<template>
  <base-section id="icons-project-header-top-right" space="0">
    <svg
      :width="`${g_bLowerBr ? 125 : 241}`"
      :height="`${g_bLowerBr ? 112 : 203}`"
      viewBox="0 0 241 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99999 71C-8.40001 23 55 3.6667 88 0H241C241.67 35 242.6 109.6 241 128C239 151 103 219 82 199C61 179 120 113 82 79C44 45 15 131 1.99999 71Z"
        :fill="color"
        fill-opacity="0.13"
      />
    </svg>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'IconProjectHeaderTopRight',

  mixins: [BaseBreakpoint],

  props: {
    color: String
  }
};
</script>
