<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          cols="12"
          class="mb-4"
        >
          <base-icon :color="color">
            {{ icon }}
          </base-icon>
        </v-col>

        <v-col
          v-if="title || subtitle"
          :cols="callout ? 9 : 12"
        >
          <component
            :is="'h2'"
            v-if="subtitle"
            space="1"
            v-text="subtitle"
          />

          <component
            :is="'h1'"
            :class="getTextClasses(eContentType.TITLE_3, ['text-uppercase'])"
            space="1"
            v-text="title"
          />

          <base-divider
            v-if="divider"
            :color="color"
          />
          <div
            v-else
            class="my-2"
          />

          <base-body
            v-if="html"
            :html="html"
            :text-color="textColor"
            space="6"
          >
            <slot />
          </base-body>

          <base-body
            v-else-if="text || $slots.default"
            :text="text"
            :text-color="textColor"
            space="6"
          >
            <slot />
          </base-body>
        </v-col>

        <v-col
          v-if="callout"
          cols="2"
        >
          <div
            class="text-h2 grey--text text--lighten-4 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'BaseInfoCard',

    mixins: [TextClass],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary1',
      },
      divider: Boolean,
      icon: String,
      justify: String,
      subtitle: String,
      text: String,
      textColor: String,
      title: String,
      html: String,
    },
  }
</script>
