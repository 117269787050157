<template>
  <div
    :class="divClasses"
    class="base-avatar d-inline-flex squared"
  >
    <v-avatar
      v-if="outlined"
      :color="color || 'grey lighten-3'"
      :size="outlineSize"
      :style="styles"
      :class="outlineAvatarClasses"
      style="opacity: .4"
    />

    <v-avatar
      :color="color || 'white'"
      :size="size"
      :class="baseAvatarClasses"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <base-icon
        v-if="icon"
        :dark="dark"
        :color="iconColor"
        :size="size / 2"
      >
        {{ icon }}
      </base-icon>

      <slot
        v-else
        :dark="dark"
        :color="iconColor"
        :size="size / 2"
      />
    </v-avatar>
  </div>
</template>

<script>
  export default {
    name: 'BaseAvatar',

    props: {
      color: String,
      dark: Boolean,
      icon: String,
      iconColor: String,
      outlined: Boolean,
      size: {
        type: [Number, String],
        default: 56,
      },
      squared: Boolean,
    },

    data: () => ({
      multiply: 6,
    }),

    computed: {
      divClasses () {
        return [
          this.outlined && 'base-avatar--outlined',
          this.squared && 'base-avatar--squared',
        ]
      },
      outlineAvatarClasses () {
        return [
          this.outlined && 'base-avatar__outline',
          this.squared && 'base-avatar__squared',
        ]
      },
      baseAvatarClasses () {
        return [
          'base-avatar__avatar',
          this.squared && 'base-avatar__squared',
        ]
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)

        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
    },
  }
</script>

<style lang="sass">
  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &--outlined
      background-color: #FFFFFF

    &--squared
      border-radius: 10px !important

      .base-avatar__avatar
        border: thin solid rgba(0, 0, 0, .12) !important

      .base-avatar__squared
        border-radius: 10px !important
</style>
