export default {
  name: 'BaseBreakpoint',

  computed: {
    g_bHighestBr() {
      return this.$vuetify.breakpoint.xlOnly;
    },
    g_bHigherBr() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    g_bBaseBr() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    g_bLowerBr() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    g_bLowestBr() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
