<template>
  <base-section id="icons-subs-top-left" space="0">
    <svg
      v-if="g_bLowestBr"
      width="147"
      height="180"
      viewBox="0 0 147 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-23 172.001C-43.8 203.201 -82.3333 124.334 -99 81.0007V0.000696182C-49.6667 -8.9993 46.4 -21.5993 36 0.000696182C25.6 21.6007 100.333 40.334 139 47.0007C173 67.0007 85 140.001 49 108.001C13 76.0007 3 133.001 -23 172.001Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="!g_bLowestBr"
      width="246"
      height="180"
      viewBox="0 0 246 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76 172.001C55.2 203.201 16.6667 124.334 0 81.0007V0.000696182C49.3333 -8.9993 145.4 -21.5993 135 0.000696182C124.6 21.6007 199.333 40.334 238 47.0007C272 67.0007 184 140.001 148 108.001C112 76.0007 102 133.001 76 172.001Z"
        :fill="color"
      />
    </svg>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'IconSubsTopLeft',

  mixins: [BaseBreakpoint],

  props: {
    color: String
  }
};
</script>
