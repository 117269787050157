<template>
  <base-section id="icons-project-header-bottom-left" space="0">
    <svg
      width="143"
      height="92"
      viewBox="0 0 143 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.1761 14.7986C26.4094 -1.31407 6.73924 37.0386 0 58.2288V90.3297C20.8495 91.3785 71.6466 92.847 108.038 90.3297C153.528 87.1823 145.947 26.7581 133.311 5.35755C120.675 -16.043 82.1345 34.94 51.1761 14.7986Z"
        :fill="color"
        fill-opacity="0.13"
      />
    </svg>
  </base-section>
</template>

<script>
export default {
  name: 'IconProjectHeaderBottomLeft',
  props: {
    color: String
  }
};
</script>
