<template>
  <base-section id="icons-subs-top-right" space="0">
    <svg
      v-if="g_bLowestBr"
      width="152"
      height="75"
      viewBox="0 0 152 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8442 71C-11.5558 55 -0.489121 17 9.84421 0H230.844C303.844 62 129.844 71 103.844 49C77.8442 27 74.8442 91 26.8442 71Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="!g_bLowestBr"
      width="249"
      height="75"
      viewBox="0 0 249 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0005 71C-11.3995 55 -0.332871 17 10.0005 0H231C304 62 130 71 104 49C78.0005 27 75.0005 91 27.0005 71Z"
        :fill="color"
      />
    </svg>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'IconSubsTopRight',

  mixins: [BaseBreakpoint],

  props: {
    color: String
  }
};
</script>
