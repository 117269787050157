import { en } from 'vuetify/lib/locale';

export default {
  ...en,

  kda: {
    common: {
      'book-banner': {
        btn: 'Book a chat',
        desc: 'Your Digital Transformation Partner'
      },
      'ask-interest': {
        title: 'Are you intrigued to join forces with us?',
        desc: `We're always open to embracing new talent`,
        btn: 'Discover your perfect fit position'
      },
      menu: {
        home: 'Home',
        about: 'About',
        process: 'Process',
        services: 'Services',
        projects: 'Projects',
        blog: 'Blog',
        careers: 'Careers',
        consult: 'Consult',
        story: 'Story',
        team: 'Team',
        tech: 'Technology',
        solutions: 'Solutions'
      },
      footer: {
        address: `Green Ville Complex, Ratu Kemuning <br>Street, Block BL No. 1, <br>RT08/RW14, Jakarta, <br>+(62-81) 180-878-87`,
        'address-mobile': `Green Ville Complex, Ratu Kemuning Street, Block BL No. 1, RT08/RW14, Jakarta, <br>+(62-81) 180-878-87`,
        'copy-right': `Copyright &copy; 2022 KeDA Tech. All Rights Reserved.`,
        'quick-links': 'Quick Links',
        'find-us': 'Find Us On',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn'
      },
      subscribe: {
        header: 'Get access to the latest and best information from us!',
        content:
          'Sign up to receive updates on our Way of Working, inspiring case studies, and much more that will surely help your business grow.',
        subscribe: 'Subscribe'
      }
    },

    home: {
      quote: {
        before: '"Starting with a group of expert programmers from America, ',
        after:
          ' is committed to helping revolutionize your business and providing the right technology to help you face business challenges in the digital age"'
      },
      process: {
        title: 'How We Do Things',
        desc: 'We listen and understand your business needs',
        btn: 'Detail'
      },
      solutions: {
        title: 'The Solution We Provide',
        item1: {
          title: 'Website Development',
          desc: 'A good and appropriate user interface can increase website conversion rates by 200%. So it is imperative that your business website becomes effective with an attractive design, complete features and easy to set up.'
        },
        item2: {
          title: 'Mobile Application',
          desc: "70% of online shoppers who leave their cart are due to a bad user experience when accessing the app. Don't let this happen to your digital business."
        },
        item3: {
          title: 'Game Development',
          desc: "Unleash the potential of advertising with a game! Say goodbye to boring and conventional methods and embrace a unique and attention-grabbing strategy. With us, you'll have the opportunity to bring your business to the next level as we'll collaborate with you every step of the way to bring your vision to life."
        },
        item4: {
          title: 'Enterprise Resource Planning system',
          desc: "Revolutionize your business with the power of ERP data! By utilizing accurate information, you'll save big on expenses and skyrocket the efficiency of your business processes by 90%. It's time to take your business to the next level with ERP data."
        }
      },
      projects: {
        title: 'Our Projects',
        item1: {
          type: 'Website Development',
          desc: 'Integrated indoor navigation with warehouse management system, increasing the efficiency of workers'
        },
        item2: {
          type: 'Mobile Application',
          desc: 'An interactive VR experience that gives you the feeling of driving in the city of Anfield'
        },
        item3: {
          type: 'Game Development',
          desc: 'A simulation software that is capable of calculating the optimal configuration of items that are placed inside a cargo or container.'
        },
        more: 'See More Projects'
      }
    },

    teams: {
      header: {
        title: 'Team Profile of KeDA Tech',
        desc: 'As one of the leading Information Technology Development Companies based in Jakarta, KeDA Tech is committed to fulfilling all your IT system and solution needs with cutting-edge technology.'
      },
      members: {
        group1: 'Crafting websites and applications',
        group2: 'Content & Social Media',
        group3: 'Business & Development',
        group4: 'Product Design'
      },
      structure: {
        desc1: `
        <p>After completing the masters program in computer science at the University of Southern California and spending several years as a programmer there,</p><br/>
        <p>Kenneth Nursalim understands that technology in Indonesia can advance further than before. He is now focused on the development and progress of the software house he founded, Keda Tech.</p><br/>
        <p>With his experience in engineering and game programming, as well as his skills in C++, Java, C#, Unreal Engine 4, and Unity, he believes that Keda Tech can become a leading technology pioneer in Indonesia.</p>`,
        desc2: `
        <p>Darren began his academic journey with a focus on Engineering, then pursued advanced studies in business and management, culminating in his graduation from California State University.<p/><br/>
        <p>After working in the United States for a long time, he noticed a significant gap between businesses in America and Indonesia. Motivated by this gap,</p><br/>
        <p>he collaboratively embarked on a transformative journey with Keda Tech to revolutionize its business strategy.</p>`,
        desc3: `
        <p>After graduating from Universitas Brawijaya with a degree in Computer Science, Reno joined Keda Tech because of their shared vision.</p><br/>
        <p>Starting as a Software Engineer and progressing to Senior and Lead Developer, he is now the Chief Technology Officer.</p><br/>
        <p>With his expertise in various programming languages such as Java, JavaScript, Python, and several platforms like Unity3D, Android, and REST APIs, Reno is confident in his ability to bring technological advancements to Keda Tech.</p>`
      }
    },

    careers: {
      header: {
        title: 'Careers',
        desc: `We are a group of passionate programmers, designers, and project managers spread across the nooks and crannies of Indonesia. At KeDA Tech, we believe in the power of remote work and our hearts are dedicated to delivering the right technology solutions for our clients' businesses.`
      },
      second:
        'Fueled by the dynamic energy of its young team members, KeDA Tech is a hotbed of imaginative ideas and groundbreaking innovations, driving the company forward towards shared success.',
      lives: {
        life1: {
          title: 'Work from Anywhere',
          desc: `Unleash your productivity and unleash your creativity, work from any location while staying in sync with the team.`
        },
        life2: {
          title: 'Explore the Latest Technology with Us',
          desc: `Join the KeDA Tech community and soar in your technology learning journey! As a member, you will have access to exciting projects that will skyrocket your professional growth.`
        },
        life3: {
          title: 'A Dynamic Dream Team - Fueled by Passion and Purpose!',
          desc: `With a dynamic team, you won't get stuck in the same place. Instead, you'll get the latest knowledge and experience by sharing with the KeDA Tech team`
        },
        life4: {
          title: 'State-of-the-Art Technology - Pushing Boundaries and Unleashing Possibilities!',
          desc: `Stay Ahead of the Game with the Latest Technology - Join KeDA Tech and Innovate with Cutting-Edge Solutions!`
        },
        life5: {
          title: 'Flexible Working Hours - Balance Your Work and Life with Ease!',
          desc: `Work with ease and flexibility at KeDA Tech - set your own schedule while staying connected to the team. And, live life to the fullest with the added bonus of KeDA Tech's health insurance coverage`
        },
        bottom: 'So what’s the hir­ing process like?'
      },
      looks: {
        title: 'It could look some­thing like this',
        look1: {
          title: `Job Searching`,
          desc: `When you discover our job vacancy and it matches what you are looking for in terms of career opportunities and job responsibilities.`
        },
        look2: {
          title: `Submission of CV`,
          desc: `Please send us your CV and resume. Don't forget to include your portfolio if you have one. It will give us a better understanding of your skills, experience, and work samples`
        },
        look3: {
          title: `HR Communication`,
          desc: `After thoroughly reviewing your resume, the HR department of KeDA Tech will promptly reach out to you to schedule an interview.`
        },
        look4: {
          title: `Interview`,
          desc: `Based on the submitted resume, the HR department will match and evaluate whether you are capable of filling the vacant position at KeDA Tech and conduct interviews where necessary`
        },
        look5: {
          title: `You're Hired`,
          desc: `Ketika kemampuan Anda dan kebutuhan kami selaras, kami dengan senang hati menyambut Anda untuk bergabung dengan tim KeDA Tech! Bersama-sama, kita akan mampu mencapai hasil yang luar biasa dan membuat dampak yang signifikan`
        }
      },
      opening: { title: 'Open Roles', apply: 'Apply', share: 'Share' },
      details: {
        input1: 'Name',
        input2: 'Phone Number',
        input3: 'Email',
        before: 'Apply Before',
        cv: 'Upload your CV here',
        select: 'Select file',
        drag: 'Select or drag file',
        join: 'Join Now'
      }
    },

    process: {
      next: 'Next',
      book: 'Book a chat',
      item1: {
        header: `KeDa's Working style`,
        title: 'Overview',
        desc: `In the industrial world, challenges are inevitable. But don't worry, we're here to help you grow your venture<br/><br/>At KeDA Tech, we take a hands-on approach to your business problems by analyzing each and every issue and providing a tailored solution using the right technology. Let us help you tackle the challenges and soar to new heights in your business endeavors.`
      },
      item2: {
        title: 'Brief',
        desc: `To kickstart our collaboration, creating a Brief is a must. In making this Brief, we need to fully understand what you want in your digital business. You can directly tell us everything, from your business profile to your desired outcomes. With clear communication, our team and your understanding can be established and result in a sustainable product.`
      },
      item3: {
        title: 'Scope',
        desc: `The scope of a project can affect up to 52% of its outcome. This scope enables us to understand exactly what you want in your digital business product. We dive deep into any business challenges you may face and find the perfect solutions. Within this scope, you will also see what our digital product can and cannot do, or how we plan to further develop it.`
      },
      item4: {
        title: 'Estimation',
        desc: `This estimation technique can help accurately predict each element in the development of your business product. Our project estimates are based on the scope that has been established and various other factors such as discovery allowances, deliveries, and allocation factors. These three factors can also determine the time and risk of each task carried out by the team.`
      },
      item5: {
        title: 'Development',
        desc: `With the successful completion of your project planning, the development of your digital business product can now begin. We utilize the scrum methodology to ensure that your digital product functions optimally. This methodology is highly regarded for producing high-quality software that meets your specifications. Moreover, it guarantees that the software can be used in both large and small projects and is easily adaptable to changes.`
      },
      item6: {
        title: 'Support',
        desc: `We understand that implementing new technology in business can be a challenge, especially for you and everyone who uses it. That's why KeDA Tech will be with you every step of the way<br/><br/>We are dedicated to providing the best support for the implementation of technology in your digital business. Not only that, we also offer ongoing product monitoring, give insightful feedback, provide backup maintenance and offer 24/7 on-call support. Let's tackle the challenges of technology together!`
      },
      item7: {
        title: 'Next Steps',
        desc: `After carefully examining the operations of KeDA Tech, there are two paths you can take moving forward. Firstly, after holding internal discussions within your organization, it is important to implement the working methods outlined by KeDA Tech. Despite the fact that the implementation process may be challenging and not as straightforward as it appears in theory, our team at KeDA Tech is always here to assist and answer any questions you may have<br/><br/>The second step involves incorporating all the processes and products generated by KeDA's work into your own company. The integration of these products will certainly prove to be a more complex task, as it involves the utilization of software and systems that will play a crucial role in the success of your business. To achieve this goal, proper training and additional time will be required<br/><br/>If you encounter any difficulties during this process, please do not hesitate to reach out to us for support. Our team at KeDA Tech is dedicated to ensuring that you are not left feeling lost and confused about the technology. We are committed to providing ongoing support for your team to ensure a smooth and successful integration.`
      }
    },

    blog: {
      header: 'KeDA Blog',
      search: 'Search',
      load: 'Load more',
      read: 'Read More',
      trend: 'Trending Article',
      details: {
        related: 'Related Article',
        more: 'See more articles'
      },
      content1: {
        title: `Discover the Top 7 Reasons Why Your Business Needs to Embrace Technology Today!`,
        desc: `
        <p>Unlock the Power of Technology for Your Business Today!</p><div class="par-spacer"></div>
        <p>The world is rapidly advancing thanks to the breakthroughs in technology in all aspects of life, especially in the fields of IT and business. Connecting with everything in real-time has never been easier.</p><div class="par-spacer"></div>
        <p>Gone are the days when technology was considered a hindrance in the business world due to its difficulties in adaptation. Today, technology is a necessary tool for business growth. Here are some of the many benefits of leveraging technology in your business:</p><div class="par-spacer"></div>
        <ol>
        <li><p>Simplifying Operational Tasks<br/>With structured technology, operational processes become easier, especially when you can quickly adapt to technology. This leads to a more efficient and effective business.</p></li><div class="par-spacer"></div>
        <li><p>Improved Communication<br/>During the pandemic, technology has made communication and collaboration among team members easier, even from afar. Tools like video conferencing, chat, and email make communication faster and more efficient.</p></li><div class="par-spacer"></div>
        <li><p>Better Data Analysis<br/>Technology makes it easier to collect and analyze data, helping you make informed business decisions. Accurate and up-to-date data leads to better decision-making, helping you achieve your desired targets.</p></li><div class="par-spacer"></div>
        <li><p>Wider Market Reach<br/>Technology allows your business to reach a wider market, both domestically and internationally. The advancements in technology make it easier to promote and sell your products through the internet and social media, reaching more potential customers.</p></li><div class="par-spacer"></div>
        <li><p>Increased Business Efficiency<br/>Technology helps optimize your business processes, reducing costs. For example, using business software reduces manual costs and makes financial control and reporting easier.</p></li><div class="par-spacer"></div>
        <li><p>Improved Security<br/>Technology also helps your business improve data and information security. With encryption and a robust security system, you can ensure that your important data is protected from unauthorized access.</p></li><div class="par-spacer"></div>
        <li><p>Increased Productivity<br/>Technology makes it easier for your business to increase productivity. For instance, using software that simplifies task management and scheduling, you can save time and make sure important tasks are completed on time.</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content2: {
        title: `Getting to Know ERP and Its Benefits for Your Optimal Business`,
        desc: `
        <p>Enterprise Resource Planning (ERP) is a powerful system and software solution for large corporations. ERP helps manage various business activities such as finance, procurement, production, HR, employee monitoring, and more.</p><div class="par-spacer"></div>
        <p>ERP is chosen because it provides real-time and accurate information that can assist companies in making better business decisions. However, this can only occur if the company can quickly adapt to ERP. The benefits of implementing ERP go beyond that, as it also has several other advantages that can greatly benefit your business.</p><div class="par-spacer"></div>
        <ol>
        <li><p>Improving company efficiency: By implementing ERP, physical data must be converted into digital data. Digital, real-time data can be accessed by everyone within the company, making it easier to access information and speeding up work processes.</p></li><div class="par-spacer"></div>
        <li><p>Enhancing collaboration: Direct access to data by all employees within the company helps to improve inter-department collaboration and makes accessing information easier. Cloud-based ERP even allows for collaboration between branches of the company through the internet.</p></li><div class="par-spacer"></div>
        <li><p>Reducing operational costs: Automated operational processes help reduce labor costs and anticipate operational issues and malfunctions.</p></li><div class="par-spacer"></div>
        <li><p>Improving data security: ERP systems have restriction controls to prevent data leaks and only administrators have access keys.</p></li><div class="par-spacer"></div>
        <li><p>Accurate business forecasting: Real-time and consistent reporting provided by ERP helps create more accurate business forecasts.</p></li><div class="par-spacer"></div>
        </ol>
        <p>Previously, ERP was only applied to large corporations, but now medium-sized companies also require it. KeDA Tech offers customizable ERP solutions to meet your business needs. Don't hesitate to reach out to us and experience the benefits of ERP for your business.</p><div class="par-spacer"></div>
        `
      },
      content3: {
        title: `3 Compelling Reasons Why Your Business Absolutely Needs a Website`,
        desc: `
        <p>Although social media and marketplaces are currently trending for online sales, Global Web Index informs that 62% of customers prefer to search for information about brands or products through websites.</p><div class="par-spacer"></div>
        <p>For entrepreneurs, this data cannot be ignored. If they don't have an official website for their business, their business opportunities could decline. Not only as a source of information for customers, but websites can also make their business more widely known and attract new customers.<br/>Here are 3 important reasons why your business should have an official website:</p><div class="par-spacer"></div>
        <ol>
        <li><p>Company credibility.<br/>Websites are important for all types of businesses, big or small, because they can make potential customers trust us. We can display genuine reviews or feedback from our customers. This way, potential customers also trust our business because it is considered professional.</p></li><div class="par-spacer"></div>
        <li><p>Promotion place.<br/>Websites are the perfect place for promotion, especially if SEO is applied well. The website traffic will increase and there will automatically be interested buyers in our products. In addition, websites can promote our business 24 hours a day, 7 days a week.</p></li><div class="par-spacer"></div>
        <li><p>Improve customer service quality.<br/>A website that can be adjusted to the comfort of customers can make customers loyal. One of them is by adding a contact page, such as a question form with email, or creating a chatbot or live chat from the website.<br/>Technology will continue to evolve, and if our business can't adapt, it will be painful. Therefore, KeDA Tech fully understands that every technology has an important role in business. You can contact us for further implementation.</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content4: {
        title: `From Crisis to Innovation: The Pandemic's Impact on Technological Adoption`,
        desc: `
        <p>The COVID-19 pandemic has brought the world to a standstill and has greatly impacted the way we live and do business. As the virus has spread, it has become increasingly clear that technology is essential in allowing us to continue with our daily activities without spreading the virus. The pandemic has given rise to a rapid increase in technological adoption, and businesses have had to adapt quickly to this new reality.</p><div class="par-spacer"></div>
        <p>One of the biggest impacts of the pandemic has been the shift towards remote work. With employees unable to go to their workplaces, companies have had to find ways to continue their operations without interruption. This has led to a huge increase in the use of collaboration tools such as video conferencing software and virtual project management tools. These tools have allowed businesses to continue their operations remotely, ensuring that their employees can work together as if they were in the same office.</p><div class="par-spacer"></div>
        <p>Another area where technology has come to the fore is in the realm of online commerce. With people wary of venturing out to physical stores, online shopping has become more popular than ever before. Retailers have had to quickly adapt to this change, investing in their online platforms and increasing their online presence to meet this growing demand. This has led to a huge increase in the use of e-commerce platforms, which are now an essential part of many businesses’ strategies.</p><div class="par-spacer"></div>
        <p>Finally, the pandemic has also seen a rise in the use of artificial intelligence and machine learning technologies. These technologies have been used to help track and predict the spread of the virus, and also to assist with the management of resources such as hospital beds and medical supplies. Additionally, they have been used to create chatbots that can help answer questions and provide information on the pandemic, freeing up healthcare workers to focus on more critical tasks.</p><div class="par-spacer"></div>
        <p>In conclusion, the COVID-19 pandemic has given rise to a rapid increase in technological adoption, as businesses and individuals have had to adapt to new ways of working and communicating. The pandemic has shown us the importance of technology in allowing us to continue our daily activities without spreading the virus, and has created new opportunities for businesses to use technology to improve their operations. As the world moves forward, it is likely that technology will continue to play an increasingly important role in our lives and businesses.</p><div class="par-spacer"></div>
        `
      },
      content5: {
        title: `The Rise and Fall of Blockbuster: The Cost of Not Adapting to Changing Technology`,
        desc: `
        <p>It was the 1980’s and the average consumer had a few different ways that they could watch movies, mainly renting films from the local video store. It was a time when Blockbuster Video was the leader in video rentals and they made a lot of money due to the high demand for films. However, the world was changing and technology was advancing at a rapid pace. The introduction of streaming services, such as Netflix, meant that Blockbuster had to adapt to the changing market. Unfortunately for them, they failed to do so, ultimately leading to their demise. </p><div class="par-spacer"></div>
        <p>In 1989, Blockbuster opened their first store and within 10 years had grown from a small chain of stores to a global empire with over 9,000 stores in 25 countries. The massive growth of their business was thanks to the success of their rental model. Consumers were able to rent films at their local store and return them afterwards without having to pay for them. This model allowed for more access to films at an affordable price and it appealed to many people. </p><div class="par-spacer"></div>
        <p>However, even though the rental model worked at first, it wasn’t enough to sustain them forever. With the emergence of streaming services like Netflix, consumers had an easier way of watching films without having to leave their homes and pay for them. Netflix also provided its subscribers with an unlimited number of films they could watch anytime they wanted. This caused a significant decrease in Blockbuster’s customers which eventually led to their downfall. </p><div class="par-spacer"></div>
        <p>The failure of Blockbuster illustrates how important it is for companies to adapt to changing technology in order for them to survive. As technology advances and new trends emerge, companies need to be willing to make changes in order keep up with these trends or risk losing out on potential customers. This is something that Blockbuster failed to do, costing them their business. </p><div class="par-spacer"></div>
        <p>In short, Blockbuster’s demise serves as a reminder that businesses must be able to adapt to changing technologies or they risk failing in a highly competitive market. Its downfall serves as an example that companies have to be willing to embrace change in order for them to remain relevant and successful. It also serves as an important lesson that no business is immune from failure if they don’t learn how to keep up with technological advancements.</p><div class="par-spacer"></div>
        `
      }
    },

    solution: {
      header: {
        title: 'Solutions',
        desc: 'We offer a range of specially crafted solutions aimed at driving the progress and growth of your business'
      },
      item1: {
        title: 'Website Development',
        desc: `A well-designed and user-friendly interface can increase website conversion rates by up to 200%. Therefore, it is important that your business website becomes effective with an attractive design, complete features and easy to navigate. We can handle all of these according to your needs and preferences<br/><br/>Starting from creating corporate websites, e-commerce sites, microsites, landing pages to custom designs with professional and feature-rich designs. We are here to ensure that your website not only looks good, but also functions optimally to meet the needs of your business.`
      },
      item2: {
        title: 'Mobile Application',
        desc: `Approximately 70% of online shoppers abandon their shopping carts due to a poor user experience when accessing the application. Don't let this happen to your digital business. Start working with us because we not only emphasize on the ease of use for your business application users, but we also give extra attention to the design aspect, making it visually appealing and enjoyable for users.`
      },
      item3: {
        title: 'Game Development',
        desc: `Discover the untapped potential of your business with a captivating and unique advertising medium - video games! Don't fret about how to make it alluring, as we will collaborate with you to bring your ideas to life. From determining the right video game that aligns with your business, to crafting the design and executing the production - whether it's for computer games, console games, or mobile devices, we've got you covered. Embrace the power of this innovative advertising approach today!`
      },
      item4: {
        title: 'Enterprise Resource Planning system',
        desc: `Maximize your business savings and streamline your processes with the right Enterprise Resource Planning or ERP. With accurate data, you can save up to 46% on your business expenses and increase productivity by 90%. Our team at Odoo ERP is here to help, offering a wide range of features to make your ERP implementation a success. Book a consultation with us today to find the best ERP solution tailored to your specific business needs`
      }
    },

    project: {
      header: 'Projects',
      quote:
        'Let us take you on a journey to dive deeper into KeDA Tech and discover the amazing projects we have worked on, including the time it took to bring each one to life',
      see: 'See Project',
      load: 'Load more',
      time: 'Time',
      months: 'Months',
      worker: 'The Worker',
      tech: 'The Tech',
      timeline: 'Effective Planning Timeline',
      development: 'Weeks in de­vel­op­ment',
      'task-completed': 'Task com­pleted',
      point1: 'Understanding the prob­lem',
      point2: 'Idea Generation',
      point3: 'Solutions',
      explore:
        'Explore other groundbreaking projects that challenge the status quo and inspire innovation',
      detail: {
        'item-wayfinder': {
          name: 'Wayfinder Indonesia',
          subtitle:
            'Transform your indoor navigation experience with seamless integration to your manufacturing system',
          desc: 'This system empowers your workforce to efficiently manage the entry and exit of goods with ease.',
          'desc-point1': `How can the integration of technology in manufacturing and warehouse systems simplify the workload for its employees and enhance their productivity?`,
          'desc-point2': `This system emerged with the purpose of revolutionizing the efficiency of warehouse workers, specifically regarding the layout of goods in a room. Imagine a world where the placement of items in a warehouse is optimized to its maximum potential, leading to a more streamlined and efficient system that benefits everyone involved. It's time to rethink the way we approach storage and organization in the workplace.`,
          'sub-point3': `The advent of Wayfinder revolutionizes the way warehouses operate, making it a hallmark of efficiency and effectiveness`,
          'desc-point3': `Because the entered data is integrated with the room's current state, it creates a dynamic and captivating fusion of information and environment.`,
          quote: `"Their con­stant com­mu­ni­ca­tion to solve the process’ needs is key to solid pro­ject man­age­ment. The team also praises their com­mit­ment to the suc­cess of their tasks through their proac­tive­ness and in­tegrity."`,
          'quote-by': `Richard Moran, IT Project Coordinator`
        },
        'item-axa': {
          name: 'AXA SMART DRIVER',
          subtitle:
            'Enjoy a smooth and hassle-free driving experience with Virtual Reality technology!',
          desc: 'Get ready for an extraordinary driving experience with interactive VR! Feel the thrill of driving in a virtual city with unparalleled comfort and safety',
          'desc-point1': `AXA presents us with a challenge to make driving safer. Together with AXA, we bring you an extraordinary driving simulation experience in the city of Anfield. With interactive and advanced VR technology, you can experience driving in the real world safely and comfortably.`,
          'desc-point2': `This simulation is created with one main goal: to provide an extraordinary driving experience for drivers. With highly advanced interactive VR technology, we will bring you on a safe and comfortable journey with a highly realistic impression.`,
          'sub-point3': '',
          'desc-point3': `AXA Smart Driver gives drivers the opportunity to drive without worry. With its sophisticated technology support, it helps you maintain safety in a unique way. This technology provides safe driving boundaries warnings so you can stay focus on the road and save time through safe journeys. So, try AXA Smart Driver and feel the thrill of driving fearlessly!`,
          quote: `"We are very happy to use the technology offered by KeDa Tech for our driving simulation. With the main goal of providing an extraordinary driving experience for our drivers, we believe that this advanced interactive VR technology will enable us to create a safe and comfortable journey with a highly realistic feel. KeDa Tech has worked with professionalism and expertise in creating this simulation. We are all very impressed by their work. They made the project safe, accurate and reliable, ensuring that all drivers get an extraordinary driving experience. We are also very grateful for KeDa Tech's support during the making of this simulation. They prioritize customer satisfaction and provide high-quality service guarantees, so that we can create a high-quality, efficient, and accurate product. We highly recommend KeDa Tech to our customers who wish to create VR interactive technology based simulations. We truly believe that they will always help our customers meet their goals by providing the best solutions. Thank you KeDa Tech for all the cooperation you have provided."`,
          'quote-by': ``
        },
        'item-load-container': {
          name: 'Load Container',
          subtitle: 'Software to Enhance Efficiency in Cargo Operations',
          desc: 'Get ahead of the competition with our cutting-edge software, designed to streamline and optimize your cargo operations, delivering increased efficiency, productivity and profitability.',
          'desc-point1': `How to Maximize the Quantity of Goods in a Cargo Container? (Without this software, the arrangement of goods in a container and cargo will undergo many trials. Thus, it takes longer to calculate and estimate the number of containers in 1 cargo.)`,
          'desc-point2': `Achieve Effortless Optimization with the Container Loader Program - Say goodbye to manual calculation woes and hello to effortless optimization. Our program calculates the quantity of goods in containers and containers in cargo, and even suggests the best container combination to use, all aimed at maximizing your operational efficiency and profitability`,
          'sub-point3': '',
          'desc-point3': `This software provides users with an easy way to achieve optimal container loading. With the various unique features available, users can determine the best combinations of containers to load cargo, and can select alternative destinations through software computation recommendations. This software promises efficient and reliable solutions for cargo loading and container arrangements while remaining cost and efficiency conscious.`,
          quote: ``,
          'quote-by': ``
        },
        'item-sqm-chatbot': {
          name: 'SQM CHATBOT',
          subtitle:
            'Experience interacting and exchanging information at high speed through our connected online Chatbot!',
          desc: 'This chatbot allows you to answer questions and instantly increase interactions with customers. With its advanced technology, the chatbot can cover various types of interactions that are varied and in real time. This chatbot will enable you to create the best service experience for your customers.',
          'desc-point1': `How can we stay connected with our customers over time, without having to repeatedly collect contact data? `,
          'desc-point2': `Chatbots have a mission to provide lightning fast solutions for customers, bridging the gap until they can connect with a human agent and delivering round-the-clock support without any time restrictions`,
          'sub-point3': '',
          'desc-point3': `With SQM Property's Chatbot SQM, customers can get quick and accurate answers regarding SQM Property. At any time, without time restrictions, customers can easily and efficiently find answers to their questions about SQM Property`,
          quote: `"From the start, we were confident that we made the right choice in partnering with KeDa Tech to bring our vision for a chatbot to life. The solution you provided was not only fast and accurate, but it was also easy for our customers to use. Our customers can now get quick answers to their questions about SQM Property at any time, without any time restrictions.<br><br>The team was highly professional and responded to any questions or concerns we had in a timely manner. The attention to detail and dedication to delivering a top-quality product was evident in every aspect of the project.<br><br>We are extremely happy with the outcome of this project and would highly recommend KeDa Tech to anyone looking for a software development partner."`,
          'quote-by': ``
        },
        'item-verif-doctor': {
          name: 'Unlocking Expert Health Care With Jasa Raharja',
          subtitle:
            'Revolutionizing Healthcare with Cutting-Edge Doctor Consultant Management Applications: Boost Efficiency, Enhance Communication, and Streamline Workflows for Optimal Patient Outcomes!',
          desc: `In the aftermath of an accident, the last thing a victim or their loved ones should have to worry about is navigating complex and confusing hospital billing procedures. That's why we're committed to using cutting-edge technology to simplify the process for Jasa Raharja-covered patients. Our innovative solution allows for easy calculation and verification of hospital charges, giving peace of mind to those in need. No more endless hours spent on hold or frustration over missing information. With our streamlined approach, we're making healthcare more accessible and less stressful for everyone. `,
          'desc-point1': `The road to recovery after an accident can be long and stressful, especially when it comes to verifying data for PT Jasa Raharja coverage. Traditional verification processes are often slow and cumbersome, causing difficulties for both doctor consultants and accident victims. But what if there was a way to speed up this critical step in the healing journey? By leveraging cutting-edge technology, we're making it possible to quickly and accurately verify data for PT Jasa Raharja-covered accident victims. No more waiting, no more frustration. With our streamlined approach, we're helping accident victims and their families get the support they need, when they need it. Join us in revolutionizing the way we handle data verification after an accident.`,
          'desc-point2': `Until now, the verification process of data from the insurance company PT Jasa Raharja in serving victims of accidents covered by insurance was not efficient enough. To address this problem, our team is looking for solutions to increase the efficiency of this process. After doing a series of maintenance, we decided to create an application to help improve the efficiency of the PT Jasa Raharja data verification process. It is hoped that this application can reduce time and costs, as well as make the process easier and more efficient, so that consultant doctors can serve victims of accidents more quickly and accurately.`,
          'sub-point3': '',
          'desc-point3': `We introduce the Consultant Doctor Verification Application to help in the verification process of hospital billing data for victims of accidents covered by the Jasa Raharja. This application is a solution that makes the verification process faster and easier for both parties, the consultant doctor and the accident victim.`,
          quote: ``,
          'quote-by': ``
        },
        'item-health-clinic': {
          name: 'Health Clinic Management System',
          subtitle:
            'Using an application to facilitate the management of all activities in the clinic, from storing patient data to tracking staff activity and continuously monitoring and evaluating them.',
          desc: `In addition to clinic management, the application can be accessed and utilized by doctors to monitor their patients' progress. This allows physicians to stay up-to-date with the latest developments and provide the best possible care to their patients, ensuring that they are on the right track towards a speedy recovery`,
          'desc-point1': `To resolve this issue, we must first identify the requirements to manage the clinic efficiently. This involves conducting an initial survey, collecting data, comparing it to industry standards, and evaluating various available solutions. Once we understand the problem and the appropriate applications that can help solve it, we can provide an integrated real-time solution to enable effective and efficient patient management.`,
          'desc-point2': `Starting from analyzing the problems faced by the clinic management, formulating ideas about how to apply first technology to ensure the effectiveness of the solutions, and testing various technologies to see which one is suitable to help the clinic management achieve their goals, our team has been working hard to develop an application that can help clinic management manage and process clinic and patient data in real time and in totality. After the testing and quality assurance process of the application is finished, we double-check whether the project objectives have been achieved well.`,
          'sub-point3': '',
          'desc-point3': `We created this application as a solution to develop efficiency and productivity for clinic managers and doctors. With this application, they can manage patient healthcare data and clinic activities more easily and quickly. This is a crucial step in increasing the productivity of clinics and doctors in handling various health issues.`,
          quote: `"We are so thankful for the great work done by KeDa Tech in creating an application that helps us to run our health clinics more efficiently and productively. The application allows us to easily and quickly manage patient health data and clinic activities.<br><br>With this application, our clinic managers and doctors can now take better care of their patients with a higher level of efficiency. This is an important step towards improving the productivity of our clinics and our doctors in handling different health issues.<br><br>We would like to thank KeDa Tech on behalf of all of our staff who have benefited from using your app. We have been highly satisfied with the results so far and look forward to further collaboration with you in the future."`,
          'quote-by': ``
        },
        'item-price-fetcher': {
          name: 'PRICE FETCHER',
          subtitle:
            'Software to track and compare prices from different e-commerce so that you can get the best value!',
          desc: `By using our application, you can compare prices of the product you are looking for and get the best deal.`,
          'desc-point1': `Accessing e-commerce applications one by one just to compare prices can be tiring and impractical. How can we compare prices across e-commerce with one access?`,
          'desc-point2': `We are inspired by the confusion of consumers in comparing e-commerce product prices. Our goal together with this software is to facilitate and help the process of choosing and comparing product prices across e-commerce to be more practical and efficient.`,
          'sub-point3': '',
          'desc-point3': `This software provides a solution for the problem of accessing different product pricing data on various e-commerce platforms. With this technology, users can easily obtain the prices of the items they are looking for without having to individually access applications for each e-commerce platform.`,
          quote: ``,
          'quote-by': ``
        },
        'item-gemx': {
          name: 'G-EMx',
          subtitle: '',
          desc: `A digital app to manage your business needs anytime, anywhere.`,
          'desc-point1': `For many business owners, accessing all of their business activities in one place can be a real challenge. The traditional method of having to coordinate with different departments in order to access the financial data of the company can be time-consuming and inefficient. This leaves business owners feeling frustrated and unable to make quick decisions based on up-to-date information. `,
          'desc-point2': `With a top-notch focus on security, we offer a solution to G-EMx that simplifies the lives of business owners by allowing them to access their financial data anytime, anywhere.`,
          'sub-point3': '',
          'desc-point3': `Business owners can now have complete control and oversight of all their business activities, access to customized financial needs, and real-time visibility into financial transactions, all at their fingertips. We understand the challenges faced by business owners in managing their finances and that's why we have developed a solution that addresses these challenges and provides them with the ease and convenience they need.`,
          quote: `"We're ecstatic to have KeDa Tech as our ally in developing a top-notch digital app for managing our business operations. Their expertise in software development is unparalleled, and they truly understand the needs of modern businesses. With their help, we've been able to streamline our operations and have complete control over our business from anywhere, anytime. The app is user-friendly, customizable, and provides real-time visibility into financial transactions. We highly recommend KeDa Tech to any business looking for a reliable, innovative solution. "`,
          'quote-by': `Asmadi Ahmad - CEO G-EMx Technologies`
        }
      }
    },

    consult: {
      form: {
        title: 'Consult with Us Now',
        desc: 'Stepping into the digital business and technology realm will surely generate many questions. You can start by filling out the form below, and we will provide the answers for free',
        input1: 'Name',
        input2: 'Your business sector',
        input3: 'Phone Number',
        input4: 'Email',
        input5: 'Your Enquiries',
        office: {
          name: 'Jakarta Office',
          address:
            'Green Ville Complex, Ratu Kemuning Street, Block BL No. 1, RT08/RW14, Jakarta, +(62-81) 180-878-87'
        },
        send: 'Send'
      },
      desc: {
        title: 'What’s in it for you?',
        desc: 'Here’s how the process will go'
      },
      point1: {
        title: '1. The Problem',
        desc: `Allow us to take a deeper dive into your business operations by engaging in meaningful conversations, gain a thorough understanding of your unique needs and current technology setup. Together, we'll pinpoint the core issue at hand and present tailored solutions that drive success. Rest assured, we'll continuously measure and optimize our approach to ensure exceptional results`
      },
      point2: {
        title: '2. The Solution',
        desc: `At KeDa Tech, we know that delivering solutions that truly make a difference is the key to success. That's why, before we even begin crafting a solution, our team of experts conducts a thorough research and analysis of your unique situation. Only then do we get to work, customizing a set of solutions specifically designed to tackle your challenges and achieve your goals<br/><br/>We believe that delivering customized solutions, tailored to meet your specific needs, is the best way to deliver real results and make a lasting impact. So why settle for a one-size-fits-all approach when you can have solutions that are crafted just for you? At KeDa Tech, that's exactly what you'll get.`
      },
      point3: {
        title: '3. Next Steps',
        desc: `At KeDa Tech, we understand the importance of building strong and lasting relationships with our clients. That's why, even after a project is completed, our commitment to excellence doesn't end there. Our follow-up process is designed to ensure complete satisfaction, address any concerns, and provide ongoing support for the longevity of your system. With regular check-ins, prompt issue resolution, and a dedicated support team, you can rest assured that your success is our top priority. Join the ranks of our satisfied clients and let us continue to drive your success long after the project is done.`
      },
      review: 'What They Said'
    },

    story: {
      header: {
        title: 'About Us',
        desc: `Starting from the dream of becoming an internationally standard software house, KeDA Tech emerged in 2018. Throughout its development, KeDA Tech believes that digital transformation will bring many changes in all aspects of life, especially in the business field of companies, communities, and society. <br><br>Join the revolution of digital transformation with KeDA Tech! From a dream of becoming an internationally recognized software house, we've arrived and are ready to shape the future. We believe that the digital age will bring about tremendous change and advancements in every aspect of life, from businesses to communities and society as a whole. Get ready for the digital revolution with KeDA Tech.`
      },
      'our-journey': {
        title: 'Our Journey',
        now: 'Now',
        item1:
          'Starting from a team consisting of 2 programmers and a designer, KeDA Tech stood and was able to complete its first project, the Container Loader Project.',
        item2:
          'A year after KeDA Tech was established, a new collaboration began with AXA. In this project, we created a VR Simulator which was successfully completed by 3 programmers and a designer in just 2 weeks.',
        item3:
          'Collaborating with one of the largest property companies in Jakarta, KeDA Tech completed a chatbot application for SQM Property.',
        item4:
          'With various projects already completed with other software developers like Shinta VR, Wallex, Luceria, and PT Citra Kreasi Makmur, we also want to be a part of your digital business progress. With over 10 programmers currently, KeDA Tech remains committed to providing the best service for you.'
      },
      'our-story': {
        title: 'Our Story',
        desc: `After completing his Master's degree in California-America in 2017, Kenneth Nursalim returned to Indonesia. Years spent in America opened his eyes to the fact that the development of information systems and technology in Indonesia was lagging behind. <br><br>Determined to change this, Kenneth Nursalim founded KeDA Tech in 2018 with a vision to become an internationally recognized software house. Join us on our journey as we strive to revolutionize the tech industry and bring Indonesia to the forefront of the digital world.`
      },
      'to-team': {
        desc: 'Sincerely doing the best for your business.',
        'meet-team': 'Meet the Team'
      }
    },

    technology: {
      'short-desc': {
        title: 'Technology',
        desc: 'For your technological advancement, we utilize all the features below to elevate your experience.'
      },
      'unreal-engine': {
        title: 'UNREAL ENGINE',
        desc: 'This Game Engine produces an incredibly stable framerate, breathtaking graphics with stunning shadows, immersive depth of field, and supports the latest DX 11 technology. Get ready to experience an extraordinary gaming journey like never before.',
        link: 'Learn more about UNREAL ENGINE',
        'long-desc': `This game engine application was created by Epic Games and made its debut in 1998. Starting with a first-person shooter game, now Unreal Engine has the capability of transforming the objects around you into a real-time 3D visualization. <br><br>The continuous improvement of Unreal Engine is bringing 3D shapes closer to reality with more lifelike representations. With each iteration, the engine becomes more immersive and captivating, taking you to a world beyond your imagination.`,
        ask1: 'How is this technology applied to open up new windows in creativity and innovation?',
        explanation1: `Unreal Engine opens up a world of limitless possibilities for your projects. It's not just for games, you can apply Unreal Engine to film, architecture, automotive and transportation, broadcast events, and even simulations of all kinds. Unleash your imagination and bring your creations to life with Unreal Engine.`,
        ask2: 'What are the advantages?',
        explanation2: `By utilizing Unreal Engine, you can get a perfect visual representation that truly captures reality. With this powerful tool, you can experience simulations to the fullest and make predictions with maximum accuracy. Imagine being able to dive into a virtual world that feels just as real as the one you live in. That's the power of Unreal Engine`,
        project: {
          use: 'Our project which use UNREAL Engine',
          title: 'Mobile Application',
          name: 'AXA',
          desc: `An interactive VR experience that takes you on a thrilling virtual drive through the bustling streets of Anfield city. Immerse yourself in the hustle and bustle of urban life as you maneuver your way through crowded intersections, tight alleyways, and bustling avenues. With a cutting-edge VR headset and intuitive controls, you'll feel like you're truly behind the wheel, navigating the city in real-time and discovering all that Anfield has to offer. Get ready for a heart-pumping, unforgettable journey through one of the most exciting cities in the world!`
        }
      },
      'my-sql': {
        title: 'My SQL',
        desc: 'MySQL boasts a high level of flexibility towards technology and is a cross-platform database. Its user-friendly table structure provides outstanding performance when put into action.',
        link: 'Learn more about My SQL',
        'long-desc': `Initially, MySQL was an advanced development of the UNIREG project that was not highly compatible with dynamic databases used on websites. As it evolved, MySQL became a database management system that uses the basic SQL commands, or Structured Query Language, which is quite well-known.`,
        ask1: 'How is this technology applied to open up new windows in creativity and innovation?',
        explanation1: `MySQL falls under the category of Relational Database Management System, where terms such as rows, columns, and tables are utilized in this database application. <br><br>Dive into the world of database management with MySQL, where the familiar terms of rows, columns, and tables are brought to life in the realm of relational databases.`,
        ask2: 'What are the advantages?',
        explanation2: `By implementing MySQL, you don't need a massive RAM. Additionally, MySQL also supports integration with other programming languages, making it a versatile and efficient solution.`,
        project: {
          use: 'Our project which use My SQL',
          title: 'Website Development',
          name: 'WayFinder',
          desc: `Integrated indoor navigation with warehouse management system, increasing the efficiency of workers.`
        }
      },
      'django-python': {
        title: 'Django Phyton',
        desc: `The birth of Django took place in 2003, but it wasn't until 2008 that its first version was finally unleashed to the world. This powerful full-stack framework, written in Python, provides developers with a seamless way to bring their web application dreams to life.`,
        link: 'Learn more about Python',
        'long-desc': `Created in 2003, Django was not released until its first version in 2008. This full-stack framework was designed to help developers create web applications using the Python programming language. Fast forward to 2019, the second version of Django has finally been released, providing even more tools for developers to bring their web application visions to life.`,
        ask1: 'How is this technology applied to open up new windows in creativity and innovation?',
        explanation1: `Django is a high-level Python web framework that allows for rapid application development with a clean and practical design. Its efficiency and streamlined approach make it a standout choice for web development.`,
        ask2: 'What are the advantages?',
        explanation2: `Django is the perfect fit for any size project, ranging from small to large. It boasts an impressive array of features compared to other frameworks, making it capable of handling all the essentials for building an application with ease.`,
        project: {
          use: 'Our project which use Django Phyton',
          title: 'Game Development',
          name: 'Container Loader',
          desc: `A simulation software that calculates optimally the configuration of goods that are stored in cargo or container. <br><br>An innovative simulation software that calculates the most efficient arrangement of goods stored in containers or cargo to maximize space and minimize waste.`
        }
      },
      unity: {
        title: 'Unity',
        desc: `Developed by Unity Technologies, Unity was first launched in 2005 and claims to be a pioneering platform in creating and operating real-time 3D experiences. Not limited to just 3D, Unity also offers 2D, VR, AR cross-platform projects.`,
        link: 'Learn more about Unity',
        'long-desc': `Developed by Unity Technologies, Unity was first launched in 2005 and claims to be a pioneering platform in creating and operating real-time 3D experiences. Not limited to just 3D, Unity also offers 2D, VR, AR cross-platform projects. With its advanced technology, Unity has revolutionized the world of gaming and interactive content, making it easier than ever to bring stunning, immersive experiences to life.`,
        ask1: 'How is this technology applied to open up new windows in creativity and innovation?',
        explanation1: `Unity can be applied in the creation of various games, animations, automotive design, architecture, and so much more, unlocking limitless possibilities for imagination to come to life.`,
        ask2: 'What are the advantages?',
        explanation2: `One of the advantages of Unity is its user-friendly system that is easy to learn the code but capable of producing games with a wide range of capabilities. This enables developers to work more efficiently and bring their creative visions to life with ease. Whether you're a beginner or an experienced game developer, Unity provides the tools and resources to bring your ideas to life. With its intuitive interface and powerful features, Unity is the ultimate tool for creating stunning games.`,
        project: {
          use: 'Our project which use Unity',
          title: '',
          name: '',
          desc: ``
        }
      },
      'keda-engine': {
        title: 'KeDa Engine',
        desc: `Discover the power of KeDa Engine - a program that was born out of determination and the need for speed. With its original programming language of C#, the KeDa team encountered the challenge of slow iteration.`,
        link: 'Learn more about KeDa Engine',
        'long-desc': `Discover the power of KeDa Engine - a program that was born out of determination and the need for speed. With its original programming language of C#, the KeDa team encountered the challenge of slow iteration. But with unwavering determination and a drive for innovation, the KeDa Engine now boasts a more efficient and powerful language of C++.`,
        ask1: 'How is this technology applied to open up new windows in creativity and innovation?',
        explanation1: `The KeDa Engine is a specialized technology developed by KeDa in 2017, designed to revolutionize the world of programming. Initially written in C# language, the team at KeDa encountered the challenge of slow iteration speed. But with a relentless determination and a drive for innovation, the KeDa Engine has now adopted the more efficient and powerful language of C++. This technology is a testament to KeDa's commitment to delivering the best possible solutions for their clients and is an example of how perseverance and a thirst for innovation can lead to the creation of game-changing technologies.`,
        ask2: 'What are the advantages?',
        explanation2: `The advantage of using KedaEngine is its customized system, utilizing C++ and a lightning fast runtime compared to other engines.`,
        project: {
          use: 'Our project which use KeDa Engine',
          item1: {
            title: 'Website Development',
            name: 'Wayfinder',
            desc: `Integrated indoor navigation with warehouse management system, increasing the efficiency of workers.`
          },
          item2: {
            title: 'Website Development',
            name: 'PRICE FETCHER',
            desc: `Software to track and compare prices from different e-commerce so that you can get the best value!`
          },
          item3: {
            title: 'Website Development',
            name: 'Load Container',
            desc: `A simulation software that is capable of calculating the optimal configuration of items that are placed inside a cargo or container.`
          }
        }
      },
      back: 'Back to Technology'
    }
  }
};
