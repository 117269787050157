<template>
  <v-btn
    :color="color"
    :depressed="depressed"
    :min-width="minWidth"
    :tile="tile"
    :class="classes"
    :disabled="disabled"
    x-large
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'BaseBtn',

    props: {
      color: {
        type: String,
        default: 'primary',
      },
      depressed: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      minWidth: {
        type: [Number, String],
        default: 164,
      },
      tile: {
        type: Boolean,
        default: true,
      },
      textColor: {
        type: String,
        default: 'black',
      },
      addClass: {
        type: String,
        default: '',
      },
    },

    computed: {
      classes () {
        return [
          'rounded-border-15 font-weight-bold',
          `${this.textColor}--text`,
          `${this.addClass}`,
        ]
      },
    },
  }
</script>

<style lang="sass">
  .rounded-border-15
    border-radius: 15px !important
</style>
