<template>
  <base-section id="icons-subs-bottom-left" space="0">
    <svg
      v-if="g_bLowestBr"
      width="184"
      height="130"
      viewBox="0 0 184 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-74.2217 38.181C-91.0217 86.981 -67.2217 119.848 -53.2217 130.181H158.778C223.778 101.181 139.778 53.1812 126.778 72.1812C113.778 91.1812 23.7783 91.181 30.7783 44.181C37.7783 -2.819 -53.2217 -22.819 -74.2217 38.181Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="!g_bLowestBr"
      width="264"
      height="130"
      viewBox="0 0 264 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.77827 38.181C-11.0217 86.981 12.7783 119.848 26.7783 130.181H238.778C303.778 101.181 219.778 53.1812 206.778 72.1812C193.778 91.1812 103.778 91.181 110.778 44.181C117.778 -2.819 26.7783 -22.819 5.77827 38.181Z"
        :fill="color"
      />
    </svg>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'IconSubsBottomLeft',

  mixins: [BaseBreakpoint],

  props: {
    color: String
  }
};
</script>
