import { de } from 'vuetify/lib/locale';

export default {
  ...de,

  kda: {
    common: {
      'book-banner': {
        btn: 'Kontaktieren Sie uns',
        desc: 'Ihr digitaler Transformationspartner '
      },
      'ask-interest': {
        title: 'Interessierst du dich, uns zu verbinden?',
        desc: 'Wir sind immer offen für neues Talent',
        btn: 'Finde die passende Stelle für dich'
      },
      menu: {
        home: 'Startseite',
        about: 'Über',
        process: 'Prozesse',
        services: 'Dienstleistungen',
        projects: 'Projekte',
        blog: 'Blog',
        careers: 'Karrieren',
        consult: 'Beraten',
        story: 'Geschichte',
        team: 'Mannschaft',
        tech: 'Technologie',
        solutions: 'Lösungen'
      },
      footer: {
        address: `Green Ville Komplex, Ratu Kemuning <br>Straße, Block BL Nr. 1, <br>RT08/RW14, Jakarta, <br>+(62-81) 180-878-87`,
        'address-mobile': `Green Ville Komplex, Ratu Kemuning Straße, Block BL Nr. 1, RT08/RW14, Jakarta, <br>+(62-81) 180-878-87`,
        'copy-right': `Copyright &copy; 2022 KeDA Tech. Alle Rechte vorbehalten.`,
        'quick-links': 'Schnelle Zugänge',
        'find-us': 'Finden Sie uns auf',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn'
      },
      subscribe: {
        header: 'Erhalten Sie Zugang zu den neuesten und besten Informationen von uns! ',
        content:
          'Melden Sie sich an, um Updates zu unserer Arbeitsweise, inspirierenden Fallstudien und vielem mehr zu erhalten, das Ihr Geschäft bestimmt wachsen lässt.',
        subscribe: 'Hier Abonnieren'
      }
    },

    home: {
      quote: {
        before: '"Mit einer Gruppe von Expertenprogrammierern aus Amerika startend, ist ',
        after:
          ' dazu entschlossen, Ihr Unternehmen zu revolutionieren und die richtige Technologie bereitzustellen, um Sie bei Geschäfts Herausforderungen im digitalen Zeitalter zu unterstützen"'
      },
      process: {
        title: 'Unser Vorgehen',
        desc: 'Wir hören zu und verstehen Ihre Geschäftsanforderungen.',
        btn: 'Detail'
      },
      solutions: {
        title: 'Die Lösung, die wir bieten ',
        item1: {
          title: 'Webseitenentwicklung',
          desc: 'Eine gute und angebrachte Benutzeroberfläche kann die Konversionsraten einer Website um 200% steigern. Daher ist es unerlässlich, dass Ihre Unternehmenswebsite durch ein ansprechendes Design, vollständige Funktionen und eine leichte Einrichtung effektiv wird.'
        },
        item2: {
          title: 'Mobile Anwendung ',
          desc: '70% der Online-Käufer, die ihren Warenkorb verlassen, tun dies aufgrund einer schlechten Benutzererfahrung beim Zugriff auf die App. Lassen Sie dies nicht mit Ihrem digitalen Geschäft geschehen.'
        },
        item3: {
          title: 'Entwicklung von Computerspielen',
          desc: 'Entfesseln Sie das Potential von Werbung mit einem Spiel! Verabschieden Sie sich von langweiligen und konventionellen Methoden und begrüßen Sie eine einzigartige und auffällige Strategie. Mit uns haben Sie die Möglichkeit, Ihr Geschäft auf die nächste Stufe zu bringen, da wir von Anfang bis Ende eng mit Ihnen zusammenarbeiten werden, um Ihre Vision Wirklichkeit werden zu lassen.'
        },
        item4: {
          title: 'ERP-System für Unternehmen',
          desc: 'Revolutionieren Sie Ihr Geschäft mit der Kraft von ERP-Daten! Durch den Einsatz von präzisen Informationen werden Sie große Ausgaben einsparen und die Effizienz Ihrer Geschäftsprozesse um 90% steigern. Es ist Zeit, Ihr Geschäft auf die nächste Stufe zu bringen mit ERP-Daten.'
        }
      },
      projects: {
        title: 'Unsere Vorhaben',
        item1: {
          type: 'Webseitenentwicklung',
          desc: 'Die Integration eines Innennavigations-Systems in unsere Lagerverwaltung hat die Effizienz unserer Arbeiter erhöht.'
        },
        item2: {
          type: 'Mobile Anwendung ',
          desc: 'Eine interaktive VR-Erfahrung, die Ihnen das Gefühl gibt, in der Stadt Anfield zu fahren.'
        },
        item3: {
          type: 'Entwicklung von Computerspielen',
          desc: 'Eine fortschrittliche Simulationssoftware, die die bestmögliche Konfiguration von Gegenständen in Frachträumen berechnen kann.'
        },
        more: 'Erkunden Sie Weitere Projekte'
      }
    },

    teams: {
      header: {
        title: 'Teamprofil von KeDA Tech',
        desc: 'Als eines der führenden IT-Entwicklungsunternehmen mit Sitz in Jakarta ist KeDA Tech bestrebt, all Ihre IT-System- und Lösungsanforderungen mit state-of-the-art-Technologie zu erfüllen.'
      },
      members: {
        group1: 'Websites und Anwendungen herstellen',
        group2: 'Inhalte & Soziale Medien',
        group3: 'Geschäft & Entwicklung',
        group4: 'Produktgestaltung'
      },
      structure: {
        desc1: `
        <p>Nach dem Abschluss des Masterstudiengangs in Informatik an der University of Southern California und mehreren Jahren als Programmierer dort,</p><br/>
        <p>versteht Kenneth Nursalim, dass die Technologie in Indonesien weiter fortschreiten kann als zuvor. Er konzentriert sich jetzt auf die Entwicklung und den Fortschritt des von ihm gegründeten Softwarehauses Keda Tech.</p><br/>
        <p>Mit seiner Erfahrung in der Technik und der Spieleprogrammierung sowie seinen Fähigkeiten in C++, Java, C#, Unreal Engine 4 und Unity glaubt er, dass Keda Tech ein führender Technologiepionier in Indonesien werden kann.</p>`,
        desc2: `
        <p>Darren begann seine akademische Reise mit einem Fokus auf Technik, dann verfolgte er weiterführende Studien in Wirtschaft und Management, die in seinem Abschluss an der California State University gipfelten.</p><br/>
        <p>Nach langer Arbeit in den Vereinigten Staaten bemerkte er eine erhebliche Lücke zwischen den Geschäften in Amerika und Indonesien. Motiviert durch diese Lücke,</p><br/>
        <p>begann er gemeinsam mit Keda Tech eine transformative Reise, um seine Geschäftsstrategie zu revolutionieren.</p>`,
        desc3: `
        <p>Nach seinem Abschluss an der Universitas Brawijaya mit einem Abschluss in Informatik trat Reno Keda Tech bei, weil sie eine gemeinsame Vision teilten.</p><br/>
        <p>Beginnend als Software Engineer und aufsteigend zum Senior und Lead Developer ist er jetzt Chief Technology Officer.</p><br/>
        <p>Mit seiner Expertise in verschiedenen Programmiersprachen wie Java, JavaScript, Python und mehreren Plattformen wie Unity3D, Android und REST-APIs ist Reno zuversichtlich, dass er technologische Fortschritte bei Keda Tech vorantreiben kann.</p>`
      }
    },

    careers: {
      header: {
        title: 'Laufbahn',
        desc: 'Wir sind eine Gruppe leidenschaftlicher Programmierer, Designer und Projektmanager, die sich über alle Winkel Indonesiens verteilen. Bei KeDA Tech glauben wir an die Kraft des Remote-Arbeitens und unser Herz ist dem Liefern der richtigen Technologielösungen für die Geschäfte unserer Kunden gewidmet.'
      },
      second:
        'Angetrieben von der dynamischen Energie seiner jungen Teammitglieder, ist KeDA Tech ein Hort kreativer Ideen und bahnbrechender Innovationen, die das Unternehmen auf dem Weg zum gemeinsamen Erfolg antreiben.',
      lives: {
        life1: {
          title: 'Arbeiten Sie von überall',
          desc: 'Entfessele deine Produktivität und entfessele deine Kreativität, arbeite von jedem Standort aus und bleibe trotzdem im Einklang mit dem Team.'
        },
        life2: {
          title: 'Entdecken Sie die neueste Technologie mit uns',
          desc: 'Schließe dich der KeDA Tech Gemeinschaft an und starte deine Reise im Bereich Technologie! Als Mitglied hast du Zugang zu aufregenden Projekten, die deine berufliche Entwicklung in die Höhe treiben werden.'
        },
        life3: {
          title: 'Ein dynamisches Traumteam - angetrieben von Leidenschaft und Zweck!',
          desc: 'Mit einem dynamischen Team wirst du nicht an einem festen Ort stecken bleiben. Stattdessen wirst du durch das Teilen mit dem KeDA Tech-Team die neuesten Kenntnisse und Erfahrungen erhalten.'
        },
        life4: {
          title: 'State-of-the-Art-Technologie - Grenzen verschieben und Möglichkeiten entfesseln!',
          desc: 'Bleiben Sie immer einen Schritt voraus mit der neuesten Technologie - Werden Sie Teil von KeDA Tech und innovieren Sie mit bahnbrechenden Lösungen!'
        },
        life5: {
          title: 'Flexible Arbeitszeiten - Balancieren Sie Arbeit und Leben mühelos!',
          desc: 'Arbeite mit Leichtigkeit und Flexibilität bei KeDA Tech - setze deinen eigenen Zeitplan und bleibe trotzdem mit dem Team verbunden. Und genieße das Leben in vollen Zügen mit dem zusätzlichen Bonus von KeDA Techs Gesundheitsversicherung.'
        },
        bottom: 'Also, wie läuft der Einstellungsprozess ab?'
      },
      looks: {
        title: 'Es könnte so aussehen',
        look1: {
          title: 'Jobsuche',
          desc: 'Wenn Sie unsere Stellenangebot entdecken und es mit dem übereinstimmt, was Sie bezüglich Karrieremöglichkeiten und Arbeitsverantwortungen suchen.'
        },
        look2: {
          title: 'Übermittlung des Lebenslaufs',
          desc: 'Bitte senden Sie uns Ihren Lebenslauf und Ihren Lebenslauf. Vergessen Sie nicht, Ihr Portfolio mitzusenden, wenn Sie eines haben. Es wird uns ein besseres Verständnis Ihrer Fähigkeiten, Erfahrungen und Arbeitsproben geben.'
        },
        look3: {
          title: 'Personalbetreuungskommunikation',
          desc: 'Nach einer gründlichen Überprüfung Ihres Lebenslaufs wird die Personalabteilung von KeDA Tech Sie zeitnah kontaktieren, um ein Vorstellungsgespräch zu vereinbaren.'
        },
        look4: {
          title: 'Interview',
          desc: 'Basierend auf dem eingereichten Lebenslauf, wird die Personalabteilung überprüfen, ob Sie für die freie Stelle bei KeDA Tech geeignet sind und gegebenenfalls Interviews durchführen.'
        },
        look5: {
          title: 'Sie sind eingestellt',
          desc: 'Wenn Ihre Fähigkeiten und unsere Bedürfnisse harmonieren, heißen wir Sie herzlich bei KeDA Tech willkommen! Gemeinsam können wir erstaunliche Ergebnisse erzielen und einen signifikanten Einfluss ausüben.'
        }
      },
      opening: { title: 'Offene Stellen', apply: 'Bewerbung', share: 'Teilen' },
      details: {
        input1: 'Name',
        input2: 'Telefonnummer',
        input3: 'Email',
        before: 'Bewerben Sie sich vor dem',
        cv: 'Laden Sie hier Ihren Lebenslauf hoch',
        select: 'Datei auswählen',
        drag: 'Datei auswählen oder hierher ziehen',
        join: 'Jetzt beitreten'
      }
    },

    process: {
      next: 'Nächste Seite',
      book: 'Diskussion mit uns',
      item1: {
        header: 'KeDa Arbeitsstil',
        title: 'Überblick',
        desc: `In der Industrie sind Herausforderungen unvermeidbar. Aber keine Sorge, wir sind hier, um Ihnen dabei zu helfen, Ihre Unternehmen zu wachsen.<br/><br/>Bei KeDA Tech gehen wir aktiv an die Probleme Ihres Unternehmens heran, indem wir jedes Problem analysieren und eine maßgeschneiderte Lösung mit der richtigen Technologie bereitstellen. Lassen Sie uns Ihnen helfen, die Herausforderungen zu meistern und in Ihren Geschäftsbemühungen neue Höhen zu erreichen.`
      },
      item2: {
        title: 'Anleitung',
        desc: `Um unsere Zusammenarbeit zu starten, ist es unerlässlich, eine Anleitung zu erstellen. Bei der Erstellung dieser Anleitung müssen wir vollständig verstehen, was Sie in Ihrem Geschäft wollen. Sie können uns alles direkt erzählen, von Ihrem Geschäftsprofil bis zu Ihren gewünschten Ergebnissen. Durch klare Kommunikation kann unser Team und Ihr Verständnis etabliert werden und zu einem nachhaltigen Produkt führen.`
      },
      item3: {
        title: 'Umfang',
        desc: `Der Umfang eines Projekts kann bis zu 52% seines Ergebnisses beeinflussen. Dieser Umfang ermöglicht es uns, genau zu verstehen, was Sie in Ihrem digitalen Geschäftsprodukt wollen. Wir tauchen tief in alle Geschäftsprobleme ein, die Sie möglicherweise haben, und finden die perfekte Lösung. Innerhalb dieses Umfangs werden Sie auch sehen, was unser digitales Produkt kann und nicht kann, oder wie wir es weiter entwickeln planen.`
      },
      item4: {
        title: 'Schätzung',
        desc: `Diese Schätztechnik kann helfen, jedes Element in der Entwicklung Ihres Geschäftprodukts genau vorherzusagen. Unsere Projektschätzungen basieren auf dem festgelegten Umfang und verschiedenen anderen Faktoren wie Entdeckungsermäßigungen, Lieferungen und Verteilungsfaktoren. Diese drei Faktoren können auch die Zeit und das Risiko jeder von der Mannschaft durchgeführten Aufgabe bestimmen.`
      },
      item5: {
        title: 'Entwicklung',
        desc: `Mit dem erfolgreichen Abschluss Ihrer Projektplanung kann die Entwicklung Ihres digitalen Geschäftprodukts nun beginnen. Wir nutzen die Scrum-Methodik, um sicherzustellen, dass Ihr digitales Produkt optimal funktioniert. Diese Methodik ist sehr geschätzt für die Erzeugung hochwertiger Software, die Ihren Spezifikationen entspricht. Darüber hinaus garantiert sie, dass die Software sowohl für große als auch für kleine Projekte verwendet werden kann und leicht an Veränderungen angepasst werden kann.`
      },
      item6: {
        title: 'Unterstützung',
        desc: `Wir verstehen, dass die Implementierung neuer Technologie in Unternehmen eine Herausforderung sein kann, insbesondere für Sie und alle, die sie nutzen. Deshalb wird KeDA Tech jeden Schritt des Weges bei Ihnen sein.<br/><br/>Wir sind darauf bedacht, Ihnen die beste Unterstützung bei der Implementierung von Technologie in Ihrem digitalen Geschäft zu bieten. Darüber hinaus bieten wir auch eine ständige Überwachung des Produkts, geben Einsichten und Feedback, stellen eine Backup-Wartung bereit und bieten rund um die Uhr telefonischen Support. Lass uns gemeinsam die Herausforderungen der Technologie angehen!`
      },
      item7: {
        title: 'Nächste Schritte',
        desc: `Nach sorgfältiger Überprüfung der Arbeitsweisen von KeDA Tech gibt es zwei Wege, die Sie weitergehen können. Erstens ist es nach internen Diskussionen innerhalb Ihrer Organisation wichtig, die von KeDA Tech vorgeschlagenen Arbeitsmethoden umzusetzen. Obwohl der Implementierungsprozess herausfordernd sein kann und nicht so einfach ist, wie es auf dem Papier aussieht, steht unser Team von KeDA Tech immer zur Verfügung, um zu helfen und Fragen zu beantworten.<br/><br/>Der zweite Schritt beinhaltet die Integration aller Prozesse und Produkte, die durch die Arbeit von KeDA entstehen, in Ihr eigenes Unternehmen. Die Integration dieser Produkte wird sicherlich eine komplexere Aufgabe sein, da sie die Verwendung von Software und Systemen beinhaltet, die eine entscheidende Rolle für den Erfolg Ihres Unternehmens spielen werden. Um dieses Ziel zu erreichen, wird eine angemessene Schulung und zusätzliche Zeit erforderlich sein.<br/><br/>Wenn Sie bei diesem Prozess Schwierigkeiten haben, zögern Sie bitte nicht, uns um Unterstützung zu bitten. Unser Team von KeDA Tech ist darauf ausgerichtet, dass Sie sich nicht verloren und verwirrt über die Technologie fühlen. Wir sind dazu verpflichtet, Ihrem Team dauerhafte Unterstützung zu bieten, um eine reibungslose und erfolgreiche Integration zu gewährleisten.`
      }
    },

    blog: {
      header: 'Blog von KeDA',
      search: 'Suche',
      load: 'Mehr anzeigen',
      read: 'Mehr lesen',
      trend: 'Artikel im Trend',
      details: {
        related: 'Verwandte Artikel',
        more: 'Weitere Artikel anzeigen'
      },
      content1: {
        title: `Entdecken Sie die Top 7 Gründe, warum Ihr Unternehmen heute Technologie umarmen muss!`,
        desc: `
        <p>Entfessele die Kraft der Technologie für dein Geschäft heute!</p><div class="par-spacer"></div>
        <p>Die Welt entwickelt sich rapide dank der Durchbrüche in der Technologie in allen Bereichen des Lebens, insbesondere in den Bereichen IT und Geschäft. Die Verbindung mit allem in Echtzeit war noch nie einfacher.</p><div class="par-spacer"></div>
        <p>Vorbei sind die Tage, in denen Technologie im Geschäftswelt als Hindernis betrachtet wurde aufgrund ihrer Schwierigkeiten bei der Anpassung. Heute ist Technologie ein notwendiges Werkzeug für das Geschäftswachstum. Hier sind einige der vielen Vorteile, die Sie durch die Verwendung von Technologie in Ihrem Geschäft erzielen können:</p><div class="par-spacer"></div>
        <ol>
        <li><p>Vereinfachung operativer Aufgaben<br/>Mit strukturierter Technologie werden die operativen Prozesse einfacher, insbesondere wenn Sie sich schnell an die Technologie anpassen können. Dies führt zu einem effizienteren und wirksameren Geschäft.</p></li><div class="par-spacer"></div>
        <li><p>Verbesserte Kommunikation<br/>Während der Pandemie hat die Technologie die Kommunikation und Zusammenarbeit zwischen den Teammitgliedern erleichtert, selbst aus der Ferne. Werkzeuge wie Video-Konferenzen, Chat und E-Mail machen die Kommunikation schneller und effizienter.</p></li><div class="par-spacer"></div>
        <li><p>Bessere Datenanalyse<br/>Technologie erleichtert das Sammeln und Analysieren von Daten, was Ihnen hilft, informierte Geschäftsentscheidungen zu treffen. Genaue und aktuelle Daten führen zu besseren Entscheidungen und helfen Ihnen, Ihre gewünschten Ziele zu erreichen.</p></li><div class="par-spacer"></div>
        <li><p>Breitere Marktreach<br/>Technologie ermöglicht es Ihrem Geschäft, einen breiteren Markt zu erreichen, sowohl im In- als auch im Ausland. Die Fortschritte in der Technologie erleichtern die Vermarktung und den Verkauf Ihrer Produkte über das Internet und soziale Medien und erreichen so mehr potenzielle Kunden.</p></li><div class="par-spacer"></div>
        <li><p>Erhöhte Geschäftseffizienz<br/>Technologie hilft dabei, Geschäftsprozesse zu optimieren und Kosten zu reduzieren. Beispielsweise reduzieren Geschäftsanwendungen die manuellen Kosten und erleichtern die finanzielle Kontrolle und Berichterstattung.</p></li><div class="par-spacer"></div>
        <li><p>Verbesserte Sicherheit<br/>Technologie hilft auch Ihrem Unternehmen, Daten- und Informationssicherheit zu verbessern. Mit Verschlüsselung und einem robusten Sicherheitssystem können Sie sicherstellen, dass Ihre wichtigen Daten vor unbefugtem Zugriff geschützt sind.</p></li><div class="par-spacer"></div>
        <li><p>Erhöhte Produktivität<br/>Technologie erleichtert es Ihrem Unternehmen, die Produktivität zu erhöhen. Zum Beispiel können Sie durch den Einsatz von Software, die Aufgabenmanagement und Planung vereinfacht, Zeit sparen und sicherstellen, dass wichtige Aufgaben rechtzeitig abgeschlossen werden.</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content2: {
        title: `ERP kennenlernen und seine Vorteile für ein optimales Geschäft verstehen.`,
        desc: `
        <p>Enterprise Resource Planning (ERP) ist ein leistungsstarkes System und Softwarelösung für große Konzerne. ERP hilft bei der Verwaltung verschiedener Geschäftstätigkeiten wie Finanzen, Beschaffung, Produktion, HR, Mitarbeiterüberwachung und mehr.</p><div class="par-spacer"></div>
        <p>ERP wird gewählt, weil es in Echtzeit und genaue Informationen bereitstellt, die Unternehmen bei besseren Geschäftsentscheidungen unterstützen können. Dies kann jedoch nur geschehen, wenn das Unternehmen schnell an ERP anpassen kann. Die Vorteile der Implementierung von ERP gehen jedoch über das hinaus, da es auch mehrere andere Vorteile hat, die Ihr Geschäft stark verbessern können.</p><div class="par-spacer"></div>
        <ol>
        <li><p>Verbesserung der Effizienz des Unternehmens: Durch die Implementierung von ERP müssen physische Daten in digitale Daten umgewandelt werden. Digitale, in Echtzeit verfügbare Daten können von jedem innerhalb des Unternehmens aufgerufen werden, was den Zugang zu Informationen erleichtert und Arbeitsprozesse beschleunigt.</p></li><div class="par-spacer"></div>
        <li><p>Verbesserung der Zusammenarbeit: Direkter Zugang zu Daten durch alle Mitarbeiter innerhalb des Unternehmens hilft bei der Verbesserung der Zusammenarbeit zwischen Abteilungen und macht den Zugang zu Informationen einfacher. Cloud-basierte ERP ermöglicht sogar die Zusammenarbeit zwischen Niederlassungen des Unternehmens über das Internet.</p></li><div class="par-spacer"></div>
        <li><p>Reduzierung der Betriebskosten: Automatisierte Betriebsprozesse helfen bei der Reduzierung der Arbeitskosten und ermöglichen es, Betriebsprobleme und Störungen vorherzusagen.</p></li><div class="par-spacer"></div>
        <li><p>Verbesserung der Datensicherheit: ERP-Systeme haben Einschränkungskontrollen, um Datenlecks zu verhindern, und nur Administratoren haben Zugangsschlüssel.</p></li><div class="par-spacer"></div>
        <li><p>Genaues Geschäftsprognostizieren: Die in Echtzeit und konsistent bereitgestellten Berichte von ERP helfen bei der Erstellung genauerer Geschäftsprognosen.</p></li><div class="par-spacer"></div>
        </ol>
        <p>Früher wurde ERP nur großen Unternehmen angewendet, aber jetzt benötigen auch mittelständische Unternehmen es. KeDA Tech bietet anpassbare ERP-Lösungen, die auf Ihre Geschäftsanforderungen abgestimmt sind. Zögern Sie nicht, uns zu kontaktieren und die Vorteile von ERP für Ihr Unternehmen zu erleben.</p><div class="par-spacer"></div>
        `
      },
      content3: {
        title: `3 überzeugende Gründe, warum Ihr Unternehmen unbedingt eine Website braucht`,
        desc: `
        <p>Obwohl soziale Medien und Marktplätze derzeit für den Online-Verkauf im Trend liegen, informiert der Global Web Index, dass 62 % der Kunden es vorziehen, Informationen über Marken oder Produkte über Websites zu suchen.<br/>Für Unternehmer sind diese Daten nicht zu ignorieren. Wenn sie keine offizielle Website für ihr Unternehmen haben, könnten ihre Geschäftsmöglichkeiten sinken. Websites dienen nicht nur als Informationsquelle für Kunden, sondern können auch den Bekanntheitsgrad ihres Unternehmens erhöhen und neue Kunden anziehen.<br/>Hier sind 3 wichtige Gründe, warum Ihr Unternehmen eine offizielle Website haben sollte:</p><div class="par-spacer"></div>
        <ol>
        <li><p>Glaubwürdigkeit des Unternehmens.<br/>Websites sind für alle Arten von Unternehmen, ob groß oder klein, wichtig, denn sie können das Vertrauen potenzieller Kunden gewinnen. Wir können echte Bewertungen oder Feedback von unseren Kunden anzeigen. Auf diese Weise haben auch potenzielle Kunden Vertrauen in unser Unternehmen, weil es als professionell gilt.</p></li><div class="par-spacer"></div>
        <li><p>Ort der Werbung.<br/>Websites sind der perfekte Ort für Werbung, vor allem wenn SEO gut angewendet wird. Der Verkehr auf der Website wird zunehmen und es wird automatisch Interessenten für unsere Produkte geben. Darüber hinaus können Websites 24 Stunden am Tag, 7 Tage die Woche für unser Unternehmen werben.</p></li><div class="par-spacer"></div>
        <li><p>Die Qualität des Kundendienstes wird verbessert.<br/>Eine Website, die an den Komfort der Kunden angepasst werden kann, kann die Kunden an sich binden. Eine davon ist das Hinzufügen einer Kontaktseite, z. B. ein Frageformular mit E-Mail, oder die Einrichtung eines Chatbots oder Live-Chats auf der Website.<br/>Die Technologie wird sich weiter entwickeln, und wenn sich unser Unternehmen nicht anpassen kann, wird es schmerzhaft sein. Daher ist sich KeDA Tech bewusst, dass jede Technologie eine wichtige Rolle in der Wirtschaft spielt. Sie können uns für die weitere Umsetzung kontaktieren.</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content4: {
        title: `Von der Krise zur Innovation: Der Einfluss der Pandemie auf die Technologie-Adoption`,
        desc: `
        <p>Die COVID-19-Pandemie hat die Welt zum Stillstand gebracht und die Art und Weise, wie wir leben und arbeiten, stark beeinträchtigt. Mit der Ausbreitung des Virus wird immer deutlicher, dass die Technologie eine wesentliche Voraussetzung dafür ist, dass wir unseren täglichen Aktivitäten nachgehen können, ohne das Virus zu verbreiten. Die Pandemie hat zu einem raschen Anstieg des Technologieeinsatzes geführt, und die Unternehmen mussten sich schnell an diese neue Realität anpassen.</p><div class="par-spacer"></div>
        <p>Eine der größten Auswirkungen der Pandemie war die Verlagerung zur Telearbeit. Da die Mitarbeiter ihren Arbeitsplatz nicht aufsuchen konnten, mussten die Unternehmen Wege finden, um ihren Betrieb ohne Unterbrechung aufrechtzuerhalten. Dies hat zu einem enormen Anstieg der Nutzung von Collaboration-Tools wie Videokonferenzsoftware und virtuellen Projektmanagement-Tools geführt. Diese Tools ermöglichen es den Unternehmen, ihre Arbeit aus der Ferne fortzusetzen und sicherzustellen, dass ihre Mitarbeiter so zusammenarbeiten können, als wären sie im selben Büro.</p><div class="par-spacer"></div>
        <p>Ein weiterer Bereich, in dem die Technologie in den Vordergrund getreten ist, ist der Online-Handel. Da die Menschen den Gang in die Geschäfte scheuen, ist der Online-Einkauf beliebter als je zuvor geworden. Die Einzelhändler mussten sich schnell auf diesen Wandel einstellen, in ihre Online-Plattformen investieren und ihre Online-Präsenz ausbauen, um der wachsenden Nachfrage gerecht zu werden. Dies hat zu einem enormen Anstieg der Nutzung von E-Commerce-Plattformen geführt, die heute ein wesentlicher Bestandteil der Strategien vieler Unternehmen sind.</p><div class="par-spacer"></div>
        <p>Schließlich hat die Pandemie auch den Einsatz von Technologien der künstlichen Intelligenz und des maschinellen Lernens gefördert. Diese Technologien wurden eingesetzt, um die Ausbreitung des Virus zu verfolgen und vorherzusagen, aber auch um die Verwaltung von Ressourcen wie Krankenhausbetten und medizinischem Material zu unterstützen. Darüber hinaus wurden Chatbots entwickelt, die bei der Beantwortung von Fragen und der Bereitstellung von Informationen über die Pandemie helfen können, so dass sich das Gesundheitspersonal auf wichtigere Aufgaben konzentrieren kann.</p><div class="par-spacer"></div>
        <p>Zusammenfassend lässt sich sagen, dass die COVID-19-Pandemie zu einem raschen Anstieg des Technologieeinsatzes geführt hat, da sich Unternehmen und Einzelpersonen an neue Arbeits- und Kommunikationsmethoden anpassen mussten. Die Pandemie hat uns gezeigt, wie wichtig die Technologie ist, damit wir unsere täglichen Aktivitäten fortsetzen können, ohne das Virus zu verbreiten, und sie hat neue Möglichkeiten für Unternehmen geschaffen, die Technologie zur Verbesserung ihrer Abläufe einzusetzen. Es ist wahrscheinlich, dass die Technologie auch in Zukunft eine immer wichtigere Rolle in unserem Leben und unseren Unternehmen spielen wird.</p><div class="par-spacer"></div>
        `
      },
      content5: {
        title: `Der Aufstieg und Fall von Blockbuster: Der Preis für fehlende Anpassung an die veränderte Technologie`,
        desc: `
        <p>Es waren die 1980er Jahre, und der Durchschnittsverbraucher hatte verschiedene Möglichkeiten, Filme zu sehen, hauptsächlich das Ausleihen von Filmen in der örtlichen Videothek. Damals war Blockbuster Video der führende Anbieter von Leihfilmen und verdiente aufgrund der hohen Nachfrage nach Filmen viel Geld. Doch die Welt veränderte sich, und die Technologie entwickelte sich in rasantem Tempo weiter. Die Einführung von Streaming-Diensten wie Netflix bedeutete, dass sich Blockbuster an den veränderten Markt anpassen musste. Leider gelang ihnen das nicht, was schließlich zu ihrem Untergang führte. </p><div class="par-spacer"></div>
        <p>1989 eröffnete Blockbuster sein erstes Geschäft und wuchs innerhalb von 10 Jahren von einer kleinen Ladenkette zu einem globalen Imperium mit über 9.000 Geschäften in 25 Ländern. Das massive Wachstum des Unternehmens war dem Erfolg des Verleihmodells zu verdanken. Die Verbraucher konnten Filme in ihrem örtlichen Geschäft ausleihen und sie anschließend zurückgeben, ohne dafür zu bezahlen. Dieses Modell ermöglichte einen breiteren Zugang zu Filmen zu einem erschwinglichen Preis und fand bei vielen Menschen Anklang. </p><div class="par-spacer"></div>
        <p>Doch auch wenn das Verleihmodell anfangs funktionierte, reichte es nicht aus, um sich dauerhaft zu behaupten. Mit dem Aufkommen von Streaming-Diensten wie Netflix hatten die Verbraucher eine einfachere Möglichkeit, Filme anzusehen, ohne ihr Haus verlassen und dafür bezahlen zu müssen. Netflix bot seinen Abonnenten außerdem eine unbegrenzte Anzahl von Filmen an, die sie jederzeit ansehen konnten. Dies führte zu einem beträchtlichen Rückgang der Kunden von Blockbuster, was schließlich zum Untergang des Unternehmens führte. </p><div class="par-spacer"></div>
        <p>Das Scheitern von Blockbuster verdeutlicht, wie wichtig es für Unternehmen ist, sich an den technologischen Wandel anzupassen, damit sie überleben können. Wenn die Technologie voranschreitet und neue Trends auftauchen, müssen Unternehmen bereit sein, Änderungen vorzunehmen, um mit diesen Trends Schritt zu halten, oder sie riskieren, potenzielle Kunden zu verlieren. Genau das hat Blockbuster versäumt, was das Unternehmen sein Geschäft gekostet hat. </p><div class="par-spacer"></div>
        <p>Kurz gesagt, der Niedergang von Blockbuster erinnert uns daran, dass Unternehmen in der Lage sein müssen, sich an die sich verändernden Technologien anzupassen, oder sie riskieren, in einem hart umkämpften Markt zu scheitern. Der Niedergang von Blockbuster dient als Beispiel dafür, dass Unternehmen bereit sein müssen, sich auf Veränderungen einzulassen, wenn sie relevant und erfolgreich bleiben wollen. Es dient auch als wichtige Lektion, dass kein Unternehmen vor dem Scheitern gefeit ist, wenn es nicht lernt, mit dem technologischen Fortschritt Schritt zu halten.</p><div class="par-spacer"></div>
        `
      }
    },

    solution: {
      header: {
        title: 'Lösungen',
        desc: 'Wir bieten eine Reihe speziell entwickelter Lösungen, die den Fortschritt und das Wachstum Ihres Unternehmens fördern'
      },
      item1: {
        title: 'Webseitenentwicklung',
        desc: `Eine gut gestaltete und benutzerfreundliche Oberfläche kann die Konversionsrate einer Website um bis zu 200% erhöhen. Daher ist es wichtig, dass Ihre Geschäfts-Website durch ein ansprechendes Design, vollständige Funktionen und eine einfache Navigation effektiv wird. Wir können all dies gemäß Ihren Bedürfnissen und Vorlieben handhaben.<br/><br/>Von der Schaffung von Corporate Websites, E-Commerce-Sites, Microsites, Landing Pages bis hin zu benutzerdefinierten Designs mit professionellen und funktionenreichen Designs. Wir sind hier, um sicherzustellen, dass Ihre Website nicht nur gut aussieht, sondern auch optimal funktioniert, um den Bedürfnissen Ihres Unternehmens zu entsprechen.`
      },
      item2: {
        title: 'Mobile Anwendung ',
        desc: `Ca. 70% der Online-Shopper verlassen aufgrund einer schlechten Benutzererfahrung beim Zugriff auf die Anwendung ihren Einkaufswagen. Lassen Sie das nicht mit Ihrem digitalen Geschäft geschehen. Beginnen Sie mit uns zu arbeiten, da wir nicht nur auf die Benutzerfreundlichkeit für die Anwender Ihrer Geschäftsanwendungen achten, sondern auch extra Aufmerksamkeit auf das Design legen, um es optisch ansprechend und angenehm für die Benutzer zu gestalten.`
      },
      item3: {
        title: 'Entwicklung von Computerspielen',
        desc: `Entdecke das ungenutzte Potenzial deines Unternehmens mit einem fesselnden und einzigartigen Werbemedium - Videospiele! Mach dir keine Sorgen darüber, wie du es ansprechend gestalten kannst, denn wir werden mit dir zusammenarbeiten, um deine Ideen zum Leben zu erwecken. Von der Bestimmung des richtigen Videospiels, das mit deinem Unternehmen übereinstimmt, bis hin zur Gestaltung und Umsetzung der Produktion - ob es sich um Computer-Spiele, Konsolen-Spiele oder Mobilgeräte handelt, wir haben alles im Griff. Embracing die Kraft dieses innovativen Werbungsansatzes heute!`
      },
      item4: {
        title: 'ERP-System für Unternehmen',
        desc: `Maximieren Sie Ihre Geschäftseinsparungen und vereinfachen Sie Ihre Prozesse mit dem richtigen Enterprise Resource Planning oder ERP. Mit genauen Daten können Sie bis zu 46% Ihrer Geschäftskosten einsparen und die Produktivität um 90% steigern. Unser Team bei Odoo ERP steht Ihnen zur Verfügung und bietet eine Vielzahl von Funktionen, um Ihre ERP-Implementierung erfolgreich zu gestalten. Buchen Sie heute eine Beratung bei uns, um die beste auf Ihre spezifischen Geschäftsanforderungen zugeschnittene ERP-Lösung zu finden.`
      }
    },

    project: {
      header: 'Projekte',
      quote:
        'Lassen Sie uns Sie auf eine Reise mitnehmen, um tiefer in die KeDA Tech einzutauchen und die erstaunlichen Projekte zu entdecken, an denen wir gearbeitet haben, einschließlich der Zeit, die es brauchte, um jedes davon zum Leben zu erwecken.',
      see: 'Siehe Projekt',
      load: 'Mehr laden',
      time: 'Uhrzeit',
      months: 'Monate',
      worker: 'Erforderliche Mannschaft',
      tech: 'Verwendete Technologien',
      timeline: 'Zeitplan für eine wirksame Planung',
      development: 'Wochen in der Entwicklung',
      'task-completed': 'Aufgabe abgeschlossen',
      point1: 'Das Problem verstehen',
      point2: 'Ideenfindung',
      point3: 'Lösungen',
      explore:
        'Erkunden Sie andere bahnbrechende Projekte, die den Status quo in Frage stellen und zu Innovationen anregen',
      detail: {
        'item-wayfinder': {
          name: 'Wayfinder Indonesien',
          subtitle:
            'Verändern Sie Ihr Indoor-Navigationserlebnis mit nahtloser Integration in Ihr Fertigungssystem',
          desc: 'Mit diesem System können Ihre Mitarbeiter den Wareneingang und -ausgang effizient und einfach verwalten.',
          'desc-point1': `Wie kann die Integration von Technologie in Fertigungs- und Lagersysteme die Arbeit der Mitarbeiter erleichtern und ihre Produktivität steigern?`,
          'desc-point2': `Dieses System wurde mit dem Ziel entwickelt, die Effizienz der Lagerarbeiter zu revolutionieren, insbesondere im Hinblick auf die Anordnung der Waren in einem Raum. Stellen Sie sich eine Welt vor, in der die Platzierung von Artikeln in einem Lager auf ihr maximales Potenzial hin optimiert wird, was zu einem strafferen und effizienteren System führt, von dem alle Beteiligten profitieren. Es ist an der Zeit, die Art und Weise, wie wir Lagerung und Organisation am Arbeitsplatz angehen, zu überdenken.`,
          'sub-point3': `Die Einführung von Wayfinder revolutioniert die Art und Weise, wie Lagerhäuser arbeiten, und macht sie zu einem Markenzeichen für Effizienz und Effektivität`,
          'desc-point3': `Da die eingegebenen Daten mit dem aktuellen Zustand des Raumes integriert werden, entsteht eine dynamische und fesselnde Verschmelzung von Informationen und Umgebung.`,
          quote: `"Ihre ständige Kommunikation, um die Bedürfnisse des Prozesses zu lösen, ist der Schlüssel zu einem soliden Projektmanagement. Das Team lobt auch ihr Engagement für den Erfolg ihrer Aufgaben durch ihre Proaktivität und Integrität."`,
          'quote-by': `Richard Moran, IT-Projektkoordinator`
        },
        'item-axa': {
          name: 'AXA SMART DRIVER',
          subtitle:
            'Genießen Sie ein reibungsloses und müheloses Fahrerlebnis mit Virtual Reality-Technologie!',
          desc: 'Machen Sie sich bereit für ein außergewöhnliches Fahrerlebnis mit interaktiver VR! Erleben Sie den Nervenkitzel des Fahrens in einer virtuellen Stadt mit unvergleichlichem Komfort und Sicherheit',
          'desc-point1': `AXA stellt uns vor die Herausforderung, das Autofahren sicherer zu machen. Gemeinsam mit AXA bieten wir Ihnen ein außergewöhnliches Fahrsimulationserlebnis in der Stadt Anfield. Mit interaktiver und fortschrittlicher VR-Technologie können Sie das Fahren in der realen Welt sicher und bequem erleben.`,
          'desc-point2': `Diese Simulation wurde mit einem Hauptziel entwickelt: dem Fahrer ein außergewöhnliches Fahrerlebnis zu bieten. Mit hochentwickelter interaktiver VR-Technologie nehmen wir Sie mit auf eine sichere und komfortable Reise mit einem äußerst realistischen Eindruck.`,
          'sub-point3': '',
          'desc-point3': `AXA Smart Driver gibt Autofahrern die Möglichkeit, ohne Sorgen zu fahren. Mit seiner ausgeklügelten Technologieunterstützung hilft er Ihnen, Ihre Sicherheit auf einzigartige Weise zu erhalten. Diese Technologie warnt Sie vor Gefahren, damit Sie sich auf die Straße konzentrieren und durch sichere Fahrten Zeit sparen können. Probieren Sie AXA Smart Driver aus und erleben Sie den Nervenkitzel eines angstfreien Fahrens!`,
          quote: `"Wir freuen uns sehr, dass wir die von KeDa Tech angebotene Technologie für unsere Fahrsimulation nutzen können. Mit dem Hauptziel, unseren Fahrern ein außergewöhnliches Fahrerlebnis zu bieten, glauben wir, dass diese fortschrittliche interaktive VR-Technologie es uns ermöglichen wird, eine sichere und komfortable Fahrt mit einem äußerst realistischen Gefühl zu schaffen. KeDa Tech hat bei der Erstellung dieser Simulation mit Professionalität und Fachwissen gearbeitet. Wir sind alle sehr beeindruckt von ihrer Arbeit. Sie haben das Projekt sicher, genau und zuverlässig gemacht und damit sichergestellt, dass alle Fahrer ein außergewöhnliches Fahrerlebnis haben. Wir sind auch sehr dankbar für die Unterstützung von KeDa Tech bei der Erstellung dieser Simulation. Sie stellen die Kundenzufriedenheit in den Vordergrund und bieten hochwertige Servicegarantien, damit wir ein hochwertiges, effizientes und genaues Produkt erstellen können. Wir empfehlen KeDa Tech unseren Kunden, die Simulationen auf der Grundlage interaktiver VR-Technologie erstellen möchten, wärmstens. Wir glauben fest daran, dass sie unseren Kunden immer helfen werden, ihre Ziele zu erreichen, indem sie die besten Lösungen anbieten. Vielen Dank an KeDa Tech für die gute Zusammenarbeit."`,
          'quote-by': ``
        },
        'item-load-container': {
          name: 'Load Container',
          subtitle: 'Software zur Steigerung der Effizienz im Frachtbetrieb',
          desc: '',
          'desc-point1': `Wie kann man die Warenmenge in einem Frachtcontainer maximieren? (Ohne diese Software wird die Anordnung der Waren in einem Container und der Ladung vielen Versuchen unterzogen. Daher dauert es länger, die Anzahl der Container in einer Ladung zu berechnen und zu schätzen).`,
          'desc-point2': `Erzielen Sie eine mühelose Optimierung mit dem Container Loader Programm - Verabschieden Sie sich von manuellen Berechnungen und begrüßen Sie eine mühelose Optimierung. Unser Programm berechnet die Menge der Waren in Containern und der Container in der Ladung und schlägt sogar die beste Containerkombination vor, um Ihre betriebliche Effizienz und Rentabilität zu maximieren.`,
          'sub-point3': '',
          'desc-point3': `Diese Software bietet den Benutzern eine einfache Möglichkeit, eine optimale Containerbeladung zu erreichen. Mit den verschiedenen einzigartigen Funktionen können die Benutzer die besten Kombinationen von Containern für die Beladung bestimmen und alternative Bestimmungsorte durch Softwareberechnungsempfehlungen auswählen. Diese Software verspricht effiziente und zuverlässige Lösungen für die Beladung von Gütern und die Anordnung von Containern bei gleichzeitiger Berücksichtigung von Kosten und Effizienz.`,
          quote: ``,
          'quote-by': ``
        },
        'item-sqm-chatbot': {
          name: 'SQM CHATBOT',
          subtitle:
            'Erleben Sie die Interaktion und den Austausch von Informationen mit hoher Geschwindigkeit durch unseren vernetzten Online-Chatbot!',
          desc: 'Dieser Chatbot ermöglicht es Ihnen, Fragen zu beantworten und die Interaktion mit Kunden sofort zu steigern. Mit seiner fortschrittlichen Technologie kann der Chatbot verschiedene Arten von Interaktionen abdecken, die vielfältig und in Echtzeit sind. Mit diesem Chatbot können Sie das beste Serviceerlebnis für Ihre Kunden schaffen.',
          'desc-point1': `Wie können wir mit unseren Kunden über einen längeren Zeitraum in Verbindung bleiben, ohne dass wir immer wieder Kontaktdaten sammeln müssen?`,
          'desc-point2': `Chatbots haben die Aufgabe, blitzschnelle Lösungen für Kunden zu bieten, die Lücke zu überbrücken, bis sie mit einem menschlichen Agenten in Verbindung treten können, und rund um die Uhr Support ohne zeitliche Einschränkungen zu bieten.`,
          'sub-point3': '',
          'desc-point3': `Mit dem Chatbot SQM von SQM Property können Kunden schnell und präzise Antworten zu SQM Property erhalten. Zu jeder Zeit, ohne zeitliche Einschränkungen, können Kunden einfach und effizient Antworten auf ihre Fragen zu SQM Property finden`,
          quote: `"Wir waren uns von Anfang an sicher, dass wir mit KeDa Tech die richtige Wahl getroffen haben, um unsere Vision eines Chatbots zu verwirklichen. Die von Ihnen bereitgestellte Lösung war nicht nur schnell und genau, sondern auch für unsere Kunden einfach zu bedienen. Unsere Kunden können nun jederzeit und ohne zeitliche Einschränkung schnelle Antworten auf ihre Fragen zu SQM Property erhalten.<br><br>Das Team war hochprofessionell und hat auf alle Fragen und Anliegen, die wir hatten, zeitnah geantwortet. Die Liebe zum Detail und das Engagement, ein qualitativ hochwertiges Produkt zu liefern, waren in jedem Aspekt des Projekts offensichtlich.<br><br>Wir sind mit dem Ergebnis dieses Projekts äußerst zufrieden und würden KeDa Tech jedem, der einen Partner für die Softwareentwicklung sucht, wärmstens empfehlen."`,
          'quote-by': ``
        },
        'item-verif-doctor': {
          name: 'Mit Jasa Raharja Zugang zu fachkundigen Gesundheitsdiensten eröffnen',
          subtitle:
            'Revolutionierung des Gesundheitswesens mit hochmodernen Anwendungen für das Arzt-Berater-Management: Steigern Sie die Effizienz, verbessern Sie die Kommunikation und straffen Sie die Arbeitsabläufe für optimale Patientenresultate!',
          desc: `Nach einem Unfall ist das Letzte, worüber sich ein Opfer oder seine Angehörigen Gedanken machen sollten, die komplizierten und verwirrenden Abrechnungsverfahren im Krankenhaus. Deshalb haben wir uns verpflichtet, modernste Technologie einzusetzen, um den Prozess für Patienten, die von Jasa Raharja versichert sind, zu vereinfachen. Unsere innovative Lösung ermöglicht eine einfache Berechnung und Überprüfung der Krankenhauskosten und gibt den Bedürftigen ein Gefühl der Sicherheit. Keine stundenlangen Warteschleifen mehr und keine Frustration über fehlende Informationen. Mit unserem optimierten Ansatz machen wir die Gesundheitsversorgung für alle zugänglicher und weniger stressig.`,
          'desc-point1': `Der Weg zur Genesung nach einem Unfall kann lang und anstrengend sein, vor allem wenn es um die Überprüfung der Daten für die PT Jasa Raharja-Versicherung geht. Herkömmliche Verifizierungsverfahren sind oft langsam und umständlich, was sowohl für Arztberater als auch für Unfallopfer Schwierigkeiten mit sich bringt. Aber was wäre, wenn es eine Möglichkeit gäbe, diesen entscheidenden Schritt auf dem Weg zur Heilung zu beschleunigen? Durch den Einsatz modernster Technologie ermöglichen wir eine schnelle und genaue Überprüfung der Daten von Unfallopfern, die über PT Jasa Raharja versichert sind. Kein Warten mehr, keine Frustration mehr. Mit unserem rationalisierten Ansatz helfen wir Unfallopfern und ihren Familien, die Unterstützung zu bekommen, die sie brauchen, wenn sie sie brauchen. Helfen Sie uns, die Art und Weise der Datenüberprüfung nach einem Unfall zu revolutionieren.`,
          'desc-point2': `Bisher war die Überprüfung der Daten der Versicherungsgesellschaft PT Jasa Raharja bei der Betreuung von Unfallopfern, die von der Versicherung gedeckt sind, nicht effizient genug. Um dieses Problem anzugehen, sucht unser Team nach Lösungen, um die Effizienz dieses Prozesses zu erhöhen. Nach einer Reihe von Wartungsarbeiten haben wir beschlossen, eine Anwendung zu entwickeln, die die Effizienz des Datenüberprüfungsprozesses von PT Jasa Raharja verbessern soll. Wir hoffen, dass diese Anwendung den Zeit- und Kostenaufwand verringern und den Prozess einfacher und effizienter gestalten kann, so dass die beratenden Ärzte die Unfallopfer schneller und genauer behandeln können.`,
          'sub-point3': '',
          'desc-point3': `Wir stellen die Anwendung zur Überprüfung des beratenden Arztes vor, um den Überprüfungsprozess von Krankenhausabrechnungsdaten für Unfallopfer, die von der Jasa Raharja abgedeckt sind, zu unterstützen. Diese Anwendung ist eine Lösung, die den Überprüfungsprozess für beide Parteien, den behandelnden Arzt und das Unfallopfer, schneller und einfacher macht.`,
          quote: `"Keda Tech war eine enorme Hilfe bei der Entwicklung und Einführung unserer Lösung bei Jasa Raharja. Sie haben uns dabei geholfen, ein rationalisiertes Verfahren zu entwickeln, um die Gesundheitsversorgung für die von Jasa Raharja versicherten Patienten insgesamt zu verbessern. Ihr Team von Fachleuten, von Projektmanagern bis hin zu Software-Ingenieuren, war allesamt sehr professionell, kommunikativ und reaktionsschnell, um uns bei der Entwicklung eines innovativen Produkts zu helfen, das den Zahlungsprozess für Patienten und ihre Familien nach einem Unfall erleichtert. Wir sind stolz darauf, Teil eines größeren Programms zu sein, das die Gesundheitsversorgung für alle Bevölkerungsgruppen erschwinglich und stressfrei machen soll. Wir sind zuversichtlich, dass die Zusammenarbeit mit Keda Tech in Zukunft einen nachhaltigen positiven Einfluss auf die Patientenerfahrung haben wird."`,
          'quote-by': ``
        },
        'item-health-clinic': {
          name: '',
          subtitle:
            'Einsatz einer Anwendung zur Erleichterung der Verwaltung aller Aktivitäten in der Klinik, von der Speicherung der Patientendaten bis zur Verfolgung der Tätigkeit des Personals und dessen kontinuierlicher Überwachung und Bewertung.',
          desc: `Neben der Klinikverwaltung können Ärzte auf die Anwendung zugreifen und sie nutzen, um die Fortschritte ihrer Patienten zu überwachen. Auf diese Weise können die Ärzte über die neuesten Entwicklungen auf dem Laufenden bleiben und ihren Patienten die bestmögliche Versorgung bieten, um sicherzustellen, dass sie auf dem richtigen Weg zu einer schnellen Genesung sind`,
          'desc-point1': `Um dieses Problem zu lösen, müssen wir zunächst die Anforderungen für eine effiziente Verwaltung der Klinik ermitteln. Dazu führen wir eine erste Umfrage durch, sammeln Daten, vergleichen sie mit Industriestandards und bewerten verschiedene verfügbare Lösungen. Sobald wir das Problem und die entsprechenden Anwendungen, die zur Lösung beitragen können, verstanden haben, können wir eine integrierte Echtzeitlösung für ein effektives und effizientes Patientenmanagement anbieten.`,
          'desc-point2': `Angefangen bei der Analyse der Probleme, mit denen die Klinikleitung konfrontiert ist, über die Formulierung von Ideen, wie die erste Technologie eingesetzt werden kann, um die Effektivität der Lösungen zu gewährleisten, bis hin zum Testen verschiedener Technologien, um zu sehen, welche geeignet ist, die Klinikleitung bei der Erreichung ihrer Ziele zu unterstützen, hat unser Team hart daran gearbeitet, eine Anwendung zu entwickeln, die der Klinikleitung helfen kann, Klinik- und Patientendaten in Echtzeit und in ihrer Gesamtheit zu verwalten und zu verarbeiten. Nachdem der Test- und Qualitätssicherungsprozess der Anwendung abgeschlossen ist, überprüfen wir noch einmal, ob die Projektziele gut erreicht worden sind.`,
          'sub-point3': '',
          'desc-point3': `Wir haben diese Anwendung als Lösung zur Steigerung der Effizienz und Produktivität von Klinikmanagern und Ärzten entwickelt. Mit dieser Anwendung können sie Gesundheitsdaten von Patienten und Klinikaktivitäten einfacher und schneller verwalten. Dies ist ein entscheidender Schritt zur Steigerung der Produktivität von Kliniken und Ärzten bei der Behandlung verschiedener Gesundheitsfragen.`,
          quote: `"Wir sind sehr dankbar für die großartige Arbeit von KeDa Tech bei der Entwicklung einer Anwendung, die uns hilft, unsere Kliniken effizienter und produktiver zu betreiben. Die Anwendung ermöglicht uns eine einfache und schnelle Verwaltung der Gesundheitsdaten der Patienten und der Klinikaktivitäten.<br><br>Mit dieser Anwendung können sich unsere Klinikmanager und Ärzte nun besser um ihre Patienten kümmern und dabei ein höheres Maß an Effizienz erreichen. Dies ist ein wichtiger Schritt zur Verbesserung der Produktivität unserer Kliniken und unserer Ärzte bei der Behandlung verschiedener Gesundheitsfragen.<br><br>Wir möchten KeDa Tech im Namen aller unserer Mitarbeiter danken, die von der Nutzung Ihrer App profitiert haben. Wir sind mit den bisherigen Ergebnissen sehr zufrieden und freuen uns auf eine weitere Zusammenarbeit mit Ihnen in der Zukunft."`,
          'quote-by': ``
        },
        'item-price-fetcher': {
          name: 'PRICE FETCHER',
          subtitle:
            'Software zum Verfolgen und Vergleichen von Preisen verschiedener E-Commerce-Anbieter, damit Sie das beste Preis-Leistungs-Verhältnis erzielen können!',
          desc: `Mit unserer Anwendung können Sie die Preise für das von Ihnen gesuchte Produkt vergleichen und das beste Angebot erhalten.`,
          'desc-point1': `Der Zugriff auf jede einzelne E-Commerce-Anwendung, nur um Preise zu vergleichen, kann ermüdend und unpraktisch sein. Wie können wir die Preise im gesamten elektronischen Handel mit einem einzigen Zugriff vergleichen?`,
          'desc-point2': `Wir haben uns von der Verwirrung der Verbraucher beim Vergleich von Produktpreisen im E-Commerce inspirieren lassen. Unser Ziel mit dieser Software ist es, den Prozess der Auswahl und des Vergleichs von Produktpreisen im E-Commerce zu vereinfachen und effizienter zu gestalten.`,
          'sub-point3': '',
          'desc-point3': `Diese Software bietet eine Lösung für das Problem des Zugriffs auf unterschiedliche Produktpreisdaten auf verschiedenen E-Commerce-Plattformen. Mit dieser Technologie können Nutzer die Preise der von ihnen gesuchten Artikel leicht abrufen, ohne auf die Anwendungen für jede E-Commerce-Plattform einzeln zugreifen zu müssen.`,
          quote: ``,
          'quote-by': ``
        },
        'item-gemx': {
          name: 'G-EMx',
          subtitle: '',
          desc: `Eine digitale Anwendung, um Ihre Geschäftsanforderungen jederzeit und überall zu verwalten`,
          'desc-point1': `Für viele Unternehmer kann es eine echte Herausforderung sein, alle Geschäftstätigkeiten an einem Ort zu erreichen. Die traditionelle Methode, mit verschiedenen Abteilungen zu koordinieren, um auf die Finanzdaten des Unternehmens zugreifen zu können, kann zeitaufwändig und ineffizient sein. Dies lässt die Geschäftsinhaber frustriert und unfähig, schnelle Entscheidungen auf Basis aktueller Informationen zu treffen.`,
          'desc-point2': `Mit höchster Sicherheitsstufe bieten wir eine Lösung für G-EMx, die es Geschäftsinhabern erleichtert, jederzeit und überall auf ihre finanziellen Daten zuzugreifen.`,
          'sub-point3': '',
          'desc-point3': `Geschäftsinhaber können jetzt die vollständige Kontrolle und Überwachung all ihrer Geschäftstätigkeiten, Zugang zu angepassten Finanzbedürfnissen und Echtzeitsichtbarkeit von Finanztransaktionen haben, alles auf einen Klick. Wir verstehen die Herausforderungen, denen Geschäftsinhaber bei der Verwaltung ihrer Finanzen gegenüberstehen, und darum haben wir eine Lösung entwickelt, die diese Herausforderungen angeht und ihnen die von ihnen benötigte Bequemlichkeit und Komfort bietet.`,
          quote: `"Wir sind begeistert, KeDa Tech als unseren Partner für die Schaffung einer digitalen App zur Verwaltung unserer Geschäftsanforderungen zu haben. Sie verstehen die Bedürfnisse moderner Unternehmen wirklich und haben eine Lösung geliefert, die unsere Erwartungen übertrifft. Mit dem Know-how von KeDa Tech im Software-Entwicklung haben wir in der Lage, unsere Operationen zu streamline und haben volle Kontrolle und Überwachung unserer Geschäftstätigkeiten zu jeder Zeit und an jedem Ort. Die App ist benutzerfreundlich, anpassbar und bietet Echtzeit-Sichtbarkeit in Finanztransaktionen. Wir empfehlen KeDa Tech jedem Unternehmen, das eine zuverlässige und innovative Lösung zur Verwaltung ihrer Geschäftstätigkeiten sucht. Sie sind Experten in ihrem Fach und liefern wirklich Ergebnisse."`,
          'quote-by': `Asmadi Ahmad - Geschäftsführer G-EMx Technologies`
        }
      }
    },

    consult: {
      form: {
        title: 'Beraten Sie sich jetzt mit uns',
        desc: 'Indem Sie in das digitale Geschäfts- und Technologieumfeld eintreten, werden sicherlich viele Fragen aufkommen. Sie können mit dem Ausfüllen des unten stehenden Formulars beginnen und wir werden die Antworten kostenlos bereitstellen.',
        input1: 'Name',
        input2: 'Ihr Geschäftsbereich',
        input3: 'Telefonnummer',
        input4: 'Email',
        input5: 'Ihre Fragen und Bedürfnisse',
        office: {
          name: 'Jakarta Büro',
          address:
            'Green Ville Komplex, Ratu Kemuning Straße, Block BL Nr. 1, RT08/RW14, Jakarta, +(62-81) 180-878-87'
        },
        send: 'Senden'
      },
      desc: {
        title: 'Was hast du davon?',
        desc: 'Das ist der genaue Ablauf des Prozesses'
      },
      point1: {
        title: '1. Das Problem',
        desc: `Lassen Sie uns tiefer in Ihre Geschäftstätigkeit eintauchen, indem wir bedeutungsvolle Gespräche führen, ein umfassendes Verständnis Ihrer einzigartigen Bedürfnisse und Ihrer aktuellen Technologieaufstellung gewinnen. Gemeinsam werden wir das Kernproblem identifizieren und maßgeschneiderte Lösungen vorschlagen, die zum Erfolg führen. Seien Sie versichert, wir werden unser Vorgehen ständig messen und optimieren, um außergewöhnliche Ergebnisse zu erzielen.`
      },
      point2: {
        title: '2. Die Lösung',
        desc: `Wir bei KeDa Tech wissen, dass der Schlüssel zum Erfolg in der Bereitstellung von Lösungen liegt, die wirklich einen Unterschied machen. Deshalb führt unser Expertenteam, bevor wir mit der Ausarbeitung einer Lösung beginnen, eine gründliche Untersuchung und Analyse Ihrer einzigartigen Situation durch. Erst dann machen wir uns an die Arbeit und entwickeln eine Reihe von Lösungen, die speziell darauf ausgerichtet sind, Ihre Herausforderungen zu meistern und Ihre Ziele zu erreichen.<br/><br/>Wir sind davon überzeugt, dass maßgeschneiderte Lösungen, die auf Ihre spezifischen Bedürfnisse zugeschnitten sind, der beste Weg sind, um echte Ergebnisse zu erzielen und eine nachhaltige Wirkung zu erzielen. Warum sollten Sie sich also mit einem Einheitsansatz zufrieden geben, wenn Sie Lösungen erhalten können, die genau auf Sie zugeschnitten sind? Bei KeDa Tech bekommen Sie genau das.`
      },
      point3: {
        title: '3. Nächste Schritte',
        desc: `Wir bei KeDa Tech wissen, wie wichtig es ist, starke und dauerhafte Beziehungen zu unseren Kunden aufzubauen. Deshalb endet unser Engagement für hervorragende Leistungen nicht, wenn ein Projekt abgeschlossen ist. Unser Follow-up-Prozess ist darauf ausgerichtet, die volle Zufriedenheit unserer Kunden zu gewährleisten, etwaige Bedenken auszuräumen und eine kontinuierliche Unterstützung für die Langlebigkeit Ihres Systems zu bieten. Dank regelmäßiger Kontrollen, prompter Problemlösungen und eines engagierten Support-Teams können Sie sich darauf verlassen, dass Ihr Erfolg für uns oberste Priorität hat. Treten Sie in die Reihen unserer zufriedenen Kunden ein und lassen Sie uns Ihren Erfolg noch lange nach Abschluss des Projekts weiter vorantreiben.`
      },
      review: 'Was sie sagten'
    },

    story: {
      header: {
        title: 'Wir über uns',
        desc: 'Mit dem Traum, ein international anerkanntes Software-Unternehmen zu sein, kam KeDA Tech im Jahr 2018 auf den Markt. Während seiner Entwicklung ist KeDA Tech überzeugt, dass die digitale Transformation viele Veränderungen in allen Bereichen des Lebens, insbesondere im Geschäftsumfeld von Unternehmen, Gemeinden und Gesellschaft insgesamt, mit sich bringen wird. <br><br>Schließe dich der digitalen Transformation bei KeDA Tech an! Aus dem Traum eines weltweit bekannten Software-Unternehmens sind wir da und bereit, die Zukunft zu gestalten. Wir sind überzeugt, dass die digitale Ära große Veränderungen und Fortschritte in allen Bereichen des Lebens, von Geschäften bis hin zu Gemeinden und der Gesellschaft insgesamt, mit sich bringen wird. Bereite dich auf die digitale Revolution mit KeDA Tech vor!'
      },
      'our-journey': {
        title: 'Unsere Reise',
        now: 'Jetzt',
        item1:
          'Aus einem Team bestehend aus 2 Programmierern und einem Designer stand KeDA Tech auf und konnte sein erstes Projekt, das Container Loader Project, abschließen.',
        item2:
          'Ein Jahr nach Gründung von KeDA Tech begann eine neue Zusammenarbeit mit AXA. In diesem Projekt haben wir einen VR-Simulator erstellt, der von 3 Programmierern und einem Designer in nur 2 Wochen erfolgreich abgeschlossen wurde.',
        item3:
          'KeDA Tech hat in Zusammenarbeit mit einem der größten Immobilienunternehmen in Jakarta eine Chatbot-Anwendung für SQM Property abgeschlossen.',
        item4:
          'Wir haben bereits verschiedene Projekte mit anderen Softwareentwicklern wie Shinta VR, Wallex, Luceria und PT Citra Kreasi Makmur abgeschlossen und möchten auch Teil Ihres digitalen Geschäftsfortschritts sein. Mit über 10 Programmierern bleibt KeDA Tech bestrebt, Ihnen den besten Service zu bieten.'
      },
      'our-story': {
        title: 'Unsere Geschichte',
        desc: `Nach Abschluss seines Master-Abschlusses in Kalifornien-Amerika im Jahr 2017 kehrte Kenneth Nursalim nach Indonesien zurück. Die in Amerika verbrachten Jahre öffneten ihm die Augen für die Tatsache, dass die Entwicklung von Informationssystemen und Technologie in Indonesien hinterherhinkte. <br><br>Entschlossen, dies zu ändern, gründete Kenneth Nursalim im Jahr 2018 KeDA Tech mit dem Ziel, ein international anerkanntes Softwarehaus zu werden. Begleiten Sie uns auf unserer Reise, während wir uns bemühen, die Tech-Branche zu revolutionieren und Indonesien in die vorderste Reihe der digitalen Welt zu bringen.`
      },
      'to-team': {
        desc: 'Wir tun von Herzen das Beste für Ihr Unternehmen.',
        'meet-team': 'Lass uns das Team kennenlernen'
      }
    },

    technology: {
      'short-desc': {
        title: 'Technologie',
        desc: 'Um Ihre technologische Fortschritte zu unterstützen, nutzen wir alle Funktionen unten, um Ihre Erfahrung zu verbessern.'
      },
      'unreal-engine': {
        title: 'UNREAL ENGINE',
        desc: 'Diese Spielengine produziert eine unglaublich stabile Framerate, atemberaubende Grafiken mit atemberaubenden Schatten, eintauchende Tiefenschärfe und unterstützt die neueste DX 11 Technologie. Bereiten Sie sich darauf vor, eine außergewöhnliche Spielereise wie nie zuvor zu erleben.',
        link: 'Mehr über UNREAL ENGINE erfahren',
        'long-desc': `Diese Spiel-Engine-Anwendung wurde von Epic Games erstellt und feierte 1998 ihre Premiere. Ausgehend von einem First-Person-Shooter-Spiel verfügt die Unreal Engine nun über die Fähigkeit, die Objekte um Sie herum in eine Echtzeit-3D-Visualisierung umzuwandeln. <br><br>Durch die ständige Verbesserung der Unreal Engine werden 3D-Formen realistischer dargestellt. Mit jeder Iteration wird die Engine immersiver und fesselnder, indem sie Sie in eine Welt jenseits Ihrer Vorstellungskraft führt.`,
        ask1: 'Wie wird diese Technologie angewendet, um neue Fenster für Kreativität und Innovation zu öffnen?',
        explanation1: `Die Unreal Engine eröffnet Ihnen eine Welt der unbegrenzten Möglichkeiten für Ihre Projekte. Die Unreal Engine eignet sich nicht nur für Spiele, sondern auch für Filme, Architektur, Autos und Transportmittel, Rundfunkveranstaltungen und sogar für Simulationen aller Art. Entfesseln Sie Ihre Fantasie und erwecken Sie Ihre Kreationen mit der Unreal Engine zum Leben.`,
        ask2: 'Welches sind die Vorteile?',
        explanation2: `Durch den Einsatz der Unreal Engine erhalten Sie eine perfekte visuelle Darstellung, die die Realität wirklich einfängt. Mit diesem leistungsstarken Werkzeug können Sie Simulationen in vollem Umfang erleben und Vorhersagen mit maximaler Genauigkeit treffen. Stellen Sie sich vor, Sie könnten in eine virtuelle Welt eintauchen, die sich genauso real anfühlt wie die, in der Sie leben. Das ist die Leistung der Unreal Engine`,
        project: {
          use: 'Unser Projekt, das UNREAL ENGINE verwendet',
          title: 'Mobile Anwendung',
          name: 'AXA',
          desc: `Ein interaktives VR-Erlebnis, das Sie auf eine aufregende virtuelle Fahrt durch die belebten Straßen von Anfield City mitnimmt. Tauchen Sie ein in die Hektik des städtischen Lebens und manövrieren Sie sich durch überfüllte Kreuzungen, enge Gassen und belebte Alleen. Mit einem hochmodernen VR-Headset und einer intuitiven Steuerung haben Sie das Gefühl, wirklich hinter dem Steuer zu sitzen, durch die Stadt in Echtzeit zu navigieren und alles zu entdecken, was Anfield zu bieten hat. Machen Sie sich bereit für eine unvergessliche Reise durch eine der aufregendsten Städte der Welt, die das Herz höher schlagen lässt!`
        }
      },
      'my-sql': {
        title: 'My SQL',
        desc: 'MySQL zeigt ein hohes Maß an Flexibilität gegenüber Technologie und ist eine plattformübergreifende Datenbank. Seine benutzerfreundliche Tabellenstruktur bietet hervorragende Leistung bei der Anwendung.',
        link: 'Mehr über MySQL erfahren',
        'long-desc': `Ursprünglich war MySQL eine Weiterentwicklung des UNIREG-Projekts, die mit dynamischen Datenbanken, die auf Websites verwendet werden, nicht sehr kompatibel war. Im Laufe der Entwicklung wurde MySQL zu einem Datenbankverwaltungssystem, das die grundlegenden SQL-Befehle (Structured Query Language) verwendet, die recht bekannt sind.`,
        ask1: 'Wie wird diese Technologie eingesetzt, um neue Fenster für Kreativität und Innovation zu öffnen?',
        explanation1: `MySQL fällt in die Kategorie der relationalen Datenbankmanagementsysteme, in der Begriffe wie Zeilen, Spalten und Tabellen in dieser Datenbankanwendung verwendet werden. <br><br>Tauchen Sie mit MySQL in die Welt der Datenbankverwaltung ein, in der die bekannten Begriffe Zeilen, Spalten und Tabellen im Bereich der relationalen Datenbanken zum Leben erweckt werden.`,
        ask2: 'Welches sind die Vorteile?',
        explanation2: `Durch den Einsatz von MySQL benötigen Sie keinen großen Arbeitsspeicher. Darüber hinaus unterstützt MySQL auch die Integration mit anderen Programmiersprachen, was es zu einer vielseitigen und effizienten Lösung macht.`,
        project: {
          use: 'Unser Projekt, das My SQL benutzt',
          title: 'Webseitenentwicklung',
          name: 'WayFinder',
          desc: `Die Integration eines Innennavigations-Systems in unsere Lagerverwaltung hat die Effizienz unserer Arbeiter erhöht.`
        }
      },
      'django-python': {
        title: 'Django Phyton',
        desc: `Die Geburt von Django fand im Jahr 2003 statt, aber es dauerte bis 2008, bis seine erste Version endlich der Welt präsentiert wurde. Dieses leistungsstarke Full-Stack-Framework, das in Python geschrieben ist, bietet Entwicklern eine nahtlose Möglichkeit, ihre Web-Anwendungsträume zum Leben zu erwecken.`,
        link: 'Erfahre mehr über Python',
        'long-desc': `Django wurde 2003 entwickelt, aber erst 2008 in der ersten Version für die Öffentlichkeit freigegeben. Dieses Full-Stack-Framework wurde entwickelt, um Entwickler bei der Entwicklung von Webanwendungen mit der Programmiersprache Python zu unterstützen. 2019 wurde nun endlich eine zweite Version von Django veröffentlicht, die Entwicklern mehr Werkzeuge zur Verfügung stellt, um ihre Vision von Webanwendungen zu verwirklichen und mehr Werkzeuge zur Verfügung gestellt, die Entwicklern helfen, ihre Vision einer Webanwendung zu verwirklichen.`,
        ask1: 'Wie wird diese Technologie angewendet, um neue Fenster für Kreativität und Innovation zu öffnen?',
        explanation1: `Django ist ein High-Level-Python-Web-Framework, das eine schnelle Anwendungsentwicklung mit einem sauberen und praktischen Design ermöglicht. Seine Effizienz und sein schlanker Ansatz machen es zu einer herausragenden Wahl für die Webentwicklung.`,
        ask2: 'Welches sind die Vorteile?',
        explanation2: `Django ist die perfekte Lösung für Projekte jeder Größe, von klein bis groß. Im Vergleich zu anderen Frameworks verfügt es über eine beeindruckende Anzahl von Funktionen, die es ermöglichen, alle wesentlichen Elemente für die Erstellung einer Anwendung mit Leichtigkeit zu handhaben.`,
        project: {
          use: 'Unser Projekt, das Django Phyton verwendet',
          title: 'Entwicklung von Computerspielen',
          name: 'Containerlader',
          desc: `Eine Simulationssoftware, die die Konfiguration der Güter, die in Fracht oder Container gelagert werden, optimal berechnet.`
        }
      },
      unity: {
        title: 'Unity',
        desc: `Das von Unity Technologies entwickelte Unity wurde 2005 auf den Markt gebracht und erhebt den Anspruch, eine bahnbrechende Plattform für die Erstellung und den Betrieb von Echtzeit-3D-Erlebnissen zu sein. Unity ist nicht nur auf 3D beschränkt, sondern bietet auch plattformübergreifende 2D-, VR- und AR-Projekte.`,
        link: 'Erfahre mehr über Unity',
        'long-desc': `Das von Unity Technologies entwickelte Unity wurde 2005 auf den Markt gebracht und erhebt den Anspruch, eine bahnbrechende Plattform für die Erstellung und den Betrieb von Echtzeit-3D-Erlebnissen zu sein. Unity ist nicht nur auf 3D beschränkt, sondern bietet auch plattformübergreifende 2D-, VR- und AR-Projekte. Mit seiner fortschrittlichen Technologie hat Unity die Welt der Spiele und interaktiven Inhalte revolutioniert und macht es einfacher denn je, beeindruckende, immersive Erlebnisse zum Leben zu erwecken.`,
        ask1: 'Wie wird diese Technologie angewendet, um neue Fenster für Kreativität und Innovation zu öffnen?',
        explanation1: `Unity kann bei der Erstellung von Spielen, Animationen, Automobildesign, Architektur und vielem mehr eingesetzt werden und eröffnet grenzenlose Möglichkeiten, die Fantasie zum Leben zu erwecken.`,
        ask2: 'Welches sind die Vorteile?',
        explanation2: `Einer der Vorteile von Unity ist sein benutzerfreundliches System, das einfach zu erlernen ist, aber dennoch Spiele mit einer großen Bandbreite an Möglichkeiten produzieren kann. Dies ermöglicht es Entwicklern, effizienter zu arbeiten und ihre kreativen Visionen mit Leichtigkeit zum Leben zu erwecken. Egal, ob Sie Anfänger oder erfahrener Spieleentwickler sind, Unity bietet die Werkzeuge und Ressourcen, um Ihre Ideen zum Leben zu erwecken. Mit seiner intuitiven Benutzeroberfläche und seinen leistungsstarken Funktionen ist Unity das ultimative Werkzeug für die Entwicklung beeindruckender Spiele.`,
        project: {
          use: 'Unser Projekt unter Verwendung von Unity',
          title: '',
          name: '',
          desc: ``
        }
      },
      'keda-engine': {
        title: 'KeDa Engine',
        desc: `Entdecke die Kraft des KeDa-Motors - ein Programm, das aus Entschlossenheit und dem Bedürfnis nach Geschwindigkeit geboren wurde. Mit seiner ursprünglichen Programmiersprache C# begegnete das KeDa-Team der Herausforderung einer langsamen Iteration.`,
        link: 'Erfahre mehr über KeDa Engine',
        'long-desc': `Entdecke die Kraft des KeDa-Motors - ein Programm, das aus Entschlossenheit und dem Bedürfnis nach Geschwindigkeit geboren wurde. Mit seiner ursprünglichen Programmiersprache C# begegnete das KeDa-Team der Herausforderung einer langsamen Iteration. Aber mit unerschütterlicher Entschlossenheit und Innovationsdrang besitzt der KeDa-Motor jetzt eine effizientere und leistungsstärkere Sprache von C++.`,
        ask1: 'Wie wird diese Technologie angewendet, um neue Fenster für Kreativität und Innovation zu öffnen?',
        explanation1: `Die KeDa Engine ist eine spezialisierte Technologie, die 2017 von KeDa entwickelt wurde und darauf abzielt, die Welt des Programmierens zu revolutionieren. Ursprünglich in der Sprache C# geschrieben, stieß das KeDa-Team auf die Herausforderung einer langsamen Iterationsgeschwindigkeit. Aber mit unerschütterlichem Willen und Innovationsdrang hat die KeDa Engine nun die effizientere und leistungsstärkere Sprache C++ adoptiert. Diese Technologie ist ein Zeugnis für KeDas Engagement, den bestmöglichen Service für ihre Kunden zu bieten, und ein Beispiel dafür, wie Durchhaltevermögen und Innovationslust zur Schaffung von Technologien mit Spielverändernder Wirkung führen können.`,
        ask2: 'Welches sind die Vorteile?',
        explanation2: `Der Vorteil der Verwendung von KedaEngine liegt in seinem angepassten System, das C++ und eine blitzschnelle Laufzeit im Vergleich zu anderen Engines nutzt.`,
        project: {
          use: 'Unser Projekt, das KeDa-Engine verwendet',
          item1: {
            title: 'Webseitenentwicklung',
            name: 'Wayfinder',
            desc: `Die Integration eines Innennavigations-Systems in unsere Lagerverwaltung hat die Effizienz unserer Arbeiter erhöht.`
          },
          item2: {
            title: 'Webseitenentwicklung',
            name: 'PRICE FETCHER',
            desc: `Software, um Preise von verschiedenen E-Commerce zu verfolgen und zu vergleichen, damit Sie den besten Wert erhalten können!`
          },
          item3: {
            title: 'Webseitenentwicklung',
            name: 'Load Container',
            desc: `Eine fortschrittliche Simulationssoftware, die die bestmögliche Konfiguration von Gegenständen in Frachträumen berechnen kann.`
          }
        }
      },
      back: 'Zurück zur Technik'
    }
  }
};
