import { zhHans } from 'vuetify/lib/locale';

export default {
  ...zhHans,

  kda: {
    common: {
      'book-banner': {
        btn: '联系我们',
        desc: '你的数字转型伙伴'
      },
      'ask-interest': {
        title: '你有兴趣和我们联合吗？',
        desc: '我们始终敞开心扉欢迎新人才',
        btn: '找到适合你的职位'
      },
      menu: {
        home: '首页',
        about: '关于',
        process: '过程',
        services: '服务',
        projects: '项目',
        blog: '博客',
        careers: '职业',
        consult: '咨询',
        story: '故事',
        team: '团队',
        tech: '技术',
        solutions: '解决方案'
      },
      footer: {
        address: `绿城综合体，Ratu Kemuning <br>街道，Block BL编号1, <br>RT08 / RW14，雅加达, <br>+(62-81) 180-878-87`,
        'address-mobile': `绿城综合体，Ratu Kemuning 街道，Block BL编号1, RT08 / RW14，雅加达, <br>+(62-81) 180-878-87`,
        'copy-right': `版权所有 &copy; 2022 KeDA Tech. 保留所有权利。`,
        'quick-links': '快速导航',
        'find-us': '找到我们',
        facebook: 'Facebook',
        instagram: 'Instagram社交平台',
        linkedin: '领英'
      },
      subscribe: {
        header: '获得我们最新和最好的信息！',
        content:
          '注册以接收关于我们的工作方式、鼓舞人心的案例研究等更新，这些一定会帮助您的业务增长。',
        subscribe: '现在订阅'
      }
    },

    home: {
      quote: {
        before: '从美国的专家程序员群体开始，',
        after: '致力于帮助您彻底变革您的企业，并提供合适的技术，以帮助您应对数字时代的商业挑战'
      },
      process: {
        title: '我们的工作流程',
        desc: '我们倾听并理解您的业务需求。',
        btn: '细节'
      },
      solutions: {
        title: '我们提供的解决方案',
        item1: {
          title: '网站开发',
          desc: '一个良好的界面可以将网站的转换率提高200％。因此，让您的商业网站具有具有吸引力的设计、完整功能和易于设置的效果是必不可少的。'
        },
        item2: {
          title: '手机应用',
          desc: '70％的网上购物者在使用应用程序时因用户体验不佳而离开了购物车。不要让这种情况发生在您的数字业务上。'
        },
        item3: {
          title: '电子游戏开发',
          desc: '通过游戏释放广告的潜力！告别无聊和传统的方法，拥抱独特和引人注目的策略。与我们一起，您将有机会将您的业务提升到下一个层次，因为我们将与您合作，每一步帮助您实现您的愿景。'
        },
        item4: {
          title: '企业级资源规划系统',
          desc: '用 ERP 数据的力量革新您的业务！通过利用准确的信息，您将节省大量费用，并使您的业务流程效率提高 90%。现在是时候使用 ERP 数据将您的业务提升到下一个层次。'
        }
      },
      projects: {
        title: '我们的计划',
        item1: { type: '网站开发', desc: '将室内导航系统与仓库管理系统集成，提高了工人的效率。' },
        item2: { type: '手机应用', desc: '一种交互式虚拟现实体验，让您感到在安菲尔德市驾驶。' },
        item3: { type: '电子游戏开发', desc: '一款能够计算货柜内物品最佳摆放方式的模拟软件。' },
        more: '发现更多精彩项目'
      }
    },

    teams: {
      header: {
        title: 'KeDA Tech团队简介',
        desc: '作为驻于雅加达的领先信息技术开发公司之一，KeDA Tech致力于用尖端技术满足您所有的IT系统和解决方案需求。'
      },
      members: {
        group1: '制作网站和应用程序',
        group2: '内容与社交媒体',
        group3: '商业与发展',
        group4: '产品设计'
      },
      structure: {
        desc1: `
        <p>在完成南加州大学的计算机科学硕士课程并在那工作了几年程序员之后，</p><br/>
        <p>Kenneth Nursalim 认识到印尼的技术可以比以前有更大的进步。他现在专注于他创办的软件公司 Keda Tech 的发展和进步。</p><br/>
        <p>凭借他在工程和游戏编程方面的经验，以及他在 C++、Java、C#、Unreal Engine 4 和 Unity 方面的技能，他相信 Keda Tech 可以成为印尼的领先技术先驱。</p>`,
        desc2: `
        <p>Darren 开始了他的学术之旅，专注于工程，然后继续深造商业和管理，最终毕业于加州州立大学。</p><br/>
        <p>在美国工作了很长时间后，他注意到美国和印尼的商业之间存在显著差距。受到这一差距的激励，</p><br/>
        <p>他与 Keda Tech 合作，开始了一次变革性的旅程，以革新其商业战略。</p>`,
        desc3: `
        <p>从布拉维加亚大学毕业，获得计算机科学学位后，Reno 因为与 Keda Tech 的共同愿景而加入该公司。</p><br/>
        <p>从软件工程师起步，逐步晋升为高级和首席开发人员，现在他是首席技术官。</p><br/>
        <p>凭借他在 Java、JavaScript、Python 等各种编程语言以及 Unity3D、Android 和 REST API 等多个平台方面的专业知识，Reno 相信他能为 Keda Tech 带来技术进步。</p>`
      }
    },

    careers: {
      header: {
        title: '职业生涯',
        desc: '我们是一群热情的程序员、设计师和项目经理，遍布印度尼西亚的各个角落。在KeDA Tech，我们相信远程工作的力量，我们的心深深地致力于为客户的业务提供正确的技术解决方案。'
      },
      second:
        '由其年轻团队成员的活力驱动，KeDA Tech 是一个富有想象力的想法和开创性创新的热土，推动公司朝共同成功的道路前进。',
      lives: {
        life1: {
          title: '在任何地方工作',
          desc: '释放您的生产力，释放您的创造力，无论在哪里工作，都能与团队保持同步。'
        },
        life2: {
          title: '与我们一起探索最新技术',
          desc: '加入KeDA Tech社区，开始您的科技学习之旅！作为成员，您将拥有访问令人兴奋的项目的机会，以助力您的职业成长。'
        },
        life3: {
          title: '一支充满激情和目的的动力梦幻阵容！',
          desc: '随着一个充满活力的团队，您不会被固定在同一个地方。相反，您将通过与KeDA Tech团队分享获得最新的知识和经验。'
        },
        life4: {
          title: '最先进的技术 - 推动界限，释放可能性！',
          desc: '跟随最新技术领先行列 - 加入 KeDA Tech，与尖端解决方案一起创新！'
        },
        life5: {
          title: '弹性工作时间 - 轻松平衡工作与生活！',
          desc: '在KeDA Tech轻松、灵活地工作 - 设定自己的时间表，同时与团队保持联络。加上KeDA Tech的健康保险覆盖，充分享受生活。'
        },
        bottom: '那么招聘过程是什么样的？'
      },
      looks: {
        title: '可能长这样',
        look1: {
          title: '求职搜索',
          desc: '当您发现我们的工作空缺，并且它与您寻找的职业机会和工作职责相匹配时。'
        },
        look2: {
          title: '提交简历',
          desc: '请给我们发送您的简历。不要忘记包括您的作品集，如果您有的话。这将使我们更好地了解您的技能，经验和工作样品。'
        },
        look3: {
          title: '人力资源沟通',
          desc: '在对你的简历进行彻底审查后，KeDA Tech的人力资源部门将迅速与你联系，安排面试。'
        },
        look4: {
          title: '面试',
          desc: '根据提交的简历，人力资源部门将匹配并评估您是否有能力填补KeDA Tech的空缺职位，并在必要时进行面试。'
        },
        look5: {
          title: '你被录用了',
          desc: '当你的能力和我们的需求同步时，我们非常高兴地欢迎你加入KeDA Tech团队！我们一起可以取得出色的成果，产生重大的影响。'
        }
      },
      opening: { title: '开放职位', apply: '申请', share: '分享' },
      details: {
        input1: '名字',
        input2: '电话号码',
        input3: '电子邮件',
        before: '申请截止日期为',
        cv: '在此处上传您的简历',
        select: '选择文件',
        drag: '请选择文件或将文件拖到此处',
        join: '立即加入'
      }
    },

    process: {
      next: '下一页',
      book: '与我们讨论',
      item1: {
        header: 'KeDa 的工作风格',
        title: '概述',
        desc: `在工业界，挑战是不可避免的。但不用担心，我们在这里帮助您发展您的企业<br/><br/>在KeDA Tech，我们采取亲手的方法解决您的商业问题，通过分析每一个问题并提供量身定制的解决方案，使用合适的技术。让我们帮助您应对挑战，在您的商业活动中腾飞到新的高度。`
      },
      item2: {
        title: '指导',
        desc: `为了启动我们的合作，创建指南是必不可少的。制作这份指南，我们需要完全了解您在生意中想要的东西。您可以直接告诉我们您的生意简介以及您的期望结果。通过清晰的沟通，我们的团队和您的理解可以得到建立，从而产生可持续的产品。`
      },
      item3: {
        title: '范围',
        desc: `项目的范围可以影响到项目结果的52%。这个范围使我们能够确切地了解您在数字商业产品中想要什么。我们深入探究你可能面临的任何商业问题，并找到完美的解决方案。在这个范围内，您还将看到我们的数字产品能做什么以及不能做什么，或者我们如何计划进一步开发它。`
      },
      item4: {
        title: '估计',
        desc: `这种估算技术可以帮助您准确预测业务产品的发展中的每一个元素。我们的项目估算是基于已经建立的范围以及其他因素，如发现津贴、交付和分配因素。这三个因素也可以确定团队完成的每个任务的时间和风险。`
      },
      item5: {
        title: '发展',
        desc: `随着你们项目规划的成功完成，数字业务产品的开发现在可以开始了。我们使用敏捷开发方法，以确保你的数字产品功能顺畅。这种方法因其生产高质量软件，符合您的要求而受到高度评价。此外，它保证软件既可用于大型项目，也可用于小型项目，并且易于适应变化。`
      },
      item6: {
        title: '支持',
        desc: `我们明白在商业中实施新技术可能是一项挑战，特别是对于您和使用它的所有人来说。这就是KeDA Tech一直陪伴您的原因<br/><br/>我们致力于为您的数字商业中实施技术提供最佳支持。不仅如此，我们还提供产品监控、提供有价值的反馈、提供备份维护和24/7的电话支持。让我们一起应对技术的挑战！`
      },
      item7: {
        title: '下一步',
        desc: `在仔细审查KeDA Tech的操作之后，您有两条道路可以选择。首先，在您的组织内部进行内部讨论后，重要的是实施KeDA Tech所列出的工作方法。尽管实施过程可能具有挑战性，并不像理论上看起来那么直接，但我们KeDA Tech的团队始终在这里提供援助并回答您的任何问题<br/><br/>第二步是将KeDA的所有过程和产品整合到您自己的公司中。集成这些产品无疑将是一项更复杂的任务，因为它涉及到软件和系统的使用，这些软件和系统将对您的业务的成功起到关键作用。为了实现这个目标，将需要适当的培训和额外的时间<br/><br/>如果您在此过程中遇到困难，请不要犹豫，向我们寻求支持。我们KeDA Tech的团队致力于确保您不会对技术感到困惑和迷茫。我们致力于为您的团队提供持续的支持，以确保顺利和成功的集成。`
      }
    },

    blog: {
      header: 'KeDA博客',
      search: '搜索',
      load: '显示更多',
      read: '阅读全文',
      trend: '流行文章',
      details: {
        related: '相关的文章',
        more: '查看更多文章'
      },
      content1: {
        title: `探索拥抱当今技术的 7 个理由`,
        desc: `
        <p>今天就利用技术加强您的企业实力！</p><div class="par-spacer"></div>
        <p>世界正在迅速进步，这要归功于技术在生活各个方面的突破，特别是在信息技术和商业领域。实时与一切互联已经变得更加容易。</p><div class="par-spacer"></div>
        <p>技术被视为在商业世界中的阻碍已经成为过去，因为适应难度已经降低。今天，技术是企业成长的必要工具。以下是利用技术在企业中的一些优势：</p><div class="par-spacer"></div>
        <ol>
        <li><p>简化操作任务<br/>有了结构化的技术，操作流程变得更容易，特别是当您能够快速适应技术时。这导致了更高效和有效的商业。</p></li><div class="par-spacer"></div>
        <li><p>改善沟通<br/>在大流行期间，技术使团队成员之间的沟通和合作更容易，即使在遥远的地方。视频会议、聊天和电子邮件等工具使沟通更快捷和更高效。</p></li><div class="par-spacer"></div>
        <li><p>更好的数据分析<br/>技术使收集和分析数据变得更加容易，帮助您做出明智的商业决策。准确和最新的数据导致更好的决策，帮助您实现所期望的目标。</p></li><div class="par-spacer"></div>
        <li><p>扩大市场覆盖<br/>技术使您的业务能够在国内外范围内拓展市场。技术的进步使得通过互联网和社交媒体推广和销售产品变得更加容易，从而达到更多潜在客户。</p></li><div class="par-spacer"></div>
        <li><p>提高业务效率<br/>技术有助于优化您的业务流程，降低成本。例如，使用业务软件可以减少人工成本，并使财务控制和报告更加容易。</p></li><div class="par-spacer"></div>
        <li><p>提高安全性<br/>技术也有助于您的业务提高数据和信息安全性。通过加密和强大的安全系统，您可以确保您的重要数据不受未经授权的访问。</p></li><div class="par-spacer"></div>
        <li><p>提高生产力<br/>技术使您的业务更容易提高生产力。例如，使用简化任务管理和计划的软件，您可以节省时间，确保重要任务及时完成。</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content2: {
        title: `了解ERP及其对您业务的最佳利益`,
        desc: `
        <p>企业资源规划（ERP）是一个强大的系统和软件解决方案，适用于大型企业。ERP有助于管理各种业务活动，如财务、采购、生产、人力资源、员工监控等。</p><div class="par-spacer"></div>
        <p>选择ERP是因为它可以提供实时和准确的信息，协助公司做出更好的商业决策。然而，这只有在公司能够迅速适应ERP的情况下才能发生。实施ERP的好处不止于此，因为它还有其他几个优点，可以大大有利于企业。</p><div class="par-spacer"></div>
        <ol>
        <li><p>提高公司效率。通过实施ERP，物理数据必须转换为数字数据。数字化的实时数据可以被公司内部的每一个人访问，使其更容易获得信息，加快工作进程。</p></li><div class="par-spacer"></div>
        <li><p>加强合作。公司内部所有员工都可以直接访问数据，这有助于提高部门间的合作，使信息的获取更加容易。基于云计算的ERP甚至允许公司的分支机构之间通过互联网进行协作。</p></li><div class="par-spacer"></div>
        <li><p>降低运营成本。自动化的操作流程有助于降低劳动力成本，并预测操作问题和故障。</p></li><div class="par-spacer"></div>
        <li><p>提高数据安全性。ERP系统有限制性控制，以防止数据泄漏，只有管理员才有访问钥匙。</p></li><div class="par-spacer"></div>
        <li><p>准确的业务预测。ERP提供的实时和一致的报告有助于创建更准确的业务预测。</p></li><div class="par-spacer"></div>
        </ol>
        <p>以前，ERP只适用于大型企业，但现在中等规模的公司也需要它。科达科技提供可定制的ERP解决方案，以满足您的业务需求。不要犹豫，请联系我们，体验ERP给您的企业带来的好处。</p><div class="par-spacer"></div>
        `
      },
      content3: {
        title: `三个令人信服的原因，为什么您的企业绝对需要一个网站`,
        desc: `
        <p>尽管社交媒体和市场目前是在线销售的趋势，但全球网络指数告知，62%的客户更愿意通过网站搜索品牌或产品的信息。<br/>对于企业家来说，这个数据是不容忽视的。如果他们的企业没有一个官方网站，他们的商业机会可能会下降。不仅是作为客户的信息来源，网站还可以使他们的业务更广为人知，吸引新客户。<br/>以下是你的企业应该拥有一个官方网站的3个重要原因。</p><div class="par-spacer"></div>
        <ol>
        <li><p>公司可信度。<br/>网站对所有类型的企业都很重要，无论大小，因为它们可以使潜在客户信任我们。我们可以展示来自客户的真实评论或反馈。这样一来，潜在客户也会信任我们的企业，因为它被认为是专业的。</p></li><div class="par-spacer"></div>
        <li><p>推广场所。<br/>网站是完美的推广场所，特别是如果SEO应用得好。网站流量会增加，自动会有对我们产品感兴趣的买家。此外，网站可以一天24小时，一周7天地推广我们的业务。</p></li><div class="par-spacer"></div>
        <li><p>提高客户服务质量。<br/>一个可以根据客户的舒适度进行调整的网站可以使客户忠诚。其中之一是通过添加联系页面，如带有电子邮件的问题表，或从网站上创建一个聊天工具或即时聊天。<br/>技术将继续发展，如果我们的企业不能适应，那将是痛苦的。因此，科大讯飞充分理解每一项技术在商业中都有重要的作用。您可以联系我们，以进一步实施。</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content4: {
        title: `从危机到创新：疫情对技术采用的影响`,
        desc: `
        <p>COVID-19大流行病已使世界陷入停滞，并极大地影响了我们的生活和工作方式。随着病毒的传播，越来越清楚的是，技术对于让我们在不传播病毒的情况下继续进行日常活动至关重要。这场大流行引起了技术应用的迅速增加，企业不得不迅速适应这一新的现实。</p><div class="par-spacer"></div>
        <p>大流行病的最大影响之一是向远程工作的转变。由于员工不能去他们的工作场所，公司不得不找到方法来继续他们的运作而不被打断。这导致协作工具的使用大幅增加，如视频会议软件和虚拟项目管理工具。这些工具使企业能够继续远程运作，确保他们的员工能够像在同一个办公室一样一起工作。</p><div class="par-spacer"></div>
        <p>另一个技术突显的领域是在线商务领域。随着人们对实体店的警惕，网上购物已变得比以往任何时候都更受欢迎。零售商不得不迅速适应这一变化，投资于他们的在线平台，增加他们的在线业务，以满足这一不断增长的需求。这导致了电子商务平台使用的大幅增加，现在已经成为许多企业战略的重要组成部分。</p><div class="par-spacer"></div>
        <p>最后，这一大流行病也见证了人工智能和机器学习技术使用的上升。这些技术已被用于帮助跟踪和预测病毒的传播，也被用于协助管理医院床位和医疗用品等资源。此外，它们还被用来创建聊天机器人，可以帮助回答问题并提供有关大流行病的信息，使医护人员能够专注于更关键的任务。</p><div class="par-spacer"></div>
        <p>总之，由于企业和个人不得不适应新的工作和沟通方式，COVID-19大流行病引起了技术应用的快速增长。这场大流行向我们展示了技术的重要性，它使我们能够在不传播病毒的情况下继续我们的日常活动，并为企业创造了使用技术来改善其运作的新机会。随着世界的发展，技术可能将继续在我们的生活和商业中发挥越来越重要的作用。</p><div class="par-spacer"></div>
        `
      },
      content5: {
        title: `Blockbuster的兴衰：不适应技术变化的代价`,
        desc: `
        <p>那是20世纪80年代，普通消费者有几种不同的方式可以观看电影，主要是在当地音像店租片。那时候，Blockbuster Video是视频租赁的领导者，由于对电影的需求很大，他们赚了很多钱。然而，世界在变化，技术在快速发展。流媒体服务的引入，如Netflix，意味着Blockbuster必须适应不断变化的市场。不幸的是，他们没能做到这一点，最终导致了他们的消亡。</p><div class="par-spacer"></div>
        <p>1989年，百视达开设了他们的第一家店，并在10年内从一个小的连锁店发展成为一个在25个国家拥有9000多家店的全球帝国。他们业务的大规模增长得益于其租赁模式的成功。消费者能够在当地商店租到电影，并在事后归还，而不需要支付费用。这种模式允许人们以可承受的价格获得更多的电影，它吸引了许多人。</p><div class="par-spacer"></div>
        <p>然而，尽管租赁模式一开始是有效的，但这并不足以让他们永远维持下去。随着像Netflix这样的流媒体服务的出现，消费者有了一种更容易的方式来观看电影，而不必离开他们的家并为之付费。Netflix还为其用户提供了无限数量的电影，他们可以随时观看。这导致百视达的客户大幅减少，最终导致其倒闭。</p><div class="par-spacer"></div>
        <p>Blockbuster的失败说明了公司适应不断变化的技术对它们的生存是多么重要。随着技术的进步和新趋势的出现，公司需要愿意做出改变，以便跟上这些趋势，否则就有可能失去潜在的客户。这是Blockbuster没有做到的，使他们失去了业务。</p><div class="par-spacer"></div>
        <p>总之，Blockbuster的消亡提醒我们，企业必须能够适应不断变化的技术，否则就有可能在一个高度竞争的市场中失败。它的衰落是一个例子，说明公司必须愿意接受变化，以使它们保持相关性和成功。它也是一个重要的教训，即如果不学习如何跟上技术进步，没有企业可以免于失败。</p><div class="par-spacer"></div>
        `
      }
    },

    solution: {
      header: {
        title: '解决方案',
        desc: '我们提供一系列专门制作的解决方案，旨在推动您的业务进步和增长。'
      },
      item1: {
        title: '网站开发',
        desc: `一个设计精美、易于使用的界面可以将网站的转化率提高多达200%。因此，让您的商业网站具有有吸引力的设计、完整的功能和易于导航非常重要。我们可以根据您的需求和喜好处理所有这些<br/><br/>从创建公司网站、电子商务网站、微站、着陆页到具有专业和功能丰富设计的自定义设计，我们都在这里。我们旨在确保您的网站不仅外观好看，而且功能完善，满足您业务的需求。`
      },
      item2: {
        title: '手机应用',
        desc: `大约70%的在线购物者由于访问应用程序时的不佳用户体验而放弃他们的购物车。不要让这种情况发生在你的数字业务上。开始与我们合作，因为我们不仅强调为您的业务应用程序用户提供易用性，而且还额外关注设计方面，使其在视觉上吸引人并令用户愉快。`
      },
      item3: {
        title: '电子游戏开发',
        desc: `发掘您业务的未开发潜力，使用吸引人的独特广告媒介——视频游戏！不必担心如何使其具有吸引力，因为我们将与您合作，将您的想法带入现实。从确定与您业务相一致的视频游戏到制作设计并执行生产——无论是电脑游戏，游戏机游戏还是移动设备，我们都可以提供帮助。今天就拥抱这种创新的广告方法的力量！`
      },
      item4: {
        title: '企业级资源规划系统',
        desc: `最大化您的企业节省，并使用合适的企业资源规划或ERP简化您的流程。通过准确的数据，您可以将您的企业花费节省46%，并提高90%的生产力。我们Odoo ERP团队随时为您服务，提供广泛的功能，使您的ERP实施成功。今天与我们预约咨询，找到适合您特定业务需求的最佳ERP解决方案。`
      }
    },

    project: {
      header: '项目',
      quote:
        '让我们带您进入KeDA Tech的旅程，深入了解我们所完成的令人惊奇的项目，包括把每个项目变成现实所花费的时间。',
      see: '看项目',
      load: '加载更多',
      time: '时间',
      months: '几个月',
      worker: '所需团队',
      tech: '使用的技术',
      timeline: '有效的规划时间线',
      development: '发展中的几周',
      'task-completed': '任务已完成',
      point1: '对问题的理解',
      point2: '理念的产生',
      point3: '解决方案',
      explore: '探索其他挑战现状和激发创新的突破性项目',
      detail: {
        'item-wayfinder': {
          name: 'Wayfinder 印度尼西亚',
          subtitle: '通过与制造系统的无缝集成，改变您的室内导航体验',
          desc: '这个系统使你的员工能够有效地管理货物的进入和离开。',
          'desc-point1': `如何在制造和仓库系统中整合技术，以简化其员工的工作量并提高其生产力？`,
          'desc-point2': `这个系统出现的目的是彻底改变仓库工人的效率，特别是关于房间里的货物布局。想象一下，在这个世界上，仓库中物品的摆放被优化到最大的潜力，导致一个更加精简和高效的系统，使每个人都受益。现在是时候重新思考我们在工作场所的存储和组织方式了。`,
          'sub-point3': `Wayfinder的出现彻底改变了仓库的运作方式，使其成为效率和效益的标志。`,
          'desc-point3': `由于输入的数据与房间的当前状态相结合，它创造了一个动态的、迷人的信息和环境的融合。`,
          quote: `"他们不断沟通以解决过程中的需求，是坚实的项目管理的关键。团队还赞扬他们通过主动性和完整性对任务成功的承诺。"`,
          'quote-by': `理查德-莫兰，IT项目协调员`
        },
        'item-axa': {
          name: 'AXA SMART DRIVER',
          subtitle: '利用虚拟现实技术，享受平稳无忧的驾驶体验',
          desc: '准备好享受互动式VR带来的非凡驾驶体验吧! 感受在虚拟城市中驾驶的快感，体验无与伦比的舒适和安全。',
          'desc-point1': `安盛向我们提出了一个挑战，让驾驶更安全。我们与安盛集团一起，在安菲尔德市为您带来非同寻常的模拟驾驶体验。通过互动和先进的VR技术，你可以安全、舒适地体验在真实世界中的驾驶。`,
          'desc-point2': `这种模拟的创建有一个主要的目标：为司机提供一个非凡的驾驶体验。通过高度先进的交互式VR技术，我们将以高度逼真的印象带给您一个安全舒适的旅程。`,
          'sub-point3': '',
          'desc-point3': `安盛智能驾驶为司机提供了无忧驾驶的机会。凭借其先进的技术支持，它以一种独特的方式帮助你维护安全。这项技术提供了安全驾驶的界限警告，因此你可以保持对道路的关注，并通过安全的旅程节省时间。因此，试试AXA Smart Driver，感受一下无畏的驾驶快感吧!`,
          quote: `"我们非常高兴使用科大讯飞提供的技术用于我们的驾驶模拟。我们的主要目标是为我们的司机提供非凡的驾驶体验，我们相信这种先进的交互式VR技术将使我们能够创造一个安全舒适的旅程，并具有高度真实的感觉。科大讯飞以专业的精神和专业的知识来创造这个模拟。我们都对他们的工作印象非常深刻。他们使这个项目变得安全、准确和可靠，确保所有司机获得非凡的驾驶体验。我们也非常感谢KeDa Tech在这个模拟制作过程中的支持。他们把客户的满意度放在首位，提供高质量的服务保障，使我们能够创造出高质量、高效率和准确的产品。我们强烈推荐KeDa Tech给那些希望制作基于VR互动技术的模拟的客户。我们真心相信，他们会一直通过提供最好的解决方案来帮助我们的客户实现他们的目标。感谢KeDa Tech提供的所有合作。"`,
          'quote-by': ``
        },
        'item-load-container': {
          name: 'Load Container',
          subtitle: '提高货物运营效率的软件',
          desc: '',
          'desc-point1': `如何最大限度地提高货物集装箱的数量？ (如果没有这个软件，货物在集装箱和货物中的安排将经历许多试验。因此，计算和估计1个货物中的集装箱数量需要更长的时间）。`,
          'desc-point2': `用集装箱装载机程序实现不费力的优化 - 告别手工计算的烦恼，迎接不费力的优化。我们的程序可以计算出集装箱内货物的数量和集装箱内货物的数量，甚至建议使用最佳的集装箱组合，所有这些都是为了最大限度地提高您的运营效率和利润率。`,
          'sub-point3': '',
          'desc-point3': `该软件为用户提供了一种简单的方法来实现集装箱的最佳装载。通过各种独特的功能，用户可以确定装载货物的最佳集装箱组合，并可以通过软件计算建议选择替代目的地。该软件承诺为货物装载和集装箱安排提供高效和可靠的解决方案，同时保持成本和效率意识。`,
          quote: ``,
          'quote-by': ``
        },
        'item-sqm-chatbot': {
          name: 'SQM CHATBOT',
          subtitle: '通过我们连接的在线聊天机器人，体验高速互动和交换信息的乐趣!',
          desc: '这种聊天机器人允许你回答问题，并立即增加与客户的互动。凭借其先进的技术，该聊天机器人可以涵盖各种类型的互动，而且是实时的互动。这种聊天机器人将使您能够为客户创造最佳的服务体验。',
          'desc-point1': `我们如何才能与客户保持长期的联系，而不必重复收集联系数据？`,
          'desc-point2': `聊天机器人的使命是为客户提供闪电般的解决方案，在他们能与人类代理联系上之前弥补差距，并提供没有任何时间限制的24小时支持。`,
          'sub-point3': '',
          'desc-point3': `通过SQM物业的聊天机器人SQM，客户可以获得有关SQM物业的快速而准确的答案。在任何时候，不受时间限制，客户可以轻松有效地找到关于SQM物业的问题的答案。`,
          quote: `"从一开始，我们就确信与KeDa Tech合作，将我们对聊天机器人的设想变为现实是正确的选择。你们提供的解决方案不仅快速准确，而且对我们的客户来说也很容易使用。我们的客户现在可以在任何时候快速得到关于SQM物业的问题的答案，没有任何时间限制。<br><br>该团队高度专业，及时回应了我们的任何问题或担忧。对细节的关注和对提供顶级产品的奉献精神在项目的每个方面都是显而易见的。<br><br>我们对这个项目的结果非常满意，并会向任何寻找软件开发伙伴的人强烈推荐科大讯飞。"`,
          'quote-by': ``
        },
        'item-verif-doctor': {
          name: '与Jasa Raharja一起打开专家健康服务的通道',
          subtitle:
            '用最先进的医生顾问管理应用程序来革新医疗保健。提高效率，加强沟通，简化工作流程，以获得最佳的病人结果！"。',
          desc: `在事故发生后，受害者或他们的亲人最不应该担心的是浏览复杂和混乱的医院计费程序。这就是为什么我们致力于使用最先进的技术，为Jasa Raharja保险的病人简化程序。我们的创新解决方案允许轻松计算和验证医院费用，让有需要的人放心。不再有无休止的通话时间或因信息缺失而感到沮丧。通过我们的精简方法，我们让每个人都能更容易获得医疗保健服务，减少压力。`,
          'desc-point1': `事故发生后的恢复之路可能是漫长而紧张的，尤其是在为PT Jasa Raharja保险核实数据时。传统的验证过程往往是缓慢而繁琐的，给医生顾问和事故受害者都带来了困难。但是，如果有一种方法可以加快治疗过程中的这一关键步骤呢？通过利用最先进的技术，我们可以快速和准确地验证PT Jasa Raharja保险的事故受害者的数据。不再有等待，不再有挫折感。通过我们简化的方法，我们正在帮助事故受害者和他们的家人在他们需要的时候获得他们需要的支持。加入我们，彻底改变我们在事故后处理数据验证的方式。`,
          'desc-point2': `到目前为止，保险公司PT Jasa Raharja在为保险范围内的事故受害者提供服务时，数据的核实过程不够高效。为了解决这个问题，我们的团队正在寻找解决方案来提高这个过程的效率。在做了一系列的维护后，我们决定创建一个应用程序来帮助提高PT Jasa Raharja的数据验证过程的效率。希望这个应用程序可以减少时间和成本，并使这个过程更容易、更有效，从而使顾问医生可以更快、更准确地为事故受害者服务。`,
          'sub-point3': '',
          'desc-point3': `我们介绍顾问医生验证应用程序，以帮助Jasa Raharja计划所涵盖的事故受害者的医院账单数据的验证过程。这个应用程序是一个解决方案，使验证过程对双方（顾问医生和事故受害者）都更快更容易。`,
          quote: `"凯达科技为我们在Jasa Raharja开发和推出解决方案提供了巨大的帮助。他们帮助我们开发了一个精简的流程，以改善Jasa Raharja所覆盖的病人的整体医疗体验。他们的专业团队，从项目经理到软件工程师，都非常专业，善于沟通，反应迅速，帮助我们创造了一个创新产品，缓解了事故后病人及其家属的支付过程。我们很自豪能够成为一个更大的计划的一部分，使所有人口都能负担得起医疗保健，并且没有压力。我们相信，与科大讯飞的合作将在未来对患者体验产生持续的积极影响。"`,
          'quote-by': ``
        },
        'item-health-clinic': {
          name: '',
          subtitle:
            '使用一个应用程序来促进诊所所有活动的管理，从存储病人数据到跟踪工作人员的活动，并持续监测和评估他们。',
          desc: `除了诊所管理外，医生还可以访问和利用该应用程序来监测他们病人的进展。这使医生能够保持最新的发展，并为他们的病人提供尽可能好的护理，确保他们在正确的轨道上迅速恢复。`,
          'desc-point1': `为了解决这个问题，我们必须首先确定有效管理诊所的要求。这包括进行初步调查，收集数据，与行业标准进行比较，并评估各种可用的解决方案。一旦我们了解了这个问题和能够帮助解决这个问题的适当应用，我们就可以提供一个综合的实时解决方案，以实现有效和高效的病人管理。`,
          'desc-point2': `从分析诊所管理所面临的问题开始，制定如何应用第一种技术以确保解决方案的有效性的想法，并测试各种技术，看哪种技术适合帮助诊所管理实现他们的目标，我们的团队一直在努力开发一个应用程序，可以帮助诊所管理实时和全面地管理和处理诊所和病人的数据。在应用程序的测试和质量保证过程结束后，我们会仔细检查项目目标是否得到很好的实现。`,
          'sub-point3': '',
          'desc-point3': `我们创建了这个应用程序，作为一个解决方案，为诊所经理和医生开发效率和生产力。有了这个应用程序，他们可以更容易和快速地管理病人的医疗保健数据和诊所活动。这是提高诊所和医生处理各种健康问题的生产力的关键一步。`,
          quote: `"我们非常感谢科大讯飞在创建应用程序方面所做的出色工作，它帮助我们更有效和更有成效地运行我们的健康诊所。该应用程序使我们能够轻松、快速地管理病人的健康数据和诊所活动。<br><br>有了这个应用程序，我们的诊所经理和医生现在可以以更高的效率更好地照顾他们的病人。这是提高我们诊所和医生处理不同健康问题的生产力的重要一步。<br><br>我们要代表我们所有从使用你们的应用程序中受益的员工感谢科大讯飞。到目前为止，我们对结果非常满意，并期待着在未来与你们进一步合作。"`,
          'quote-by': ``
        },
        'item-price-fetcher': {
          name: 'PRICE FETCHER',
          subtitle: '用软件来跟踪和比较不同电商的价格，这样你就可以得到最好的价值!',
          desc: `通过使用我们的应用程序，你可以比较你正在寻找的产品的价格，并获得最佳交易。`,
          'desc-point1': `为了比较价格而逐一访问电子商务应用程序可能会很累，而且不切实际。我们如何能够通过一次访问来比较各电子商务的价格？`,
          'desc-point2': `我们的灵感来自于消费者在比较电子商务产品价格方面的困惑。我们和这个软件的目标是促进和帮助在整个电子商务中选择和比较产品价格的过程更加实用和高效。`,
          'sub-point3': '',
          'desc-point3': `该软件为在各种电子商务平台上获取不同产品价格数据的问题提供了一个解决方案。有了这项技术，用户可以轻松获得他们正在寻找的物品的价格，而不必单独访问每个电子商务平台的应用程序。`,
          quote: ``,
          'quote-by': ``
        },
        'item-gemx': {
          name: 'G-EMx',
          subtitle: '',
          desc: `一个数字应用程序，随时随地管理您的业务需求。`,
          'desc-point1': `對於許多企業主，在一個地方訪問他們所有的商業活動可能是一個真正的挑戰。傳統的方法必須協調不同部門，以便訪問公司的財務數據，這可能是耗時且不高效的。這使企業主感到沮喪，無法根據最新的信息快速做出決策。`,
          'desc-point2': `以高度安全性为重点，我们向G-EMx提供一种解决方案，使商业所有者能随时随地访问其财务数据。`,
          'sub-point3': '',
          'desc-point3': `商业所有者现在可以完全控制和监督他们所有的业务活动，访问定制的财务需求，并实时看到财务交易，所有这一切都在他们的指尖。我们了解商业所有者在管理财务方面面临的挑战，这就是为什么我们开发了一个解决方案，解决这些挑战，并为他们提供所需的方便和便利。`,
          quote: `"我们很高兴与KeDa Tech合作，创建一个管理我们业务需求的数字应用程序。他们真正理解现代企业的需求，提供了一个超出我们预期的解决方案。凭借KeDa Tech在软件开发方面的专业知识，我们能够简化我们的运营，并随时随地对我们的业务活动进行全面控制和监督。该应用程序易于使用，可定制，并提供实时的财务交易可见性。我们强烈推荐KeDa Tech给任何寻找可靠，创新解决方案的企业。他们是该领域的专家，真正实现了结果！"`,
          'quote-by': `Asmadi Ahmad - CEO G-EMx Technologies`
        }
      }
    },

    consult: {
      form: {
        title: '现在就咨询我们',
        desc: '进入数字商业和技术领域一定会产生很多问题。您可以从填写以下表格开始，我们将免费提供答案。',
        input1: '名字',
        input2: '你的商业领域',
        input3: '电话号码',
        input4: '电子邮件',
        input5: '你的问题和需求',
        office: {
          name: '雅加达办公室',
          address:
            '绿城综合体，Ratu Kemuning街道，Block BL编号1，RT08 / RW14，雅加达，+(62-81) 180-878-87'
        },
        send: '发送 '
      },
      desc: {
        title: '你能得到什么？',
        desc: '下面让我们看看具体的流程步骤'
      },
      point1: {
        title: '1. 问题',
        desc: `让我们通过深入的对话来更好地了解您的业务运作，全面了解您独特的需求和当前的技术设置。我们将一起识别核心问题，并提供量身定制的解决方案，以实现成功。请放心，我们将不断衡量和优化我们的方法，以确保卓越的结果。`
      },
      point2: {
        title: '2. 解决方案',
        desc: `在科大讯飞，我们知道，提供真正能带来变化的解决方案是成功的关键。这就是为什么在我们开始制定解决方案之前，我们的专家团队会对你的独特情况进行彻底的研究和分析。只有这样，我们才会开始工作，定制一套专门用于应对你的挑战和实现你的目标的解决方案<br/><br/>我们相信，提供量身定制的解决方案，满足您的具体需求，是实现真正结果和产生持久影响的最佳途径。因此，如果你能拥有专为你设计的解决方案，为什么要满足于一刀切的做法呢？在科大讯飞，这正是你将得到的东西。`
      },
      point3: {
        title: '3. 接下来的步骤',
        desc: `在科大讯飞，我们了解与客户建立牢固和持久关系的重要性。这就是为什么，即使在项目完成后，我们对卓越的承诺也不会就此结束。我们的后续程序旨在确保完全满意，解决任何问题，并为您的系统的寿命提供持续支持。通过定期检查，及时解决问题，以及专门的支持团队，你可以放心，你的成功是我们的首要任务。加入我们满意客户的行列，让我们在项目完成后继续推动你的成功。`
      },
      review: '他们说什么'
    },

    story: {
      header: {
        title: '我们自我介绍',
        desc: '从成为国际标准软件公司的梦想开始，KeDA Tech于2018年成立。在其发展过程中，KeDA Tech相信数字化转型将在生活的各个方面带来许多变革，特别是在公司、社区和社会的商业领域。 <br><br>加入KeDA Tech的数字化转型革命！从成为国际认可的软件公司的梦想中，我们已经到达并准备塑造未来。我们相信数字时代将带来从商业到社区和整个社会的巨大变革和进步。与KeDA Tech一起准备数字革命吧！'
      },
      'our-journey': {
        title: '我们的旅程',
        now: '现在',
        item1:
          '从由两个程序员和一个设计师组成的团队开始，KeDA Tech 坚持并完成了第一个项目：集装箱装卸机项目。',
        item2:
          '一年后，KeDA Tech成立，与AXA开始了新的合作。在这个项目中，我们创建了一个VR模拟器，由三名程序员和一名设计师在短短两周内成功完成。',
        item3: 'KeDA Tech与雅加达最大的房地产公司之一合作，完成了SQM Property的聊天机器人应用。',
        item4:
          '我们已经与Shinta VR、Wallex、Luceria和PT Citra Kreasi Makmur等其他软件开发商完成了多项项目，我们也想成为您数字业务进展的一部分。KeDA Tech目前有超过10名程序员，始终致力于为您提供最佳服务。'
      },
      'our-story': {
        title: '我们的故事',
        desc: `2017年在美国加州完成硕士学位后，肯尼斯·努萨林回到了印度尼西亚。在美国生活了几年，他发现印度尼西亚的信息技术发展落后了。 <br><br>为了改变这种情况，他在2018年创立了KeDA Tech，希望它能成为国际知名的软件公司。来加入我们，一起推动科技产业的发展，让印度尼西亚走在数字世界的最前沿。`
      },
      'to-team': {
        desc: '真心为您的公司做到最好。',
        'meet-team': '让我们来见见团队吧'
      }
    },

    technology: {
      'short-desc': {
        title: '技术',
        desc: '为了您的技术进步，我们利用以下所有功能来提高您的体验。'
      },
      'unreal-engine': {
        title: '虚幻引擎',
        desc: '这个游戏引擎生成了令人难以置信的稳定帧率，令人叹为观止的图形，令人沉浸的景深，并支持最新的DX 11技术。准备好体验前所未有的非凡游戏旅程。',
        link: '了解更多关于虚幻引擎',
        'long-desc': `这个游戏引擎应用程序是由Epic Games创建的，并在1998年首次亮相。从一款第一人称射击游戏开始，现在虚幻引擎已经具备将周围的物体变成实时3D可视化的能力。 <br><br>虚幻引擎的不断改进使3D形状更接近现实，具有更逼真的表现。随着每次迭代，引擎变得更具吸引力和沉浸感，带你进入一个超出你想象的世界。`,
        ask1: '这项技术如何应用于开拓创造力和创新的新窗口？',
        explanation1: `虚幻引擎为您的项目打开了一个具有无限可能性的世界。它不仅仅适用于游戏，您可以将虚幻引擎应用于电影、建筑、汽车和交通、广播事件，甚至是各种模拟。释放您的想象力，用虚幻引擎将您的创作变为现实。`,
        ask2: '有哪些优势?',
        explanation2: `通过利用虚幻引擎，你可以得到一个完美的视觉表现，真正捕捉到现实。有了这个强大的工具，你可以充分体验模拟，并以最大的准确性进行预测。想象一下，能够潜入一个虚拟世界，感觉就像你生活的世界一样真实。这就是虚幻引擎的力量`,
        project: {
          use: '我们使用UNREAL发动机的项目',
          title: '移动应用',
          name: 'AXA',
          desc: `一个互动的VR体验，带你在安菲尔德市繁华的街道上进行惊险的虚拟驾驶。当你在拥挤的十字路口、狭窄的小巷和熙熙攘攘的大道上操纵你的方式时，让自己沉浸在城市生活的喧嚣和骚动中。有了尖端的VR头盔和直观的控制，你会觉得自己真的是在驾驶，实时导航城市，发现安菲尔德所能提供的一切。准备好在世界最激动人心的城市之一进行一次令人心潮澎湃、难以忘怀的旅程吧!`
        }
      },
      'my-sql': {
        title: 'My SQL',
        desc: 'MySQL 以高度的科技灵活性自豪，是一种跨平台数据库。它的用户友好的表结构在实际操作中提供出色的性能。',
        link: '了解更多关于MySQL',
        'long-desc': `最初，MySQL是UNIREG项目的一个高级开发项目，与网站上使用的动态数据库兼容性不高。随着它的发展，MySQL成为一个数据库管理系统，使用基本的SQL命令，即结构化查询语言，这是相当知名的。`,
        ask1: '如何应用这项技术来打开创意和创新的新窗口？',
        explanation1: `MySQL属于关系型数据库管理系统，在这个数据库应用中使用了行、列和表等术语。<br><br>潜入MySQL的数据库管理世界，熟悉的行、列和表等术语在关系型数据库的领域中被赋予了生命。`,
        ask2: '有哪些优势?',
        explanation2: `通过实施MySQL，你不需要一个巨大的RAM。此外，MySQL还支持与其他编程语言的整合，使其成为一个多功能、高效率的解决方案。`,
        project: {
          use: '我们使用My SQL的项目',
          title: '网站开发',
          name: 'WayFinder',
          desc: `将室内导航系统与仓库管理系统集成，提高了工人的效率。`
        }
      },
      'django-python': {
        title: 'Django Phyton',
        desc: `Django 的诞生发生在 2003 年，但直到 2008 年，它的第一个版本才最终被释放到世界上。这个功能强大的全栈框架，用 Python 编写，为开发人员提供了一种无缝的方法，使他们的 web 应用梦想成真。`,
        link: '了解更多关于Python',
        'long-desc': `Django创建于2003年，但直到2008年发布第一个版本时才向公众发布。 这个全栈框架旨在帮助开发人员使用Python编程语言开发网络应用程序。现在，在2019年，Django的第二个版本终于发布了，为开发人员提供了更多的工具来实现他们对网络应用的愿景。 并提供了更多的工具来帮助开发者实现他们对网络应用的设想。`,
        ask1: '这项技术如何应用于开拓创造力和创新的新窗口？',
        explanation1: `Django是一个高水平的Python网络框架，允许快速开发应用程序，设计简洁实用。它的效率和精简的方法使它成为网络开发的突出选择。`,
        ask2: '有哪些优势?',
        explanation2: `Django非常适合任何规模的项目，从小型到大型。与其他框架相比，它拥有一系列令人印象深刻的功能，使它能够轻松地处理构建一个应用程序的所有基本要素。`,
        project: {
          use: '我们使用Django Phyton的项目',
          title: '电子游戏开发',
          name: '集装箱装卸工',
          desc: `Innovative 模拟软件，用于优化计算存储在集装箱或货柜中的货物，以最大化空间和最小化浪费。`
        }
      },
      unity: {
        title: 'Unity',
        desc: `Unity由Unity Technologies开发，于2005年首次推出，声称是创建和操作实时3D体验的先锋平台。不仅仅限于3D，Unity还提供2D、VR、AR跨平台项目。`,
        link: '了解更多关于Unity',
        'long-desc': `Unity由Unity Technologies开发，于2005年首次推出，声称是创建和操作实时3D体验的先锋平台。不仅仅限于3D，Unity还提供2D、VR、AR跨平台项目。凭借其先进的技术，Unity已经彻底改变了游戏和互动内容的世界，使其比以往任何时候都更容易带来令人惊叹的沉浸式体验。`,
        ask1: '这项技术如何应用于开拓创造力和创新的新窗口？',
        explanation1: `Unity可以应用于各种游戏、动画、汽车设计、建筑等方面的创作，为想象力的实现开启了无限的可能性。`,
        ask2: '有哪些优势?',
        explanation2: `Unity的优势之一是其用户友好的系统，易于学习代码，但能够制作具有广泛功能的游戏。这使开发人员能够更有效地工作，并轻松地将他们的创意愿景变为现实。无论你是初学者还是经验丰富的游戏开发者，Unity都能提供工具和资源，将你的想法变为现实。凭借其直观的界面和强大的功能，Unity是创造令人惊叹的游戏的终极工具。`,
        project: {
          use: '我们使用Unity的项目',
          title: '',
          name: '',
          desc: ``
        }
      },
      'keda-engine': {
        title: 'KeDa Engine',
        desc: `发现KeDa引擎的力量 - 一个出生于决心和速度需求的程序。最初使用C＃作为编程语言的KeDa团队遇到了迭代缓慢的挑战。`,
        link: '了解更多关于KeDa Engine',
        'long-desc': `发现KeDa引擎的力量 - 一个出生于决心和速度需求的程序。最初使用C＃作为编程语言的KeDa团队遇到了迭代缓慢的挑战。但是凭借坚定不移的决心和创新的推动力，KeDa引擎现在拥有更高效和强大的C++语言。`,
        ask1: '这项技术如何应用于开拓创造力和创新的新窗口？',
        explanation1: `KeDa引擎是由KeDa公司在2017年开发的专业技术，旨在彻底改变编程世界。最初使用C#语言编写，但KeDa团队遇到了迭代速度缓慢的挑战。但是凭借不懈的决心和对创新的渴望，KeDa引擎现在已经采用了更高效和强大的C++语言。这项技术是KeDa对为客户提供最佳解决方案承诺的证明，也是坚持不懈和对创新的渴望如何导致创造游戏改变技术的例子。`,
        ask2: '有哪些优势?',
        explanation2: `使用 KedaEngine 的优势在于它的定制系统，利用 C++ 和比其他引擎快得多的闪电般的运行时。`,
        project: {
          use: '我们使用KeDa引擎的项目',
          item1: {
            title: '网站开发',
            name: 'Wayfinder',
            desc: `将室内导航系统与仓库管理系统集成，提高了工人的效率。`
          },
          item2: {
            title: '网站开发',
            name: 'PRICE FETCHER',
            desc: `软件来跟踪和比较不同电子商务的价格，以便您可以获得最佳价值！`
          },
          item3: {
            title: '网站开发',
            name: 'Load Container',
            desc: `一款能够计算货柜内物品最佳摆放方式的模拟软件。`
          }
        }
      },
      back: '回到技术'
    }
  }
};
