<template>
  <div
    :class="classes"
    class="pt-2"
  >
    <base-avatar
      v-if="icon"
      :color="color"
      :icon-color="textColor"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      :squared="squared"
    />

    <base-avatar
      v-else
      :color="color"
      :icon-color="textColor"
      :dark="dark"
      :outlined="outlined"
      :size="size"
      :squared="squared"
    >
      <base-img
        v-if="src"
        :src="require(`@/assets/icons/${src}`)"
        :width="size / 2"
        :height="size / 2"
        contain
      />
    </base-avatar>

    <div :class="[horizontal, title, 'ml-3', 'd-flex']">
      <base-title
        :title="title"
        class="text-uppercase"
        space="3"
      />

      <base-body
        v-if="text || $slots.default"
        :space="'auto'"
        :text="text"
        :text-color="textColor"
        class="ma-auto"
        max-width="700"
      >
        <slot />
      </base-body>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseAvatarCard',

    props: {
      align: {
        type: String,
        default: 'left',
      },
      color: String,
      dark: Boolean,
      horizontal: Boolean,
      icon: String,
      src: String,
      outlined: {
        type: Boolean,
        default: true,
      },
      space: {
        type: [Number, String],
        default: 8,
      },
      size: {
        type: [Number, String],
        default: 72,
      },
      squared: Boolean,
      text: String,
      textColor: String,
      title: String,
    },

    computed: {
      classes () {
        const classes = [
          `mb-${this.space}`,
        ]

        if (this.horizontal) {
          classes.push('d-flex')

          if (!this.$slots.default && !this.text) {
            classes.push('align-center')
          }
        }

        return classes
      },
    },
  }
</script>
