<template>
  <v-text-field
    v-if="email"
    :rules="emailRules"
    :label="label"
    :outlined="outlined"
    :dense="outlined"
    :solo="!outlined"
    single-line
    v-bind="$attrs"
    v-on="$listeners"
  />
  <v-text-field
    v-else-if="required"
    :rules="requiredRules"
    :label="label"
    :outlined="outlined"
    :dense="outlined"
    :solo="!outlined"
    single-line
    v-bind="$attrs"
    v-on="$listeners"
  />
  <v-text-field
    v-else
    :label="label"
    :outlined="outlined"
    :dense="outlined"
    :solo="!outlined"
    single-line
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'BaseTextField',

  props: {
    email: Boolean,
    outlined: Boolean,
    label: String,
    name: String,
    required: Boolean
  },

  data: function () {
    return {
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      requiredRules: [(v) => !!v || `${this.name || this.label} is required`]
    };
  }
};
</script>

<style lang="scss">
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #9e9e9e !important;
}
</style>
