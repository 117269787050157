// Imports
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/index.vue'),
      children: [
        {
          path: '',
          name: 'KDAHome',
          component: () => import('@/views/KDAHome/index.vue'),
          meta: { hexBookColor: '#DAFFE5' }
        },
        {
          path: 'technology',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDATechnology',
              component: () => import('@/views/KDATechnology/index.vue'),
              meta: { hexBookColor: '#F5E3FF' }
            },
            {
              path: 'detail/:strTechnology',
              name: 'KDATechnologyDetail',
              component: () => import('@/views/KDATechnologyDetail/index.vue'),
              meta: { hexBookColor: '#EBFFE3' }
            }
          ]
        },
        {
          path: 'project',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDAProject',
              component: () => import('@/views/KDAProject/index.vue'),
              meta: { hexBookColor: '#FFCCFA' }
            },
            {
              path: 'detail/:strProject',
              name: 'KDAProjectDetail',
              component: () => import('@/views/KDAProjectDetail/index.vue'),
              meta: { hexBookColor: '#B6E5FF' }
            }
          ]
        },
        {
          path: 'story',
          name: 'KDAStory',
          component: () => import('@/views/KDAStory/index.vue'),
          meta: { src: require('@/assets/bg/bg-header-home.png'), hexBookColor: '#E2E7FF' }
        },
        {
          path: 'solutions',
          name: 'KDASolutions',
          component: () => import('@/views/KDASolutions/index.vue'),
          meta: { src: require('@/assets/bg/bg-header-home.png'), hexBookColor: '#D6B6FF' }
        },
        {
          path: 'portfolio',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDAPortfolio',
              component: () => import('@/views/KDAPortfolio/index.vue'),
              meta: { src: require('@/assets/bg/bg-header-portfolio.jpg') }
            },
            {
              path: 'demo/:strProjectCode',
              name: 'KDAPortfolioDemo',
              component: () => import('@/views/KDAPortfolioDemo/index.vue'),
              meta: { src: require('@/assets/bg/bg-header-portfolio.jpg') }
            }
          ]
        },
        {
          path: 'teams',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDATeams',
              component: () => import('@/views/KDATeams/index.vue'),
              meta: { hexBookColor: '#FFE3E8' }
            }
          ]
        },
        {
          path: 'process',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDAProcess',
              component: () => import('@/views/KDAProcess/index.vue'),
              meta: { hexBookColor: '#FFE3E8' }
            }
          ]
        },
        {
          path: 'blogs',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDABlog',
              component: () => import('@/views/KDABlog/index.vue'),
              meta: { hexBookColor: '#DAFFE5' }
            },
            {
              path: 'info/:strHashedID',
              name: 'KDABlogDetails',
              component: () => import('@/views/KDABlogDetails/index.vue'),
              meta: { hexBookColor: '#FFD0B6' }
            }
          ]
        },
        {
          path: 'careers',
          component: () => import('@/layouts/KDASubView.vue'),
          children: [
            {
              path: '',
              name: 'KDACareers',
              component: () => import('@/views/KDACareers/index.vue'),
              meta: { hexBookColor: '#B6FFF6' }
            },
            {
              path: 'apply/:strHashedID',
              name: 'KDACareerApplication',
              component: () => import('@/views/KDACareerApplication/index.vue'),
              meta: { hexBookColor: '#B6FFC6' }
            }
          ]
        },
        {
          path: 'contact-us',
          name: 'KDAContactUs',
          component: () => import('@/views/KDAContactUs/index.vue'),
          meta: { src: require('@/assets/bg/bg-header-contact-us.jpg') }
        },
        {
          path: 'consultations',
          name: 'KDAConsultations',
          component: () => import('@/views/KDAConsultations/index.vue'),
          meta: {
            src: require('@/assets/bg/bg-header-home.png'),
            hexBookColor: '#D6FFB6',
            navToTop: true
          }
        },
        {
          path: '*',
          name: 'KDA404',
          component: () => import('@/views/KDA404/index.vue'),
          meta: { hexBookColor: '#DAFFE5' }
        }
      ]
    }
  ]
});

export default router;
