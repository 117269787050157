var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.required)?_c('v-textarea',_vm._g(_vm._b({attrs:{"rules":_vm.requiredRules,"label":_vm.label,"outlined":_vm.outlined,"dense":_vm.outlined,"solo":!_vm.outlined,"single-line":""}},'v-textarea',{
    rows: 6,
    ..._vm.$attrs
  },false),_vm.$listeners)):_c('v-textarea',_vm._g(_vm._b({attrs:{"label":_vm.label,"outlined":_vm.outlined,"dense":_vm.outlined,"solo":!_vm.outlined,"single-line":""}},'v-textarea',{
    rows: _vm.rows,
    ..._vm.$attrs
  },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }