const eContentType = {
  DISPLAY: 'display',
  TITLE_1: 'title-1',
  TITLE_2: 'title-2',
  TITLE_3: 'title-3',
  TITLE_4: 'title-4',
  SUBTITLE_1: 'subtitle-1',
  SUBTITLE_2: 'subtitle-2',
  SUBTITLE_3: 'subtitle-3',
  BODY_1: 'body-1'
};

export default {
  name: 'TextClass',

  data: () => ({
    eContentType
  }),

  methods: {
    /**
     * Adding getTextClasses() on methods.
     * Specifies the CSS classes depending on the content type.
     * Parameter 1 = Content type,
     * Parameter 2 = Additional classes,
     **/
    getTextClasses: function (eType, ...arrClasses) {
      switch (eType) {
        case eContentType.DISPLAY: {
          return ['text-h4', 'text-sm-h2', 'text-md-h1', 'font-weight-bold', ...arrClasses];
        }
        case eContentType.TITLE_1: {
          return ['text-h4', 'text-md-h3', 'font-weight-bold', ...arrClasses];
        }
        case eContentType.TITLE_2: {
          return ['text-h5', 'text-md-h3', ...arrClasses];
        }
        case eContentType.TITLE_3: {
          return ['text-h6', 'text-md-h5', 'font-weight-bold', ...arrClasses];
        }
        case eContentType.TITLE_4: {
          return ['text-h6', 'text-md-h5', ...arrClasses];
        }
        case eContentType.SUBTITLE_1: {
          return ['text-body-1', 'font-weight-bold', ...arrClasses];
        }
        case eContentType.SUBTITLE_2: {
          return ['text-subtitle-1', ...arrClasses];
        }
        case eContentType.SUBTITLE_3: {
          return ['text-subtitle-2', 'text-md-h6', 'font-weight-light', ...arrClasses];
        }
        case eContentType.BODY_1: {
          return [
            'text-subtitle-1 font-weight-light',
            'text-md-h6 font-weight-light',
            ...arrClasses
          ];
        }
        default:
          return ['text-body-2', ...arrClasses];
      }
    }
  }
};
