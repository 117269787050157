import { ja } from 'vuetify/lib/locale';

export default {
  ...ja,

  kda: {
    common: {
      'book-banner': {
        btn: 'お問い合わせください',
        desc: 'あなたのデジタル変革パートナー'
      },
      'ask-interest': {
        title: '私たちと連携することに興味はありますか？',
        desc: '私たちは常に新しい才能を受け入れることを準備しています',
        btn: 'あなたに適したポジションを見つけてください'
      },
      menu: {
        home: 'ホーム',
        about: '約',
        process: 'プロセス',
        services: 'サービス',
        projects: 'プロジェクト',
        blog: 'ブログ',
        careers: 'キャリア',
        consult: 'コンサルト',
        story: 'ストーリー',
        team: 'チーム',
        tech: 'テクノロジー',
        solutions: 'ソリューション'
      },
      footer: {
        address: `グリーン・ビル・コンプレックス、ラトゥ・ <br>ケムニング・ストリート、ブロックBL番号1、<br>RT08/RW14、ジャカルタ、<br>+(62-81) 180-878-87`,
        'address-mobile': `グリーン・ビル・コンプレックス、ラトゥ・ ケムニング・ストリート、ブロックBL番号1、RT08/RW14、ジャカルタ、<br>+(62-81) 180-878-87`,
        'copy-right': `Copyright &copy; 2022 KeDA Tech. すべての権利が保留されています。`,
        'quick-links': 'クイックアクセス',
        'find-us': '私たちを見つける',
        facebook: 'フェイスブック',
        instagram: 'インスタグラムというソーシャルメディア',
        linkedin: 'リンクトイン'
      },
      subscribe: {
        header: '私たちからの最新かつ最高の情報にアクセスしてください！ ',
        content:
          '私たちの方法、鼓舞する事例研究などの更新を受信するためにサインアップしてください。これは間違いなくあなたのビジネスの成長に役立つでしょう。',
        subscribe: '今すぐ購読する'
      }
    },

    home: {
      quote: {
        before: 'アメリカの熟練したプログラマの集団から始めて、',
        after:
          'はあなたのビジネスを革新し、デジタル時代のビジネスチャレンジに対応するために必要な正しいテクノロジーをご提供することにコミットしています'
      },
      process: {
        title: '私たちが行う手順',
        desc: '私たちはあなたのビジネスニーズを聞き理解しています。',
        btn: 'ディテール'
      },
      solutions: {
        title: '我々が提供するソリューション',
        item1: {
          title: 'ウェブサイト開発',
          desc: '良好で適切なユーザーインターフェースはウェブサイトのコンバージョンレートを200％増加させることができます。したがって、あなたのビジネスウェブサイトを魅力的なデザイン、完備した機能、そして簡単にセットアップさせることは必須です。'
        },
        item2: {
          title: 'モバイルアプリケーション',
          desc: 'アプリにアクセスする際のユーザー体验が悪いことが、オンラインショップのカートを離れる人の70％の原因です。あなたのデジタルビジネスにこのようなことが起きないようにしましょう。'
        },
        item3: {
          title: 'コンピューターゲームの開発',
          desc: 'ゲームで広告のポテンシャルを開放しましょう！退屈で従来的な方法を振り切り、ユニークで引き付ける戦略を受け入れましょう。私たちと一緒に、ビジネスを次の段階に上げることができます。それは、私たちが一緒に作業することによって、あなたのビジョンを現実にするために、一歩一歩手伝っているからです。'
        },
        item4: {
          title: '企業リソース計画システム',
          desc: 'ERPデータの力でビジネスを革新しましょう！正確な情報を活用することで、費用を大幅に節約し、ビジネスのプロセスの効率性を90％向上させます。ERPデータでビジネスを次のレベルに引き上げる時が来ました。'
        }
      },
      projects: {
        title: '私たちの予定',
        item1: {
          type: 'ウェブサイト開発',
          desc: '倉庫管理システムにインドアナビゲーションシステムを統合することで、作業者の効率が向上しました。'
        },
        item2: {
          type: 'モバイルアプリケーション',
          desc: 'アンフィールド市でドライブする感覚を与えるインタラクティブVR体験.'
        },
        item3: {
          type: 'コンピューターゲームの開発',
          desc: '貨物やコンテナーに配置されたアイテムの最適な配置を算出することができる先進的なシミュレーションソフトウェア。'
        },
        more: '新たなプロジェクトを発見しよう'
      }
    },

    teams: {
      header: {
        title: 'KeDA Techチームのプロフィール',
        desc: 'ジャカルタを拠点とするトップクラスの情報技術開発企業の1つとして、KeDA Techは最先端技術によってすべてのITシステムとソリューションのニーズを満たすことにコミットしています。'
      },
      members: {
        group1: 'ウェブサイトとアプリケーションの作成',
        group2: 'コンテンツ & ソーシャルメディア',
        group3: 'ビジネス & 開発',
        group4: 'プロダクトデザイン'
      },
      structure: {
        desc1: `
        <p>南カリフォルニア大学でコンピュータサイエンスの修士課程を修了し、そこで数年間プログラマーとして働いた後、</p><br/>
        <p>Kenneth Nursalimは、インドネシアの技術が以前よりもさらに進歩する可能性があることを理解しています。現在、彼は自分が設立したソフトウェアハウスであるKeda Techの開発と進歩に注力しています。</p><br/>
        <p>彼のエンジニアリングとゲームプログラミングの経験、そしてC++、Java、C#、Unreal Engine 4、Unityのスキルを持つことで、彼はKeda Techがインドネシアの主要な技術先駆者になることができると信じています。</p>`,
        desc2: `
        <p>Darrenは工学に焦点を当てて学業をスタートさせ、その後ビジネスとマネジメントの高度な研究を追求し、最終的にカリフォルニア州立大学を卒業しました。</p><br/>
        <p>アメリカ合衆国で長期間働いた後、彼はアメリカとインドネシアのビジネス間に大きなギャップがあることに気付きました。このギャップに動機づけられて、</p><br/>
        <p>彼はKeda Techと共に協力してビジネス戦略を革命的に変革する旅を始めました。</p>`,
        desc3: `
        <p>ブラウィジャヤ大学でコンピュータサイエンスの学位を取得した後、Renoは共通のビジョンを持っていたため、Keda Techに参加しました。</p><br/>
        <p>ソフトウェアエンジニアとしてキャリアをスタートし、シニアおよびリードデベロッパーに進んだ後、現在は最高技術責任者（CTO）を務めています。</p><br/>
        <p>Java、JavaScript、Pythonなどのさまざまなプログラミング言語や、Unity3D、Android、REST APIなどのプラットフォームに関する専門知識を持つRenoは、Keda Techに技術的な進歩をもたらす自信があります。</p>`
      }
    },

    careers: {
      header: {
        title: 'キャリアについて',
        desc: '私たちはインドネシアの各地に散らばる、プログラマー、デザイナー、プロジェクトマネージャーからなるグループです。KeDA Techでは、リモートワークの力を信じており、お客様のビジネスに適切な技術ソリューションを提供することに心を尽くしています。'
      },
      second:
        '若いチームメンバーの活力によって支えられています、KeDA Techは、想像力豊かなアイデアと画期的な革新のホットベッドです。企業が共同の成功に向かって前進するために駆動します。',
      lives: {
        life1: {
          title: 'どこからでも仕事する',
          desc: '生産性を解き放ち、創造力を引き出し、どこからでも作業してチームとの調和を保ちましょう。'
        },
        life2: {
          title: '私たちと一緒に最新技術を探検しましょう',
          desc: 'KeDA Techコミュニティに参加して、テクノロジー学習の旅を思い切り楽しもう！メンバーとして、エキサイティングなプロジェクトにアクセスすることができます。これらはあなたのキャリアアップを加速することになります。'
        },
        life3: {
          title: '情熱と目的に駆動されたダイナミックなドリームチーム！',
          desc: 'ダイナミックなチームと共に、同じ場所に固まることはありません。代わりにKeDA Techチームと共有することで最新の知識と経験を得ることができます。'
        },
        life4: {
          title: '最先端技術 - 境界を押し広げて可能性を開放！',
          desc: '最新の技術でゲームのレベルを上げましょう - KeDA Techに参加して、先端解決策で革新を行いましょう！'
        },
        life5: {
          title: 'フレックス勤務時間 - 仕事と生活のバランスを簡単にとりましょう！',
          desc: 'KeDA Techで簡単でフレックスな働き方 - 自分のスケジュールを設定しながら、チームとの接続を保つ。KeDA Techの健康保险カバレッジが追加されることで、人生を十分に楽しむことができます。'
        },
        bottom: 'では、採用のプロセスはどのようなものですか？'
      },
      looks: {
        title: 'こんな感じになるかもしれません',
        look1: {
          title: '仕事の探し方',
          desc: 'あなたが私たちの仕事の空きを発見し、それがあなたがキャリアの機会や仕事の責任について探しているものと一致する場合。'
        },
        look2: {
          title: '履歴書の提出',
          desc: '私たちにあなたのCVとレジュメを送ってください。ポートフォリオがある場合は忘れずに含めてください。これにより、あなたのスキル、経験、および作品サンプルをよりよく理解することができます。'
        },
        look3: {
          title: '人事コミュニケーション',
          desc: 'あなたの履歴書を徹底的にレビューした後、KeDA Techの人事部はすぐにあなたと連絡を取り、面接のスケジュールを調整します。'
        },
        look4: {
          title: 'インタビュー',
          desc: '提出された履歴書に基づいて、人事部はあなたがKeDA Techの空席のポジションを埋める能力があるかどうかを一致させ、評価し、必要に応じて面接を実施します。'
        },
        look5: {
          title: 'あなたは雇われました',
          desc: 'あなたの能力と私たちの要件が一致する場合、KeDA Techチームに参加することを私たちは喜んで歓迎します！一緒に、素晴らしい成果を達成し、重要な影響を与えることができます。'
        }
      },
      opening: { title: '開いている役割', apply: '申请', share: '分かち合う' },
      details: {
        input1: '名前',
        input2: '電話番号',
        input3: '電子メール',
        before: '期限までに申し込む',
        cv: 'ここに履歴書をアップロードしてください',
        select: 'ファイルを選択する',
        drag: 'ファイルを選択するか、ここにドラッグしてください',
        join: '今すぐ参加する'
      }
    },

    process: {
      next: '次のページ',
      book: '私たちとの議論',
      item1: {
        header: 'KeDa の仕事スタイル',
        title: '概要',
        desc: `産業界では、課題が避けがたいものですが、心配いりません。私たちはあなたの事業を成長させるためにここにいます。<br/><br/>KeDA Techでは、ビジネスの問題に手を打ち、問題を分析して、適切な技術を使ったカスタマイズされたソリューションを提供します。課題に対処して、ビジネスの活動で新たな高みに到達するのを助けましょう。`
      },
      item2: {
        title: '指導',
        desc: `私たちの協力をスタートさせるには、ガイドの作成が必須です。このガイドを作成するには、あなたがビジネスで望むものを完全に理解する必要があります。あなたのビジネスプロフィールから望ましいアウトカムまで、直接私たちに全てを教えてください。明確なコミュニケーションによって、私たちのチームとあなたの理解が確立され、持続可能な製品が生まれます。`
      },
      item3: {
        title: 'スコープ',
        desc: `プロジェクトの範囲はその結果に影響を与えることができる52%までです。この範囲は、デジタルビジネス製品に求めているものを正確に理解することができます。私たちは、あなたが面临する可能性のあるすべてのビジネス課題に深く掘り込み、完璧なソリューションを見つけます。この範囲では、デジタル製品ができることとできないこと、またはこれをどのようにさらに開発する予定かが分かります。`
      },
      item4: {
        title: '推定',
        desc: `この見積り技術は、ビジネス製品の開発の中の各要素を正確に予測するのに役立ちます。私たちのプロジェクト見積りは、設定されたスコープと発見の許容、配信、割り当て因子などのいくつかの他の要因に基づいています。この3つの要因は、チームによって実施されるタスクの時間とリスクも決定することができます。`
      },
      item5: {
        title: '発展',
        desc: `あなたのプロジェクト計画の成功完了により、デジタルビジネス製品の開発が今すぐ始められます。私たちは、デジタル製品が最適に機能することを確実にするために、スクラムメソッドを利用しています。このメソッドは、あなたの仕様を満たす高品質のソフトウェアを生産することで高く評価されています。さらに、ソフトウェアは大規模なプロジェクトにも小規模なプロジェクトにも使用することができ、変更にも容易に適応することが保証されています。`
      },
      item6: {
        title: 'サポート',
        desc: `ビジネスに新しいテクノロジーを導入することが課題であることは理解しています。特にあなたとそれを使用するすべての人にとっては、大変です。だからKeDA Techはあなたと一緒にすべての段階で支援します。デジタルビジネスでのテクノロジーの導入に最適なサポートを提供することに専念しています。それだけでなく、製品の監視も行い、洞察力のあるフィードバックを与え、バックアップのメンテナンスも提供し、24/7のコールサポートも行います。一緒にテクノロジーの課題に取り組みましょう！`
      },
      item7: {
        title: '次のステップ',
        desc: `KeDA Techの運用を慎重に調査した後、これから進むことができる2つの道があります。まず第1に、組織内での内部議論を行った後、KeDA Techによって定義された作業方法を実装することが重要です。実装プロセスが理論上よりも困難で正確ではないかもしれませんが、KeDA Techのチームはいつでも協力して質問に答えることができます。<br/><br/>第2のステップは、KeDAの仕事によって生成されたすべてのプロセスと製品を自社に統合することです。これらの製品の統合は、ビジネスの成功に重要な役割を果たすソフトウェアとシステムを活用することを必要とするため、より複雑なタスクとなります。この目標を達成するためには、適切なトレーニングと追加の時間が必要になります。<br/><br/>このプロセスで困難が生じた場合は、私たちに支援を求めてください。KeDA Techのチームは、技術について迷惑や困惑することなくすべてを達成することを確実にすることに専念しています。私たちは、チームのスムーズかつ成功した統合を確保するための継続的なサポートを提供することにコミットしています。`
      }
    },

    blog: {
      header: 'KeDAブログ',
      search: '検索',
      load: 'もっと見る',
      read: '続きを読む',
      trend: '今注目の記事',
      details: {
        related: '関連する記事',
        more: '他の記事を見る'
      },
      content1: {
        title: `今日から技術を受け入れる必要性のトップ7の理由を発見しましょう！`,
        desc: `
        <p>テクノロジーの力を今すぐビジネスに活かす</p><div class="par-spacer"></div>
        <p>ITやビジネスの分野をはじめ、あらゆる場面でテクノロジーの飛躍的な進歩があり、世界は急速に発展しています。リアルタイムであらゆるものとつながることは、かつてないほど容易になりました。</p><div class="par-spacer"></div>
        <p>テクノロジーは、その適応性の難しさから、ビジネスの世界では邪魔者扱いされていた時代は過ぎ去りました。現在では、テクノロジーはビジネスの成長に必要なツールとなっています。ここでは、テクノロジーをビジネスに活用することで得られるさまざまな利点をご紹介します。</p><div class="par-spacer"></div>
        <ol>
        <li><p>運用タスクの簡素化<br/>構造化されたテクノロジーを利用すれば、特にテクノロジーにすばやく適応できる場合は、運用プロセスが容易になります。これは、より効率的で効果的なビジネスへとつながります。</p></li><div class="par-spacer"></div>
        <li><p>コミュニケーションの改善<br/>パンデミック発生時、テクノロジーによって、チームメンバー間のコミュニケーションとコラボレーションが、たとえ遠くからでも容易になりました。ビデオ会議、チャット、電子メールなどのツールは、コミュニケーションをより迅速かつ効率的にします。</p></li><div class="par-spacer"></div>
        <li><p>データ分析の向上<br/>テクノロジーは、データの収集と分析を容易にし、情報に基づいたビジネス上の意思決定を支援します。正確で最新のデータは、より良い意思決定につながり、目標達成を支援します。</p></li><div class="par-spacer"></div>
        <li><p>より広いマーケットへのリーチ<br/>テクノロジーは、国内外を問わず、より広いマーケットへのアクセスを可能にします。テクノロジーの進化により、インターネットやソーシャルメディアを通じて製品のプロモーションや販売が容易になり、より多くの潜在顧客にリーチできるようになりました。</p></li><div class="par-spacer"></div>
        <li><p>ビジネスの効率化<br/>テクノロジーは、ビジネスプロセスを最適化し、コストを削減するのに役立ちます。例えば、ビジネスソフトを使うことで、手作業によるコストを削減し、財務管理やレポーティングを容易にします。</p></li><div class="par-spacer"></div>
        <li><p>セキュリティの向上<br/>テクノロジーは、データや情報のセキュリティの向上にも役立ちます。暗号化や堅牢なセキュリティシステムにより、重要なデータを不正なアクセスから守ることができます。</p></li><div class="par-spacer"></div>
        <li><p>生産性の向上<br/>テクノロジーは、ビジネスの生産性を向上させることも可能にします。例えば、タスク管理とスケジュール管理を簡素化するソフトウェアを使用すれば、時間を節約し、重要なタスクを時間通りに完了させることができます。</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content2: {
        title: `ERPとそのビジネスへのメリットを知ることで最適なビジネスを実現する`,
        desc: `
        <p>ERP（Enterprise Resource Planning）は、大企業向けの強力なシステムおよびソフトウェア・ソリューションです。ERPは、財務、調達、生産、人事、従業員の監視など、さまざまなビジネス活動の管理を支援します。</p><div class="par-spacer"></div>
        <p>ERPが選ばれる理由は、より良いビジネス上の意思決定を行う上で企業を支援することができるリアルタイムかつ正確な情報を提供することです。しかし、これは会社が迅速にERPに適応することができた場合にのみ発生する可能性があります。それはまた、大幅にあなたのビジネスに利益をもたらすことができるいくつかの他の利点を持っているので、ERPを導入することの利点は、それだけにとどまらない。</p><div class="par-spacer"></div>
        <ol>
        <li><p>会社の効率を向上させる。<br/>ERPを導入することで、物理的なデータは、デジタルデータに変換する必要があります。デジタル化されたリアルタイムのデータには、社内の誰もがアクセスできるため、情報へのアクセスが容易になり、業務プロセスのスピードアップが図れます。</p></li><div class="par-spacer"></div>
        <li><p>コラボレーションを強化する。<br/>社内のすべての従業員がデータに直接アクセスすることで、部門間のコラボレーションが促進され、情報へのアクセスが容易になります。クラウドベースのERPでは、インターネットを介した支店間のコラボレーションも可能です。</p></li><div class="par-spacer"></div>
        <li><p>業務コストの削減 <br/>業務プロセスを自動化することで、人件費の削減や業務上の問題や不具合の予見に役立ちます。</p></li><div class="par-spacer"></div>
        <li><p>データセキュリティの向上。<br/>ERPシステムには、データ漏洩を防ぐための制限コントロールがあり、管理者のみがアクセスキーを持つことができます。</p></li><div class="par-spacer"></div>
        <li><p>正確なビジネス予測。<br/>ERPが提供するリアルタイムで一貫性のあるレポートは、より正確なビジネス予測を作成するのに役立ちます。</p></li><div class="par-spacer"></div>
        </ol>
        <p>以前は、ERPは大企業にのみ適用されていましたが、現在では中堅企業にも必要とされています。KeDA Tech は、お客様のビジネスニーズに合わせてカスタマイズ可能な ERP ソリューションを提供します。ぜひ私たちにご相談いただき、ERPのメリットを実感してください。</p><div class="par-spacer"></div>
        `
      },
      content3: {
        title: `あなたのビジネスが絶対にウェブサイトが必要な3つの強力な理由`,
        desc: `
        <p>現在、オンライン販売ではソーシャルメディアやマーケットプレイスがトレンドとなっていますが、Global Web Indexによると、62％の顧客がブランドや製品に関する情報をウェブサイトから検索することを好んでいるとのことです。<br/>起業家にとって、このデータは無視できないものです。もし、自分のビジネスのための公式ウェブサイトを持っていなければ、ビジネスチャンスが減少する可能性があります。ウェブサイトは、顧客の情報源としてだけでなく、ビジネスをより広く認知させ、新たな顧客を獲得することもできるのです。<br/>ここでは、ビジネスが公式ウェブサイトを持つべき重要な3つの理由を紹介します。</p><div class="par-spacer"></div>
        <ol>
        <li><p>会社の信頼性。<br/>ウェブサイトは、大小問わず、あらゆるタイプのビジネスにとって重要です。なぜなら、ウェブサイトは潜在的な顧客に当社を信頼させることができるからです。顧客からの本物のレビューやフィードバックを表示することができます。このように、プロフェッショナルと見なされるため、潜在的な顧客も私たちのビジネスを信頼します。</p></li><div class="par-spacer"></div>
        <li><p>プロモーションの場。<br/>ウェブサイトは、特にSEOがうまく適用されている場合、プロモーションのための最適な場所です。ウェブサイトのトラフィックが増加し、自動的に私たちの製品に興味を持っている買い手が存在することになります。また、ウェブサイトは24時間365日、私たちのビジネスを宣伝することができます。</p></li><div class="par-spacer"></div>
        <li><p>顧客サービスの質を向上させる<br/>顧客の快適性に合わせて調整できるウェブサイトは、顧客をロイヤル化することができます。メール付きの質問フォームなどの問い合わせページを追加したり、ウェブサイトからチャットボットやライブチャットを作成したりすることもその一つです。<br/>テクノロジーはこれからも進化し続けるので、私たちのビジネスが適応できなければ、痛い目を見ることになります。そのため、KeDA Techは、どの技術もビジネスにおいて重要な役割を担っていることを十分に理解しています。導入の際には、ぜひご相談ください。</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content4: {
        title: `危機から革新へ：パンデミックが技術導入に与える影響`,
        desc: `
        <p>COVID-19のパンデミックは世界を混乱させ、私たちの生活やビジネスに大きな影響を及ぼしています。ウイルスが蔓延する中、ウイルスを拡散させることなく日常生活を送るためには、テクノロジーが不可欠であることがますます明らかになっています。パンデミックにより、テクノロジーの導入が急速に進み、企業はこの新しい現実に迅速に適応しなければならなくなりました。</p><div class="par-spacer"></div>
        <p>パンデミックの最大の影響の一つは、リモートワークへの移行です。従業員が職場に行けなくなったことで、企業は業務を中断することなく業務を継続する方法を見つけなければならなくなりました。このため、ビデオ会議ソフトウェアや仮想プロジェクト管理ツールなどのコラボレーションツールの利用が大幅に増加しました。これらのツールにより、企業は遠隔地で業務を継続し、従業員が同じオフィスにいるのと同じように働くことができるようになりました。</p><div class="par-spacer"></div>
        <p>また、オンライン・コマースの分野でも、テクノロジーが重要な役割を担っています。実店舗への足が遠のく中、オンラインショッピングは以前にも増して人気を博しています。小売業者はこの変化に迅速に対応し、オンライン・プラットフォームに投資し、需要の高まりに対応するためにオンラインでの存在感を高める必要がありました。その結果、Eコマース・プラットフォームの利用が大幅に増加し、今や多くの企業の戦略に欠かせない存在となっています。</p><div class="par-spacer"></div>
        <p>最後に、このパンデミックでは、人工知能や機械学習技術の利用も進んでいます。これらの技術は、ウイルスの拡散を追跡・予測したり、病院のベッドや医療用品などのリソースの管理を支援するために利用されています。また、医療従事者がより重要な業務に集中できるよう、パンデミックに関する質問への回答や情報提供を支援するチャットボットの作成にも利用されています。</p><div class="par-spacer"></div>
        <p>結論として、COVID-19の大流行により、企業や個人が新しい働き方やコミュニケーションの方法に適応しなければならなくなったため、テクノロジーの導入が急速に進んだと言えます。パンデミックは、私たちがウイルスを広げることなく日常活動を続けるためのテクノロジーの重要性を示し、企業がテクノロジーを使って業務を改善する新しい機会を生み出しました。世界が前進するにつれ、テクノロジーは私たちの生活やビジネスにおいてますます重要な役割を果たすようになると思われます。</p><div class="par-spacer"></div>
        `
      },
      content5: {
        title: `ブロックバスターの昇華と墜落：変化する技術に適応しない代償`,
        desc: `
        <p>1980年代、一般消費者が映画を見る方法はいくつかあり、主に地元のビデオ屋で映画を借りていた。当時はブロックバスター・ビデオがビデオレンタルのリーダーで、映画の需要の高さから大儲けしていた時代である。しかし、世の中は変化し、テクノロジーは急速に進歩していました。Netflixのようなストリーミングサービスが登場したことで、ブロックバスターは市場の変化に適応しなければならなくなったのです。しかし、残念なことに、彼らはそれを果たせず、最終的には終焉を迎えてしまったのです。</p><div class="par-spacer"></div>
        <p>1989年に1号店をオープンしたブロックバスターは、10年以内に小さな店舗チェーンから、25カ国に9,000店舗以上を展開する世界的な企業に成長したのです。ブロックバスターのビジネスが大きく成長したのは、レンタルモデルが成功したおかげである。消費者は、近所の店で映画を借り、その後お金を払うことなく返却することができた。このモデルは、より多くの人が手頃な価格で映画にアクセスできるようにし、多くの人にアピールすることができた。</p><div class="par-spacer"></div>
        <p>しかし、当初はレンタルモデルがうまくいっていたとはいえ、永遠に維持できるほどではありませんでした。Netflixのようなストリーミングサービスの登場により、消費者は自宅から出ることなく、お金を払うことなく、より簡単に映画を見ることができるようになったのです。また、Netflixは、加入者がいつでも好きな映画を無制限に見ることができるようになった。このため、Blockbusterの顧客は大幅に減少し、最終的に同社の経営破綻につながったのです。</p><div class="par-spacer"></div>
        <p>ブロックバスターの失敗は、企業が生き残るためには、テクノロジーの変化に対応することがいかに重要であるかを物語っている。技術が進歩し、新しいトレンドが生まれると、企業はそのトレンドに遅れないように変化を遂げる必要があり、そうでなければ潜在顧客を失う危険性がある。ブロックバスター社は、このようなことを怠ったために、ビジネスを失うことになったのだ。</p><div class="par-spacer"></div>
        <p>つまり、ブロックバスター社の消滅は、企業がテクノロジーの変化に対応できなければ、競争の激しい市場で失敗する危険性があることを思い起こさせるものである。ブロックバスターの破滅は、企業が関連性を保ち成功するためには、変化を積極的に受け入れる必要があることを示す例となる。また、技術の進歩に対応する方法を学ばなければ、どんな企業も失敗を免れることはできないという重要な教訓にもなっています。</p><div class="par-spacer"></div>
        `
      }
    },

    solution: {
      header: {
        title: 'ソリューション',
        desc: 'お客様のビジネスの発展に貢献するために、さまざまなソリューションを提供しています。'
      },
      item1: {
        title: 'ウェブサイト開発',
        desc: `ユーザーフレンドリーなインタフェースの設計はウェブサイトのコンバージョン率を最大200%まで高めることができます。そのため、ビジネスのウェブサイトが魅力的なデザイン、完全な機能、簡単にナビゲーションできるようにすることが重要です。私たちはあなたのニーズや好みに応じて、これらすべてを処理することができます。<br/><br/>法人のウェブサイト、eコマースサイト、マイクロサイト、ランディングページから、プロフェッショナルで機能豊富なデザインのカスタムデザインまで、作成から始めます。私たちはあなたのウェブサイトが単に見栄えが良いだけでなく、ビジネスのニーズに応えるために最適に機能するようにすることを保証します。`
      },
      item2: {
        title: 'モバイルアプリケーション',
        desc: `オンラインショッピングでは、アプリケーションへのアクセス時にユーザーエクスペリエンスが悪いため、約70%のショッピングカートが破棄されます。これがあなたのデジタルビジネスに起こらないようにしましょう。私たちと一緒に働き始めましょう。なぜなら、私たちはあなたのビジネスアプリケーションユーザーにとっての使いやすさだけでなく、デザイン面にも特別な注意を払い、ユーザーが視覚的に魅力的で楽しいものになるように努力しています。`
      },
      item3: {
        title: 'コンピューターゲームの開発',
        desc: `ビジネスの未開拓のポテンシャルを、魅力的でユニークな広告メディア、ビデオゲームで発見しましょう。どのように魅力的にするか心配しないでください、私たちはあなたのアイデアを現実にするために協力します。ビジネスに適した正しいビデオゲームの決定から、デザインの作成、製作の実行まで、コンピュータゲーム、コンソールゲーム、モバイルデバイスに対応しています。この革新的な広告アプローチの力を今すぐ抱きしめましょう！`
      },
      item4: {
        title: '企業リソース計画システム',
        desc: `正確なデータを活用して、ビジネス費用を46%節約し、生産性を90%向上させるために、適切なエンタープライズリソースプランニング（ERP）を活用しましょう。Odoo ERPのチームはあなたのERP実装の成功を支援するため、幅広い機能を提供しています。今すぐ当社に相談して、特定のビジネスニーズに合わせた最適なERPソリューションを見つけましょう。`
      }
    },

    project: {
      header: 'プロジェクト',
      quote:
        'KeDA Techを探索する旅においでください。私たちが手がけた驚くべきプロジェクトを見ていただけます。そして、それぞれのプロジェクトが実現するまでに要した時間も学べます。',
      see: 'プロジェクトを見る',
      load: 'もっと読み込む',
      time: '時間',
      months: '月数',
      worker: '必要なチーム',
      tech: '使用した技術',
      timeline: '効果的なプランニングのタイムライン',
      development: '開発期間中の週数',
      'task-completed': 'タスク完了',
      point1: '問題の理解',
      point2: 'アイデア発想',
      point3: 'ソリューション',
      explore: '現状を打破し、イノベーションを喚起する画期的なプロジェクトに迫る',
      detail: {
        'item-wayfinder': {
          name: 'Wayfinder インドネシア',
          subtitle:
            '生産システムとのシームレスな統合により、インドアナビゲーション体験を変革します。',
          desc: 'このシステムにより、お客様の従業員は、簡単に効率的に物品の入出庫を管理できるようになります。',
          'desc-point1': `製造・倉庫システムにテクノロジーを導入することで、その従業員の作業負荷を簡素化し、生産性を向上させるにはどうしたらよいでしょうか。`,
          'desc-point2': `このシステムは、倉庫作業者の効率化、特に部屋の中の品物の配置に革命を起こすことを目的に登場しました。倉庫内の配置を最適化することで、より合理的で効率的なシステムを実現し、関係者全員が利益を得られる世界を想像してください。今こそ、ワークプレイスにおける収納と整理整頓のあり方を見直す時なのです。`,
          'sub-point3': `Wayfinderの登場は、倉庫の運営方法に革命をもたらし、効率と効果を際立たせるものとなりました`,
          'desc-point3': `入力されたデータは部屋の現状と統合されるため、情報と環境の融合がダイナミックに展開され、魅了される。`,
          quote: `"プロセスのニーズを解決するための彼らの絶え間ないコミュニケーションは、堅実なプロジェクトマネジメントの鍵です。また、彼らの積極性と誠実さによって、タスクの成功に向けたコミットメントを高く評価しています。"`,
          'quote-by': `ITプロジェクト・コーディネーター Richard Moran`
        },
        'item-axa': {
          name: 'AXA SMART DRIVER',
          subtitle: 'バーチャルリアリティ技術で、スムーズで手間のかからないドライビングを楽しもう!',
          desc: 'インタラクティブなVRで、非日常的なドライブ体験の準備を! 圧倒的な快適性と安全性で、仮想都市をドライブするスリルを体感しよう',
          'desc-point1': `アクサは私たちに、運転をより安全なものにするためのチャレンジを提示しています。AXAとともに、アンフィールドの街で、特別なドライビングシミュレーション体験をお届けします。インタラクティブで高度なVR技術により、現実世界での運転を安全かつ快適に体験することができます。`,
          'desc-point2': `このシミュレーションは、ドライバーに非日常的な運転体験を提供するという、ひとつの大きな目標を持って作成されています。高度に進化したインタラクティブなVR技術で、臨場感あふれる安全で快適な旅をお届けします。`,
          'sub-point3': '',
          'desc-point3': `アクサ・スマートドライバーは、ドライバーに安心して運転する機会を提供します。高度なテクノロジーサポートにより、ユニークな方法で安全性を維持することができます。このテクノロジーは、安全運転のための境界線を警告するため、道路に集中することができ、安全な旅を通して時間を節約することができます。アクサのスマートドライバーをお試しいただき、大胆不敵なドライブのスリルを味わってください。`,
          quote: `"KeDa Techが提供する技術を当社のドライビングシミュレーションに使用できることを大変うれしく思います。ドライバーに特別な運転体験を提供することを主な目的として、この高度なインタラクティブVR技術により、高い臨場感で安全かつ快適な旅を実現できると信じています。KeDa Techは、プロフェッショナリズムと専門知識を駆使して、このシミュレーションの作成に取り組んでくれました。私たちは皆、彼らの仕事に非常に感銘を受けています。彼らはプロジェクトを安全、正確、信頼性の高いものにし、すべてのドライバーが並外れた運転体験を得られるようにしました。また、このシミュレーションの制作中、KeDa Techのサポートに大変感謝しています。彼らは顧客満足を優先し、高品質のサービス保証を提供してくれるので、私たちは高品質で効率的、かつ正確な製品を作ることができます。VRインタラクティブ技術を使ったシミュレーションを作りたいと考えているお客様には、ぜひKeDa Techをお勧めします。彼らは常に最高のソリューションを提供することで、顧客が目標を達成するのを助けてくれると心から信じています。KeDa Techの皆様、ご協力ありがとうございました。"`,
          'quote-by': ``
        },
        'item-load-container': {
          name: 'Load Container',
          subtitle: '貨物輸送の効率化を実現するソフトウェア',
          desc: '',
          'desc-point1': `コンテナ内の物量を最大化するには？ このソフトがないと、コンテナやカーゴ内の物品の配置に何度も試行錯誤を繰り返すことになります。そのため、1カーゴのコンテナ数を計算・見積もるのに時間がかかる)`,
          'desc-point2': `コンテナ・ローダー・プログラムで最適化を実現 - 手計算の煩わしさから解放され、楽に最適化ができるようになります。コンテナ内の商品量とコンテナ内の貨物量を計算し、最適なコンテナの組み合わせを提案するプログラムにより、業務効率と収益性を最大化します。`,
          'sub-point3': '',
          'desc-point3': `本ソフトウェアは、最適なコンテナ積載を簡単に実現するためのソフトウェアです。様々なユニークな機能により、ユーザーは貨物を積載するための最適なコンテナの組み合わせを決定し、ソフトウェア計算の推奨によって代替目的地を選択することができます。コストと効率を意識しながら、効率的で確実な貨物積載とコンテナ手配を実現するソフトウェアです。`,
          quote: ``,
          'quote-by': ``
        },
        'item-sqm-chatbot': {
          name: 'SQM CHATBOT',
          subtitle:
            'オンラインでつながったチャットボットで、高速に対話・情報交換を体験してください',
          desc: 'このチャットボットは、質問に答えることで、顧客とのインタラクションを瞬時に増やすことができます。高度な技術を持つこのチャットボットは、変化に富んだ様々なタイプのインタラクションをリアルタイムでカバーすることができます。このチャットボットを利用することで、顧客にとって最高のサービス体験を実現することができます。',
          'desc-point1': `コンタクトデータを繰り返し収集することなく、時間をかけてお客様とつながり続けるにはどうしたらよいのでしょうか。`,
          'desc-point2': `チャットボットは、お客様に光速のソリューションを提供し、人間のエージェントに接続できるまでのギャップを埋め、時間の制限なく24時間サポートを提供する使命を担っています。`,
          'sub-point3': '',
          'desc-point3': `SQMプロパティのチャットボットSQMを利用することで、お客様はSQMプロパティに関する迅速かつ正確な回答が得られるようになります。いつでも、時間の制限なく、お客様はSQM Propertyに関する質問に対する回答を簡単に、効率的に見つけることができます。`,
          quote: `"当初から、チャットボットのビジョンを実現するためにKeDa Techと提携したのは正しい選択だったと確信しています。御社の提供するソリューションは、迅速かつ正確であるだけでなく、当社の顧客にとっても使いやすいものでした。当社の顧客は、SQM Propertyに関する質問に対して、時間の制約を受けることなく、いつでも素早く回答を得ることができるようになりました。<br><br>チームは非常にプロフェッショナルで、私たちが抱く疑問や懸念にタイムリーに応えてくれました。細部にまで気を配り、最高品質の製品を提供するための献身的な姿勢は、プロジェクトのあらゆる側面に見受けられました。<br><br>私たちはこのプロジェクトの結果に非常に満足しており、ソフトウェア開発のパートナーを探している人には、KeDa Techを強くお勧めします。"`,
          'quote-by': ``
        },
        'item-verif-doctor': {
          name: 'Jasa Raharjaで専門的な医療サービスへのアクセスを開始',
          subtitle:
            '最先端のドクター・コンサルタント・マネジメント・アプリケーションでヘルスケアに革命を起こす。効率化、コミュニケーション強化、ワークフローの合理化により、患者の予後を最適化します。',
          desc: `事故の後、被害者やその家族が最も心配しなければならないのは、複雑で分かりにくい病院の請求手続きをナビゲートすることです。そこで私たちは、Jasa Raharjaの保険に加入している患者さんのために、最先端のテクノロジーを駆使して手続きを簡素化することに力を注いでいます。私たちの革新的なソリューションにより、病院の請求額を簡単に計算・確認することができ、困っている人に安心を与えることができます。もう、何時間も待たされることも、情報が足りずにイライラすることもありません。私たちの合理的なアプローチで、医療をより身近に、よりストレスの少ないものにします。`,
          'desc-point1': `事故後の回復への道のりは長く、特にPT Jasa Raharjaの補償のためのデータ検証にはストレスがかかるものです。従来の検証プロセスは時間がかかり、面倒なことが多いため、医師のコンサルタントと事故被害者の双方に困難をもたらしました。しかし、この癒しの旅の重要なステップをスピードアップさせる方法があるとしたらどうでしょう？最先端のテクノロジーを活用することで、PT Jasa Raharjaの補償対象となる事故被害者のデータを迅速かつ正確に検証することが可能になりました。もう待つ必要はなく、イライラすることもありません。私たちの合理的なアプローチによって、事故の被害者とその家族が必要なときに必要なサポートを受けられるようにします。私たちと一緒に、事故後のデータ検証の処理方法に革命を起こしましょう。`,
          'desc-point2': `これまで、保険会社PT Jasa Raharjaが保険の対象となる事故の被害者にサービスを提供する際のデータの検証プロセスは、十分に効率的とは言えませんでした。この問題を解決するため、私たちのチームは、このプロセスの効率を上げるためのソリューションを模索しています。一連のメンテナンスを行った後、PT Jasa Raharjaのデータ確認プロセスの効率化を支援するアプリケーションを作成することにしました。このアプリケーションによって、時間とコストを削減し、プロセスをより簡単かつ効率的にすることで、コンサルタント医師がより迅速かつ正確に事故の被害者に対応できるようになることが期待されています。`,
          'sub-point3': '',
          'desc-point3': `Jasa Raharjaの対象となる事故被害者の病院請求データの確認作業を支援する「コンサルタント・ドクター確認アプリケーション」をご紹介します。このアプリケーションは、コンサルタント・ドクターと事故被害者の双方にとって、検証プロセスをより迅速かつ容易にするためのソリューションです。`,
          quote: `"ケダテックには、Jasa Raharjaでのソリューションの開発と立ち上げに多大な協力をいただきました。彼らは、Jasa Raharjaの対象となる患者さんの医療体験を全体的に向上させるための合理的なプロセスの開発を支援してくれました。プロジェクトマネージャーからソフトウェアエンジニアまで、彼らのプロフェッショナルなチームは、事故後の患者さんとその家族の支払いプロセスを容易にする革新的な製品を作るために、全員が非常にプロフェッショナルで、コミュニケーションをとりながら、迅速に対応してくれました。私たちは、すべての人々が手頃な価格でストレスなく医療を受けられるようにするための大きなプログラムの一端を担えることを誇りに思います。ケダテックとの協業は、今後、患者さんの体験に持続的なプラスの影響を与えると確信しています。"`,
          'quote-by': ``
        },
        'item-health-clinic': {
          name: '',
          subtitle:
            'アプリケーションを使用して、患者データの保存からスタッフの活動の追跡、継続的な監視と評価まで、クリニックでのすべての活動の管理を容易にします。',
          desc: `このアプリケーションは、クリニックの管理だけでなく、医師が患者の経過を確認するためにアクセスし、活用することができます。これにより、医師は最新の情報を入手し、患者さんに最善の治療を提供することができ、患者さんの早期回復に向けた正しい道のりを歩むことができます。`,
          'desc-point1': `この問題を解決するためには、まずクリニックを効率的に管理するための要件を特定する必要があります。これには、最初の調査を行い、データを収集し、業界標準と比較し、利用可能なさまざまなソリューションを評価することが必要です。問題とその解決に役立つ適切なアプリケーションを理解した上で、効果的かつ効率的な患者管理を可能にする統合的なリアルタイム・ソリューションを提供することができます。`,
          'desc-point2': `クリニック経営者が直面している問題の分析から始まり、解決策の効果を確実にするために最初の技術をどのように適用するかについてアイデアを練り、クリニック経営者が目標を達成するためにどの技術が適しているか、様々な技術をテストしています。我々のチームは、クリニック経営者がクリニックと患者のデータをリアルタイムかつ総合的に管理・処理できるようなアプリケーションを開発すべく、努力を重ねています。アプリケーションのテストと品質保証のプロセスが終了した後、プロジェクトの目的がしっかりと達成されているかどうかを再確認しています。`,
          'sub-point3': '',
          'desc-point3': `このアプリケーションは、クリニックの経営者や医師の効率性と生産性を向上させるソリューションとして開発されました。このアプリケーションを使用することで、患者さんのヘルスケアデータやクリニックの活動をより簡単かつ迅速に管理することができます。これは、クリニックや医師がさまざまな健康問題に対応するための生産性を向上させるための重要なステップです。`,
          quote: `"KeDa Techが、私たちのヘルスクリニックをより効率的かつ生産的に運営できるようなアプリケーションを作成してくれたことに、とても感謝をしています。このアプリケーションのおかげで、患者の健康データと診療活動を簡単かつ迅速に管理できるようになりました。<br><br>このアプリケーションのおかげで、クリニックのマネージャーや医師は、より高いレベルで効率的に患者さんをケアすることができるようになりました。これは、私たちのクリニックと医師がさまざまな健康問題に対処する際の生産性を向上させるための重要なステップです。<br><br>御社のアプリを使用することで恩恵を受けたすべてのスタッフを代表して、KeDa Techに感謝したいと思います。私たちは、これまでの結果に非常に満足しており、今後も貴社とのさらなるコラボレーションを期待しています。"`,
          'quote-by': ``
        },
        'item-price-fetcher': {
          name: 'PRICE FETCHER',
          subtitle:
            'さまざまなEコマースの価格を追跡し、比較するソフトウェアで、最もお得に購入することができます',
          desc: `アプリケーションを利用することで、お探しの商品の価格を比較し、最もお得な価格で購入することができます。`,
          'desc-point1': `価格を比較するためだけに、Eコマースアプリケーションに1つずつアクセスするのは疲れるし、現実的ではありません。一度のアクセスでECサイトの価格を比較するにはどうしたらいいのでしょうか？`,
          'desc-point2': `私たちは、eコマースの商品価格を比較する際に、消費者が混乱していることにヒントを得ています。このソフトウェアと一緒に私たちの目標は、eコマースで商品価格を選択し比較するプロセスを、より実用的かつ効率的にするために促進し、支援することです。`,
          'sub-point3': '',
          'desc-point3': `本ソフトウェアは、様々なECプラットフォームで異なる商品価格データにアクセスする際の問題点を解決するものです。この技術により、ユーザーは各ECプラットフォーム用のアプリケーションに個別にアクセスすることなく、探している商品の価格を簡単に入手することができます。`,
          quote: ``,
          'quote-by': ``
        },
        'item-gemx': {
          name: 'G-EMx',
          subtitle: '',
          desc: `ビジネスニーズをいつでもどこでも管理するデジタルアプリ。`,
          'desc-point1': `多くのビジネスオーナーにとって、すべてのビジネスアクティビティを1つの場所でアクセスすることは実際には課題です。異なる部署と調整して企業の財務データにアクセスすることが必要な伝統的な方法は時間を食い、効率が悪いこともあります。これはビジネスオーナーが不満を感じ、最新の情報に基づいて迅速な決定をすることができないという結果につながりま`,
          'desc-point2': `セキュリティのレベルを最優先に考慮して、G-EMxに対してビジネスオーナーがいつでもどこでも自分の財務データにアクセスできるようにするシステムを作るソリューションを提供します。`,
          'sub-point3': '',
          'desc-point3': `ビジネス・オーナーは、すべてのビジネス活動の完全な制御と監視、カスタマイズされた財務ニーズへのアクセス、財務取引のリアルタイムの可視性、そのすべてを指先で操作できるようになりました。私たちは、ビジネスオーナーが財務管理で直面する課題を理解しています。だからこそ、これらの課題に対処し、ビジネスオーナーが必要とする容易さと利便性を提供するソリューションを開発したのです。`,
          quote: `"KeDa Techとのパートナーシップは、私たちのビジネスニーズを管理するデジタルアプリ作りにとって素晴らしいことです！彼らはモダンなビジネスのニーズをとてもよく理解しており、私たちの期待を超えるソリューションを提供してくれました。KeDa Techのソフトウェア開発の専門性をフル活用することで、私たちの業務をスムーズに運用することができ、いつでもどこでもビジネス活動を完全に管理・監視することができます。このアプリはユーザーフレンドリーで、カスタマイズ可能なのと、リアルタイムな財務トランザクションの可視化が可能なので、使いやすい！"`,
          'quote-by': `Asmadi Ahmad - CEO G-EMx Technologies`
        }
      }
    },

    consult: {
      form: {
        title: '今すぐ私たちと相談してください',
        desc: 'デジタルビジネスやテクノロジー領域に足を踏み入れることで、多くの質問が生じることが確実です。以下のフォームを埋めてから始めてください。無料で回答を提供いたします。',
        input1: '名前',
        input2: 'あなたのビジネスセクター',
        input3: '電話番号',
        input4: '電子メール',
        input5: 'あなたの質問とニーズ',
        office: {
          name: 'ジャカルタ事務所',
          address:
            'グリーン・ビル・コンプレックス、ラトゥ・ケムニング・ストリート、ブロックBL番号1、RT08/RW14、ジャカルタ、+(62-81) 180-878-87'
        },
        send: '送る'
      },
      desc: {
        title: 'あなたにとってどのようなメリットがありますか？',
        desc: 'このようにプロセスが進んでいきます。'
      },
      point1: {
        title: '1. 問題',
        desc: `あなたのビジネス業務をより深く理解するために、意味のある会話を通じて探求することができます。あなたの独自のニーズと現在の技術セットアップを徹底的に理解することができます。共に、核心の問題点を明確にして、成功をもたらすカスタマイズされたソリューションを提示します。安心してください、私たちは常にアプローチを測定し最適化することで、優れた結果を確保することができます。`
      },
      point2: {
        title: '2. 解決方法',
        desc: `KeDa Techは、真に違いをもたらすソリューションを提供することが成功への鍵であることを理解しています。そのため、私たちの専門家チームは、ソリューションの構築に取りかかる前に、お客様独自の状況について徹底的な調査と分析を行います。そして、お客様の課題を解決し、目標を達成するためのソリューションをカスタマイズしていきます。<br/><br/>私たちは、お客様のニーズに合わせてカスタマイズされたソリューションを提供することが、真の成果をもたらし、永続的な影響を与える最良の方法であると信じています。そのため、お客様のために作られたソリューションを提供できるのに、なぜ画一的なアプローチで妥協するのでしょうか。KeDaTechは、まさにそれを実現します。`
      },
      point3: {
        title: '3. 次のステップ',
        desc: `KeDa Techは、お客様との強固で持続的な関係を構築することの重要性を理解しています。そのため、プロジェクトが完了した後でも、私たちの取り組みはそこで終わりません。私たちのフォローアップ・プロセスは、完全な満足を保証し、あらゆる懸念に対処し、お客様のシステムを長持ちさせるために継続的なサポートを提供するように設計されています。定期的なチェックイン、迅速な問題解決、専任のサポートチームにより、お客様の成功が私たちの最優先事項であることをご安心いただけます。プロジェクトが完了した後も、私たちがお客様の成功に貢献します。`
      },
      review: 'コメント'
    },

    story: {
      header: {
        title: '私たちについての紹介',
        desc: '国際的なソフトウェアハウスになる夢から始まったKeDA Techは、2018年に誕生しました。その開発の過程でKeDA Techは、デジタルトランスフォーメーションがすべての生活の面で多くの変化をもたらし、特に企業、コミュニティ、社会のビジネス分野ではさらに大き'
      },
      'our-journey': {
        title: '私たちの旅',
        now: '今',
        item1:
          '2人のプログラマーとデザイナーからなるチームから始まり、KeDA Techは立ち上がり、最初のプロジェクトであるコンテナローダープロジェクトを完了することができました。',
        item2:
          'KeDA Techが設立してから一年後、AXAとの新しい協力関係が始まりました。このプロジェクトでは、3人のプログラマーとデザイナーが2週間で成功したVRシミュレータを作成しました。',
        item3:
          'KeDA Techはジャカルタ最大の不動産会社の1つと協力して、SQM Propertyのチャットボットアプリケーションを完成しました。',
        item4:
          'Shinta VR、Wallex、Luceria、PT Citra Kreasi Makmurなどのソフトウェア開発者との多くのプロジェクトが完了しており、当社もあなたのデジタルビジネスの進展の一部となりたいと思います。現在10人以上のプログラマーがいるKeDA Techは、最善のサービスを提供することに望ましく、これをコミットしています。'
      },
      'our-story': {
        title: '私たちの物語',
        desc: `2017年にカリフォルニアアメリカでマスターの学位を取得したKenneth Nursalimはインドネシアに戻りました。アメリカで過ごした数年は、彼にインドネシアの情報システムと技術の発展が遅れていることを示しました。 <br><br>この状況を変えることを決意し、Kenneth Nursalimは2018年にKeDA Techを創設し、国際的に認められたソフトウェアハウスになることをビジョンとしました。私たちの旅を一緒に歩んでください。テクノロジー産業を革新し、インドネシアをデジタル世界の最前線に導くことを目指しています。`
      },
      'to-team': {
        desc: 'あなたの事業のために本当に最善を尽くします。',
        'meet-team': 'みんなでチームに会いましょう'
      }
    },

    technology: {
      'short-desc': {
        title: '技術',
        desc: 'あなたの技術の進歩のために、私たちは以下の機能をすべて活用してあなたの経験を高めます。'
      },
      'unreal-engine': {
        title: 'アンリアルエンジン (UNREAL ENGINE)',
        desc: 'このゲームエンジンは、信じがたいほどの安定したフレームレート、驚くべきグラフィックス、魅惑の目の奥までの没入感、最新のDX 11技術をサポートしています。今までにない素晴らしいゲームの旅を体験する準備をしてください。',
        link: 'UNREAL ENGINEについてもっと学ぶ',
        'long-desc': `このゲームエンジンアプリケーションは、Epic Gamesによって1998年に誕生しました。当初はファーストパーソンシューティングゲームを中心に、現在はUnreal Engineとして多様なジャンルに採用されています。 <br><br>Unreal Engineは進化を続け、リアルタイム3Dグラフィックスの表現力を向上させています。毎回のアップデートで、よりリアルなグラフィックスが実現され、驚くほど美麗な世界を表現することができます。このエンジンは、想像を超えた没入感と魅力的なゲーム体験をもたらします。`,
        ask1: 'この技術は、創造性や革新の新しい窓を開くためにどのように適用されていますか？',
        explanation1: `Unreal Engine は、お客様のプロジェクトに無限の可能性をもたらします。ゲームだけでなく、映画、建築、車や交通機関、放送イベント、さらにはあらゆる種類のシミュレーションに Unreal Engine を応用することが可能です。Unreal Engine で想像力を解き放ち、あなたの作品に命を吹き込んでください。`,
        ask2: 'どのようなメリットがあるのか?',
        explanation2: `Unreal Engine を活用することで、現実を真にとらえた完璧な視覚表現を得ることができます。この強力なツールを使用すると、シミュレーションを最大限に体験し、最大限の精度で予測を行うことができます。自分が住んでいる世界と同じようにリアルに感じられる仮想世界に飛び込むことができることを想像してみてください。それが Unreal Engine のパワーです。`,
        project: {
          use: 'UNREAL Engineを使用したプロジェクト',
          title: 'モバイルアプリケーション',
          name: 'AXA',
          desc: `アンフィールドの街の喧騒をスリリングにバーチャルドライブするインタラクティブなVR体験です。混雑した交差点、狭い路地、賑やかな大通りを操りながら、都市生活の喧騒に没頭できます。最先端のVRヘッドセットと直感的な操作で、まるで自分がハンドルを握ったかのように、リアルタイムで街をナビゲートし、アンフィールドが提供するすべてを発見することができます。世界で最もエキサイティングな都市の一つを巡る、胸躍る、忘れられない旅の準備をしましょう!`
        }
      },
      'my-sql': {
        title: 'My SQL',
        desc: 'MySQLは技術に対する高い柔軟性を誇り、クロスプラットフォームデータベースです。ユーザーフレンドリーなテーブル構造は実際に実行すると優れたパフォーマンスを提供します。',
        link: 'MySQLに関してもっと学ぶ',
        'long-desc': `当初、MySQL は UNIREG プロジェクトを発展させたもので、Web サイトで使用される動的データベースとの互換性は高くはありませんでした。それが進化するにつれ、MySQL は基本的な SQL コマンド（Structured Query Language）を使用するデータベース管理システムとなり、これは非常によく知られている。`,
        ask1: 'この技術をどのように応用して、創造性と革新性の新しい窓を開いていくのか。',
        explanation1: `MySQL はリレーショナルデータベース管理システムに分類され、このデータベースアプリケーションでは、行、列、テーブルなどの用語が使用されます。<br><br>MySQL を使用してデータベース管理の世界に飛び込むと、リレーショナルデータベースの領域で、行、列、テーブルという馴染みのある用語が使用されるようになります。`,
        ask2: 'どのようなメリットがあるのか?',
        explanation2: `MySQLを導入することで、大規模なRAMは必要ありません。さらに、MySQL は他のプログラミング言語との統合もサポートしており、多用途で効率的なソリューションとなっています。`,
        project: {
          use: 'My SQLを使用したプロジェクト',
          title: 'ウェブサイト開発',
          name: 'WayFinder',
          desc: `倉庫管理システムにインドアナビゲーションシステムを統合することで、作業者の効率が向上しました。`
        }
      },
      'django-python': {
        title: 'Django Phyton',
        desc: `Django の生まれは 2003 年に起こりましたが、最初のバージョンが最終的に世界にリリースされるのは 2008 年まででした。このパワフルなフルスタックフレームワークは、Python で書かれており、開発者にウェブアプリケーションの夢を現実にするための滑らかな方法を提供します。`,
        link: 'Pythonに関してもっと学ぶ',
        'long-desc': `Djangoは2003年に作られましたが、最初のバージョンが2008年にリリースされるまで公開されませんでした。このフルスタックのフレームワークは、Pythonプログラミング言語を使用してWebアプリケーションを開発する開発者を支援するために設計されました。2019年になり、Djangoの2番目のバージョンがようやくリリースされ、開発者がWebアプリケーションのビジョンを実現するためのより多くのツールが提供されました。`,
        ask1: 'この技術は、創造性や革新の新しい窓を開くためにどのように適用されていますか？',
        explanation1: `Django は、クリーンで実用的なデザインで迅速なアプリケーション開発を可能にする高水準の Python ウェブフレームワークです。その効率性と合理的なアプローチは、Web 開発のための傑出した選択肢となっています。`,
        ask2: 'どのようなメリットがあるのか?',
        explanation2: `Django は、小さなものから大きなものまで、どんな規模のプロジェクトにも最適です。他のフレームワークと比較して、印象的な機能の数々を誇っており、アプリケーションを構築するために必要なすべての要素を簡単に処理することができます。`,
        project: {
          use: 'Django Phytonを使用した弊社プロジェクト',
          title: 'コンピューターゲームの開発',
          name: 'コンテナローダー',
          desc: `貨物やコンテナに保管されている貨物の構成を最適に計算するシミュレーションソフトウェア。`
        }
      },
      unity: {
        title: 'Unity',
        desc: `Unity Technologiesが開発したUnityは2005年に発売され、リアルタイム3Dエクスペリエンスの作成と運用における先駆的なプラットフォームであると主張しています。3Dに限らず、2D、VR、ARのクロスプラットフォームプロジェクトも提供されています。`,
        link: 'Unityに関してもっと学ぶ',
        'long-desc': `Unity Technologiesが開発したUnityは2005年に発売され、リアルタイム3Dエクスペリエンスの作成と運用における先駆的なプラットフォームであると主張しています。3Dに限らず、2D、VR、ARのクロスプラットフォームプロジェクトも提供されています。その高度な技術により、Unityはゲームとインタラクティブコンテンツの世界に革命をもたらし、魅力的で没入感のある体験をこれまで以上に簡単に実現できるようになりました。`,
        ask1: 'この技術は、創造性や革新の新しい窓を開くためにどのように適用されていますか？',
        explanation1: `Unityは、ゲーム、アニメーション、自動車デザイン、建築など、さまざまな制作に応用でき、想像力を無限に開花させることができます。`,
        ask2: 'どのようなメリットがあるのか?',
        explanation2: `Unityの利点の1つは、コードの習得が容易でありながら、幅広い機能を持つゲームを制作できるユーザーフレンドリーなシステムであることです。これにより、開発者はより効率的に作業を行い、創造的なビジョンを簡単に実現することができます。初心者でも経験豊富なゲーム開発者でも、Unityはあなたのアイデアを実現するためのツールとリソースを提供します。直感的なインターフェースと強力な機能を備えたUnityは、魅力的なゲームを作成するための究極のツールです。`,
        project: {
          use: 'Unityを使用した弊社プロジェクト',
          title: '',
          name: '',
          desc: ``
        }
      },
      'keda-engine': {
        title: 'KeDaエンジン',
        desc: `KeDaエンジンのパワーを発見しましょう - 決意とスピードのニーズから生まれたプログラム。C＃をプログラミング言語として使用していたKeDaチームは反復の遅さの課題に直面しました。`,
        link: 'KeDaエンジンに関してもっと学ぶ',
        'long-desc': `KeDaエンジンのパワーを発見しましょう - 決意とスピードのニーズから生まれたプログラム。C＃をプログラミング言語として使用していたKeDaチームは反復の遅さの課題に直面しました。しかし、堅実な決意と革新のドライブにより、KeDaエンジンは今より効率的で強力なC++の言語を備えています。`,
        ask1: 'この技術は、創造性や革新の新しい窓を開くためにどのように適用されていますか？',
        explanation1: `KeDaエンジンは、KeDaが2017年に開発した専門技術であり、プログラミングの世界を革命的に変えることを目的としています。最初はC#言語で書かれましたが、KeDaチームは反復速度が遅いという課題に直面しました。しかし、不屈の決意と革新の渇望によって、KeDaエンジンはより効率的で強力なC++言語に採用されました`,
        ask2: 'どのようなメリットがあるのか?',
        explanation2: `KedaEngine の使用メリットは、カスタマイズされたシステム、C++ の活用、他のエンジンに比べて驚くべきスピードのランタイムを持っていることです。`,
        project: {
          use: 'KeDaエンジンを使用する我々のプロジェクト',
          item1: {
            title: 'ウェブサイト開発',
            name: 'Wayfinder',
            desc: `倉庫管理システムにインドアナビゲーションシステムを統合することで、作業者の効率が向上しました。`
          },
          item2: {
            title: 'ウェブサイト開発',
            name: 'PRICE FETCHER',
            desc: `異なる電子商取引の価格を追跡し比較することができるソフトウェアですので、最適な価値を得ることができます！`
          },
          item3: {
            title: 'ウェブサイト開発',
            name: 'Load Container',
            desc: `貨物やコンテナーに配置されたアイテムの最適な配置を算出することができる先進的なシミュレーションソフトウェア。`
          }
        }
      },
      back: 'テクノロジーに戻る'
    }
  }
};
