import { id } from 'vuetify/lib/locale';

export default {
  ...id,

  kda: {
    common: {
      'book-banner': {
        btn: 'Hubungi Kami',
        desc: 'Mitra Transformasi Digital Anda'
      },
      'ask-interest': {
        title: 'Tertarik untuk bergabung dengan kami?',
        desc: 'Kami selalu terbuka untuk menyambut talenta baru',
        btn: 'Temukan posisi yang cocok untukmu'
      },
      menu: {
        home: 'Beranda',
        about: 'Tentang',
        process: 'Proses',
        services: 'Layanan',
        projects: 'Proyek',
        blog: 'Blog',
        careers: 'Karir',
        consult: 'Konsultasi',
        story: 'Cerita',
        team: 'Tim',
        tech: 'Teknologi',
        solutions: 'Solusi'
      },
      footer: {
        address: `Komplek Green Ville, Jalan Ratu <br>Kemuning Blok BL No. 1, <br>RT08/RW14, DKI Jakarta, <br>+(62-81) 180-878-87`,
        'address-mobile': `Komplek Green Ville, Jalan Ratu Kemuning Blok BL No. 1, RT08/RW14, DKI Jakarta, <br>+(62-81) 180-878-87`,
        'copy-right': `Hak Cipta &copy; 2022 KeDA Tech. Semua Hak Dilindungi.`,
        'quick-links': 'Link Cepat',
        'find-us': 'Temukan Kami Di',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn'
      },
      subscribe: {
        header: 'Dapatkan akses ke informasi terbaru dan terbaik dari kami!',
        content:
          'Bergabunglah untuk mendapatkan update tentang Cara Kerja kami, studi kasus inspiratif, dan banyak hal lainnya yang pasti akan membantu bisnismu berkembang.',
        subscribe: 'Berlanggananlah'
      }
    },

    home: {
      quote: {
        before: '"Diawali dengan perkumpulan programmer yang ahli dari Amerika, ',
        after:
          ' berkomitmen untuk membantu revolusi bisnis Anda dan mempersiapkan teknologi yang pas untuk Anda menghadapi tantangan bisnis di era digital"'
      },
      process: {
        title: 'Proses yang Kami Jalankan',
        desc: 'Kami mendengar dan paham kebutuhan bisnis Anda',
        btn: 'Detail'
      },
      solutions: {
        title: 'Solusi yang Kami Berikan',
        item1: {
          title: 'Pengembangan Website',
          desc: 'User Interface yang baik dan tepat bisa meningkatkan conversion rates website sampai 200%. Jadi sudah seharusnya website bisnis Anda menjadi efektif dengan desain yang menarik, fitur yang lengkap dan mudah diatur.'
        },
        item2: {
          title: 'Aplikasi Mobile',
          desc: '70% pelaku belanja online yang meninggalkan keranjangnya akibat pengalaman pengguna yang buruk dalam mengakses aplikasi. Jangan sampai hal ini terjadi pada bisnis digital Anda.'
        },
        item3: {
          title: 'Pengembangan Game',
          desc: 'Bawa potensi iklanmu ke level berikutnya dengan sebuah game! Tinggalkan metode-metode membosankan dan konvensional, dan coba metode baru yang menarik perhatian. Bersama kami, Anda akan memiliki kesempatan untuk membawa bisnis Anda ke level berikutnya, karena kami akan bekerja sama dengan Anda setiap langkah demi membawa visi Anda menjadi kenyataan.'
        },
        item4: {
          title: 'Sistem ERP',
          desc: 'Revolusikan bisnismu dengan kekuatan data ERP! Dengan memanfaatkan informasi yang akurat, kamu bisa menghemat pengeluaran besar dan meningkatkan efisiensi proses bisnismu hingga 90%. Saatnya untuk membawa bisnismu ke level berikutnya dengan data ERP.'
        }
      },
      projects: {
        title: 'Proyek Kami',
        item1: {
          type: 'Pengembangan Website',
          desc: 'Navigasi dalam ruangan yang terintegrasi dengan sistem manajemen gudang. Sehingga meningkatkan efisiensi para pekerjanya.'
        },
        item2: {
          type: 'Aplikasi Mobile',
          desc: 'Sebuah VR interaktif yang memberikan pengalaman mengemudi di kota Anfield'
        },
        item3: {
          type: 'Pengembangan Game',
          desc: 'Sebuah software simulasi yang mampu menghitung secara optimal tentang konfigurasi barang yang masuk dalam kargo atau kontainer.'
        },
        more: 'Jelajahi Beragam Proyek'
      }
    },

    teams: {
      header: {
        title: 'Profil Tim KeDA Tech',
        desc: 'Sebagai salah satu Perusahaan Pengembang Teknologi Informasi yang berbasis di Jakarta, KeDA Tech berkomitmen untuk memenuhi semua kebutuhan sistem informasi dan solusi IT Anda.'
      },
      members: {
        group1: 'Pembuatan website dan aplikasi',
        group2: 'Konten & Media Sosial',
        group3: 'Bisnis & Pengembangan',
        group4: 'Desain Produk'
      },
      structure: {
        desc1: `
        <p>Setelah menyelesaikan program master computer science di University of Southern California dan menjalani beberapa tahun sebagai programmer di sana,</p><br/>
        <p>Kenneth Nursalim memahami kalau teknologi di Indonesia bisa lebih maju dari sebelumnya. Sekarang Ia fokus pada pembuatan dan kemajuan software house yang didirikannya yaitu Keda Tech.</p><br/>
        <p>Dengan pengalamannya di bidang teknik dan game programmer juga keterampilannya pada C++, Java, C#, Unreal Engine 4, and Unity membuatnya percaya bahwa Keda Tech bisa menjadi pelopor teknologi terdepan di Indonesia.</p>`,
        desc2: `
        <p>Darren mengawali perjalanan akademisnya dengan fokus pada bidang Teknik, kemudian mempelajari studi lanjutan di bidang bisnis dan manajemen, yang berpuncak pada keberhasilannya lulus dari California State University.<p/><br/>
        <p>Setelah lama bekerja di Amerika Serikat, ia melihat adanya kesenjangan besar antara bisnis di Amerika dan Indonesia. Termotivasi oleh kesenjangan ini,</p><br/>
        <p>ia secara kolaboratif memulai perjalanan transformatif bersama Keda Tech untuk merevolusi strategi bisnisnya.</p>`,
        desc3: `
        <p>Setelah lulus dari Universitas Brawijaya dengan program studi Computer Science, Reno bergabung dengan Keda Tech karena kesamaan visi yang dimiliki.</p><br/>
        <p>Diawali dengan menjadi Software Engineer, sampai menjadi Senior dan Lead Developer, sekarang Ia menjadi Chief Technology Officer.</p><br/>
        <p>Dengan keahliannya di berbagai bahasa pemrograman mulai dari Java, Javascript, Python, sampai beberapa program seperti Unity3D, Android, dan REST API's, Reno yakin bisa membawa kemajuan teknologi pada Keda Tech.</p>`
      }
    },

    careers: {
      header: {
        title: 'Karir',
        desc: 'Kami adalah sekumpulan pemrogram, desainer, dan manajer proyek yang membaktikan diri dan tersebar luas di seluruh Indonesia. Di KeDA Tech, kami percaya pada kekuatan bekerja jarak jauh dan hati kami terdedikasi untuk menyediakan solusi teknologi yang tepat untuk bisnis klien kami'
      },
      second:
        'Dipimpin oleh energi dinamis anggota tim muda, KeDA Tech adalah pusat dari ide-ide yang luar biasa dan inovasi yang mengubah dunia, mendorong perusahaan menuju kesuksesan bersama',
      lives: {
        life1: {
          title: 'Bekerja dari mana saja',
          desc: 'Tingkatkan produktivitas dan kreativitasmu, bekerja dari mana saja sambil tetap terhubung dengan tim.'
        },
        life2: {
          title: 'Pelajari Teknologi Terbaru Bersama Kami. ',
          desc: 'Bergabunglah dengan komunitas KeDA Tech dan raih kesuksesan dalam petualanganmu mempelajari teknologi! Sebagai anggota, kamu akan memiliki akses pada proyek-proyek menarik yang akan membantumu membangun karir profesionalmu.'
        },
        life3: {
          title: 'Tim Mimpi Dinamis - Diterbangkan oleh semangat dan tujuan!',
          desc: 'Nggak bakal ketinggalan zaman kalo ngeladenin tim yang dinamis. Dapetin ilmu dan pengalaman baru dengan sharing bareng KeDA Tech.'
        },
        life4: {
          title: 'Teknologi Terdepan - Memecahkan Batas dan Melahirkan Kemungkinan!',
          desc: 'Jangan ketinggalan teknologi, gabung KeDA Tech buat jadi pemimpin inovasi solusi terdepan. Siappp!'
        },
        life5: {
          title:
            'Waktu kerja yang bisa diatur sesuai keinginanmu - biar kerjaan tetep lancar tanpa harus ngebosenin!',
          desc: 'Dengan KeDA Tech, kerjaanmu tambah gampang! Bebas nentuin jam kerja yang pas buatmu, asal tetep bisa terhubung sama KeDA Tech. Terus, jangan lupa, hidup seru dan sehat bersama KeDA Tech - karena kita punya BPJS Kesehatan untuk kamu.'
        },
        bottom: 'Jadi seperti apa proses perekrutannya?'
      },
      looks: {
        title: 'Ini bisa terlihat seperti ini',
        look1: {
          title: 'Pencarian Pekerjaan',
          desc: 'Ketika Anda menemukan lowongan kerja kami dan sesuai dengan apa yang Anda inginkan dalam hal peluang karir dan tanggung jawab pekerjaan'
        },
        look2: {
          title: 'Penyerahan CV',
          desc: 'Mohon kirimkan CV dan resume Anda kepada kami. Jangan lupa sertakan portofolio jika Anda memilikinya. Hal ini akan memberikan pemahaman yang lebih baik tentang keahlian, pengalaman, dan contoh pekerjaan Anda'
        },
        look3: {
          title: 'Komunikasi HR',
          desc: 'Setelah melakukan tinjauan yang cermat terhadap resume Anda, departemen HR KeDA Tech akan segera menghubungi Anda untuk menjadwalkan wawancara.'
        },
        look4: {
          title: 'Interview',
          desc: 'Berdasarkan resume yang diajukan, departemen HR akan melakukan pencocokan dan evaluasi apakah Anda mampu mengisi posisi yang kosong di KeDA Tech dan melakukan wawancara jika diperlukan.'
        },
        look5: {
          title: 'Anda diterima',
          desc: 'When your skills and our needs align, we warmly welcome you to join the KeDA Tech team! Together, we will be able to achieve great results and make a significant impact in the industry.'
        }
      },
      opening: { title: 'Lowongan Terbuka', apply: 'Lamar', share: 'Bagikan' },
      details: {
        input1: 'Nama',
        input2: 'Nomor HP',
        input3: 'Email',
        before: 'Lamar Sebelum',
        cv: 'Unggah CV Anda di sini',
        select: 'Pilih file',
        drag: 'Pilih file atau tarik file ke sini',
        join: 'Gabung Sekarang'
      }
    },

    process: {
      next: 'Selanjutnya',
      book: 'Diskusi dengan kami',
      item1: {
        header: 'Gaya Kerja KeDa',
        title: 'Gambaran Umum',
        desc: `Dalam dunia industri, tantangan adalah suatu hal yang tak terhindarkan. Namun, jangan khawatir, kami hadir untuk membantu Anda membangun usaha Anda<br/><br/>Di KeDA Tech, kami mengambil pendekatan hands-on untuk mengatasi masalah bisnis Anda dengan menganalisa setiap masalah dan memberikan solusi yang sesuai dengan teknologi yang tepat. Biarkan kami membantu Anda mengatasi tantangan dan terbang tinggi menuju keberhasilan bisnis Anda.`
      },
      item2: {
        title: 'Pengarahan',
        desc: `Untuk memulai kerja sama kita, pembuatan Brief adalah hal yang wajib. Dalam pembuatan Brief ini, kami harus memahami sepenuhnya apa yang Anda inginkan dalam bisnis digital Anda. Anda bisa langsung menjelaskan semuanya padakami, mulai dari profil usaha Anda sampai dengan tujuan yang ingin dicapai. Dengan komunikasi yang jelas, tim kami dan pemahaman Anda dapat terbentuk dan menghasilkan produk yang berkelanjutan`
      },
      item3: {
        title: 'Lingkup Pekerjaan',
        desc: `Ruang lingkup suatu proyek dapat mempengaruhi hingga 52% hasil akhirnya. Ruang lingkup ini memungkinkan kami untuk memahami apa yang Anda inginkan dalam produk bisnis digital Anda. Kami menyelami lebih jauh setiap permasalahan bisnis yang mungkin Anda hadapi dan menemukan solusi yang tepat. Dalam ruang lingkup ini, Anda juga akan melihat apa yang dapat dilakukan oleh produk digital kami dan apa yang tidak, atau bagaimana kami berencana untuk mengembangkan produk digital tersebut.`
      },
      item4: {
        title: 'Estimasi',
        desc: `Teknik estimasi ini dapat membantu memperkirakan setiap elemen dengan akurasi dalam pengembangan produk bisnis Anda. Estimasi proyek kami didasarkan pada ruang lingkup yang telah ditentukan dan beberapa faktor lain seperti tunjangan penemuan, pengiriman, dan faktor alokasi. Ketiga faktor ini juga dapat menentukan waktu dan resiko setiap tugas yang dilakukan oleh tim.`
      },
      item5: {
        title: 'Pengembangan',
        desc: `Dengan sukses menyelesaikan perencanaan proyek Anda, pengembangan produk bisnis digital Anda sekarang dapat dimulai. Kami menggunakan metodologi scrum untuk memastikan produk digital Anda berfungsi dengan optimal. Metodologi ini sangat dihormati karena menghasilkan perangkat lunak berkualitas tinggi yang sesuai dengan spesifikasi Anda. Bahkan, metode ini juga menjamin bahwa software dapat digunakan dalam proyek besar maupun kecil dan mudah untuk menyesuaikan perubahan.`
      },
      item6: {
        title: 'Dukungan',
        desc: `Kami mengerti bahwa menerapkan teknologi baru dalam bisnis bisa menjadi tantangan, terutama bagi Anda dan semua orang yang menggunakannya. Oleh karena itu, KeDA Tech akan selalu menemani Anda dalam setiap prosesnya<br/><br/>Kami berdedikasi untuk memberikan dukungan terbaik dalam implementasi teknologi dalam bisnis digital Anda. Bukan hanya itu, kami juga menawarkan pemantauan produk yang berkelanjutan, memberikan masukan yang berkualitas, melakukan pemeliharaan cadangan dan menawarkan dukungan on-call 24/7. Mari mengatasi tantangan teknologi bersama-sama!`
      },
      item7: {
        title: 'Langkah Berikutnya',
        desc: `Setelah melakukan pemeriksaan yang cermat terhadap operasi KeDA Tech, ada dua jalur yang bisa diambil ke depan. Pertama, setelah melakukan diskusi internal di dalam organisasi, penting untuk melaksanakan metode kerja yang ditetapkan oleh KeDA Tech. Meskipun proses implementasi mungkin sulit dan tidak sejelas teorinya, tim kami di KeDA Tech selalu siap membantu dan menjawab setiap pertanyaan yang Anda miliki<br/><br/>Langkah kedua melibatkan mengintegrasikan semua proses dan produk yang dihasilkan oleh pekerjaan KeDA ke dalam perusahaan Anda sendiri. Integrasi produk ini tentu akan menjadi tugas yang lebih kompleks, karena melibatkan penggunaan perangkat lunak dan sistem yang akan memainkan peran penting dalam keberhasilan bisnis Anda. Untuk mencapai tujuan ini, pelatihan yang tepat dan waktu tambahan diperlukan<br/><br/>Jika Anda mengalami kesulitan selama proses ini, jangan ragu untuk menghubungi kami untuk mendapatkan dukungan. Tim kami di KeDA Tech berdedikasi untuk memastikan bahwa Anda tidak merasa kebingungan dan bingung tentang teknologi. Kami bertekad memberikan dukungan berkelanjutan bagi tim Anda untuk memastikan integrasi yang lancar dan sukses`
      }
    },

    blog: {
      header: 'Blog KeDa',
      search: 'Cari',
      load: 'Tampilkan Lebih Banyak',
      read: 'Baca Selengkapnya',
      trend: 'Artikel yang Lagi Ngetrend',
      details: {
        related: 'Artikel Terkait',
        more: 'Lihat Artikel Lainnya'
      },
      content1: {
        title: `7 Alasan yang Bikin Bisnismu Wajib Pakai Teknologi, Mau Tahu Kenapa?`,
        desc: `
        <p>Perkembangan dunia saat ini, dipacu oleh kemajuan teknologi dalam berbagai aspek kehidupan. Terutama dalam bidang IT dan bisnis, teknologi membuat kita bisa terhubung dengan segala sesuatu secara real-time.<br/>Dulunya, kemajuan teknologi dianggap musuh dalam bisnis karena sulitnya proses adaptasinya. Namun, sekarang teknologi sangat dibutuhkan untuk kemajuan bisnis. Apa saja keuntungan memanfaatkan teknologi dalam bisnis?</p><div class="par-spacer"></div>
        <ol>
        <li><p>Mempermudah pekerjaan operasional<br/>Dengan teknologi yang terstruktur, proses operasional bisnis akan lebih mudah. Terutama jika bisa mengadaptasi teknologi dengan cepat, bisnis akan berjalan lebih efektif.</p></li><div class="par-spacer"></div>
        <li><p>Berkomunikasi lebih mudah<br/>Ini sangat terasa saat pandemi, kemajuan teknologi mempermudah komunikasi dan kolaborasi antar anggota tim dari jarak jauh. Alat-alat seperti video conference, chat dan email membuat proses komunikasi lebih cepat dan efisien.</p></li><div class="par-spacer"></div>
        <li><p>Kemampuan analisis data yang lebih baik<br/>Teknologi mempermudah pengumpulan dan analisis data, sehingga memudahkan pengambilan keputusan bisnis. Dengan data yang akurat dan terupdate, bisnis dapat mengambil keputusan yang tepat untuk mencapai target yang diharapkan.</p></li><div class="par-spacer"></div>
        <li><p>Menjangkau pasar yang lebih luas<br/>Dengan teknologi, bisnis dapat menjangkau pasar yang lebih luas, baik dalam negeri maupun luar negeri. Kemajuan teknologi mempermudah promosi dan penjualan produk melalui internet dan media sosial, memungkinkan bisnis untuk menjangkau calon konsumen yang lebih luas dan tersebar.</p></li><div class="par-spacer"></div>
        <li><p>Efisiensi bisnis<br/>Teknologi mempermudah bisnis untuk mengoptimalkan proses bisnis dan mengurangi biaya. Contohnya, dengan menggunakan software bisnis, bisnis dapat membantu mengurangi biaya manual dan mempermudah proses pengendalian dan laporan keuangan.</p></li><div class="par-spacer"></div>
        <li><p>Peningkatan keamanan<br/>Teknologi juga membantu bisnis dalam meningkatkan keamanan data dan informasi. Dengan menggunakan enkripsi dan sistem keamanan yang baik, bisnis dapat memastikan bahwa data penting mereka terlindungi dari pihak-pihak yang tidak bertanggung jawab.</p></li><div class="par-spacer"></div>
        <li><p>Peningkatan produktivitas<br/>Teknologi mempermudah bisnis untuk meningkatkan produktivitas. Contohnya, dengan menggunakan software yang mempermudah pengelolaan tugas dan jadwal, bisnis dapat menghemat waktu dan memastikan tugas-tugas penting dikerjakan tepat waktu.</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content2: {
        title: `Mengenal ERP dan Manfaatnya untuk Bisnis Anda yang Maksimal`,
        desc: `
        <p>ERP atau Enterprise Resource Planning adalah solusi sistem dan software yang memberikan banyak manfaat bagi perusahaan besar. ERP membantu memantau dan mengelola aktivitas bisnis seperti keuangan, pengadaan, produksi, SDM, monitoring karyawan dan lain sebagainya.</p><div class="par-spacer"></div>
        <p>ERP dipilih karena dapat memberikan informasi real-time dan akurat yang membantu perusahaan dalam membuat keputusan bisnis yang tepat. Namun, untuk memperoleh manfaat maksimal, perusahaan harus beradaptasi dengan ERP dengan cepat. Dan manfaat dari penerapan ERP tidak hanya sampai disitu saja, tetapi juga memiliki banyak manfaat lain yang sangat menguntungkan bisnis Anda.</p><div class="par-spacer"></div>
        <ol>
        <li><p>Menambahkan efisiensi perusahaan: Dengan penerapan ERP, data yang biasanya berbentuk fisik harus dikonversikan menjadi data digital. Data yang bersifat digital dan real-time dapat diakses oleh semua orang dalam perusahaan, mempermudah akses informasi dan mempercepat proses kerja.</p></li><div class="par-spacer"></div>
        <li><p>Menambahkan kolaborasi: Data yang bisa diakses secara langsung oleh semua orang dalam perusahaan membantu meningkatkan kolaborasi antar departemen dan mempermudah akses informasi. ERP yang berbasis cloud bahkan memungkinkan kolaborasi antar cabang perusahaan melalui internet.</p></li><div class="par-spacer"></div>
        <li><p>Menghemat biaya operasional: Proses operasional yang otomatis membantu mengurangi biaya tenaga kerja dan mengantisipasi berbagai gangguan dan kerusakan operasional.</p></li><div class="par-spacer"></div>
        <li><p>Menambahkan keamanan data: Sistem ERP memiliki kontrol pembatas untuk mencegah kebocoran data dan hanya admin yang memiliki kunci akses.</p></li><div class="par-spacer"></div>
        <li><p>Membuat prakiraan bisnis secara akurat: Laporan real-time dan konsisten yang diberikan oleh ERP membantu membuat prakiraan bisnis yang lebih akurat.</p></li><div class="par-spacer"></div>
        </ol>
        <p>Dahulu, ERP hanya diterapkan pada perusahaan besar, namun saat ini perusahaan menengah juga membutuhkannya. KeDA Tech menawarkan solusi ERP yang dapat dikustomisasi sesuai kebutuhan bisnis Anda. Jangan ragu untuk menghubungi kami dan rasakan manfaat dari penerapan ERP untuk bisnis Anda. Rasakan perbedaan yang signifikan dengan penerapan ERP dan tingkatkan kinerja bisnis Anda!</p><div class="par-spacer"></div>
        `
      },
      content3: {
        title: `3 Keunggulan yang membuat website menjadi kebutuhan mutlak bagi bisnis Anda`,
        desc: `
        <p>Walaupun media sosial dan marketplace lagi ngetrend untuk jual beli online, tapi Global Web Index ngasih tahu kalau 62% pelanggan suka cari informasi soal merek atau produk lewat website.<br/>Nah, buat para pengusaha, data ini gak boleh disepelekan. Kalau gak punya website resmi buat bisnis, bisa-bisa peluang bisnis kalian ikut turun. Bukan cuma sebagai sumber informasi buat pelanggan, tapi website juga bisa bikin bisnis kalian terkenal lebih luas dan bikin pelanggan baru datang sendiri.<br/>Nah, ini 3 alasan penting kenapa bisnis kalian harus punya website resmi.</p><div class="par-spacer"></div>
        <ol>
        <li><p>Credibility perusahaan.<br/>Website jadi hal yang penting buat semua jenis bisnis, baik besar atau kecil, karena bisa bikin calon konsumen percaya sama kita. Kita bisa nampilin review atau ulasan asli dari pelanggan kita. Dengan begitu, calon konsumen juga percaya sama bisnis kita karena dianggap professional.</p></li><div class="par-spacer"></div>
        <li><p>Tempat promosi.<br/>Website jadi tempat yang sempurna buat promosi, lagi-lagi kalau diterapin SEO dengan baik. Traffic website bakal naik dan otomatis bakal ada pembeli yang tertarik beli produk kita. Selain itu, website bisa mempromosikan bisnis kita 24 jam non-stop setiap hari.</p></li><div class="par-spacer"></div>
        <li><p>Meningkatkan kualitas layanan pelanggan.<br/>Website yang bisa kita atur sesuai kenyamanan pelanggan, bisa bikin pelanggan setia. Salah satunya dengan nambah halaman kontak, seperti form pertanyaan dengan email, atau bikin chatbot atau live chat dari website.<br/>Teknologi pasti terus berkembang, dan kalau bisnis kita gak bisa beradaptasi, itu bakal nyesek banget. Maka dari itu, KeDA Tech paham banget kalau setiap teknologi punya peran penting dalam bisnis. Kalian bisa kontak kami buat implementasi selanjutnya.</p></li><div class="par-spacer"></div>
        </ol>
        `
      },
      content4: {
        title: `Dari Krisis ke Inovasi: Dampak Pandemi pada Adopsi Teknologi`,
        desc: `
        <p>Pandemi COVID-19 telah membuat dunia terhenti dan sangat mempengaruhi cara hidup dan bisnis kita. Saat virus menyebar, semakin jelas bahwa teknologi sangat penting dalam memungkinkan kita melanjutkan kegiatan sehari-hari tanpa menyebarkan virus. Pandemi ini menyebabkan peningkatan cepat dalam adopsi teknologi, dan bisnis harus beradaptasi dengan cepat pada kenyataan baru ini.</p><div class="par-spacer"></div>
        <p>Salah satu dampak terbesar pandemi adalah pergeseran ke arah bekerja jarak jauh. Dengan karyawan tidak bisa pergi ke tempat kerja, perusahaan harus mencari cara untuk melanjutkan operasinya tanpa gangguan. Ini menyebabkan peningkatan besar dalam penggunaan alat kolaborasi seperti perangkat lunak video conferencing dan alat manajemen proyek virtual. Alat-alat ini memungkinkan bisnis untuk melanjutkan operasi mereka secara remote, memastikan bahwa karyawan bisa bekerja bersama seolah-olah mereka berada di kantor yang sama.</p><div class="par-spacer"></div>
        <p>Bidang lain di mana teknologi menjadi lebih penting adalah dalam dunia perdagangan online. Dengan orang waspada untuk berpergian ke toko fisik, belanja online menjadi lebih populer dari sebelumnya. Retailer harus beradaptasi dengan cepat pada perubahan ini, berinvestasi pada platform online mereka dan meningkatkan presensi online mereka untuk memenuhi permintaan yang tumbuh. Ini menyebabkan peningkatan besar dalam penggunaan platform e-commerce, yang sekarang merupakan bagian penting dari strategi banyak bisnis.</p><div class="par-spacer"></div>
        <p>Akhirnya, pandemi juga melihat peningkatan penggunaan teknologi kecerdasan buatan dan pembelajaran mesin. Teknologi-teknologi ini digunakan untuk membantu melacak dan memprediksi penyebaran virus, dan juga untuk membantu manajemen sumber daya seperti tempat tidur rumah sakit dan barang medis. Selain itu, mereka digunakan untuk membuat chatbot yang dapat membantu menjawab pertanyaan dan memberikan informasi tentang pandemi, membebaskan tenaga kesehatan untuk fokus pada tugas-tugas kritis.</p><div class="par-spacer"></div>
        <p>Pada kesimpulannya, pandemi COVID-19 telah menimbulkan peningkatan yang cepat dalam adoptasi teknologi, karena bisnis dan individu harus beradaptasi dengan cara kerja dan berkomunikasi baru. Pandemi ini telah menunjukkan pentingnya teknologi dalam memungkinkan kita untuk melanjutkan aktivitas sehari-hari tanpa menyebarkan virus, dan telah menciptakan peluang baru bagi bisnis untuk menggunakan teknologi untuk meningkatkan operasi mereka. Saat dunia bergerak maju, sangat mungkin bahwa teknologi akan terus memainkan peran yang semakin penting dalam hidup dan bisnis kita.</p><div class="par-spacer"></div>
        `
      },
      content5: {
        title: `Naik dan Jatuhnya Blockbuster: Biaya dari Tidak Menyesuaikan Diri dengan Teknologi yang Berubah`,
        desc: `
        <p>Pada tahun 1980-an, konsumen memiliki banyak cara berbeda untuk menonton film, terutama dengan menyewa dari toko video lokal. Pada saat itu, Blockbuster Video adalah perusahaan pemimpin dalam industri penyewaan film dan membuat banyak uang karena permintaan yang tinggi. Namun, dunia sedang berubah dan teknologi berkembang dengan cepat. Pertumbuhan layanan streaming seperti Netflix menyebabkan Blockbuster harus menyesuaikan diri dengan situasi pasar yang berubah. Sayangnya bagi mereka, gagal melakukannya, yang pada akhirnya menyebabkan kehancurannya. </p><div class="par-spacer"></div>
        <p>Pada tahun 1989, Blockbuster membuka tokonya yang pertama dan 10 tahun kemudian menjadi kekaisaran global dengan 9.000 toko di 25 negara. Keberhasilan mereka berkat model sewaan yang memungkinkan lebih banyak akses terhadap film dengan harga terjangkau. Namun, ketika layanan streaming seperti Netflix muncul, konsumen punya cara lebih mudah untuk menonton film tanpa harus keluar rumah. Akibatnya adalah penurunan pelanggan Blockbuster dan pada akhirnya kehancuran bisnis mereka. </p><div class="par-spacer"></div>
        <p>Kegagalan mereka merupakan contoh penting bagi semua perusahaan tentang pentingnya menyesuaikan diri dengan perubahan teknologi agar tetap relevan di pasar yang kompetitif. Ini juga menunjukkan bahwa tidak ada bisnis yang imun dari kegagalan jika tidak mengikuti perkembangan teknologi.</p><div class="par-spacer"></div>
        `
      }
    },

    solution: {
      header: {
        title: 'Solusi',
        desc: 'Kami menyediakan beragam solusi yang dibuat khusus untuk mendorong kemajuan dan pertumbuhan bisnis Anda'
      },
      item1: {
        title: 'Pengembangan Website',
        desc: `User interface yang baik dan mudah digunakan dapat meningkatkan tingkat konversi website hingga 200%. Oleh karena itu, penting bagi website bisnis Anda untuk efektif dengan desain yang menarik, fitur yang lengkap dan mudah diatur. Kami dapat menangani semuanya sesuai kebutuhan dan preferensi Anda<br/><br/>Mulai dari pembuatan website perusahaan, e-commerce, microsite, halaman landing sampai desain custom dengan desain profesional dan kaya fitur. Kami ada untuk memastikan bahwa website Anda tidak hanya tampak bagus, tetapi juga berfungsi optimal untuk memenuhi kebutuhan bisnis Anda`
      },
      item2: {
        title: 'Aplikasi Mobile',
        desc: `Sekitar 70% pelanggan belanja online meninggalkan keranjang mereka karena pengalaman pengguna yang buruk saat mengakses aplikasi. Jangan biarkan hal ini terjadi pada bisnis digital Anda. Mulailah bekerja sama dengan kami karena kami tidak hanya menekankan pada kemudahan penggunaan untuk pengguna aplikasi bisnis Anda, tetapi juga memberikan perhatian lebih pada aspek desain, membuatnya menarik dan menyenangkan bagi pengguna`
      },
      item3: {
        title: 'Pengembangan Game',
        desc: `Temukan potensi bisnis Anda yang belum terkelola dengan media iklan yang menarik dan unik - game! Jangan khawatir tentang bagaimana membuatnya menarik, karena kami akan bekerja sama dengan Anda untuk membawa ide-ide Anda menjadi kenyataan. Dari menentukan game yang sesuai dengan bisnis Anda, hingga merancang desain dan melaksanakan produksi - baik untuk game komputer, game konsol, atau perangkat mobile, kami siap membantu anda. Gabungkan kekuatan pendekatan iklan inovatif ini hari ini!`
      },
      item4: {
        title: 'Sistem ERP',
        desc: `Maksimalkan penghematan bisnis Anda dan simplifikasi proses bisnis dengan Enterprise Resource Planning atau ERP. Dengan data yang akurat, Anda bisa menghemat hingga 46% pengeluaran bisnis dan meningkatkan produktivitas sebesar 90%. Tim kami di Odoo ERP siap membantu, menawarkan berbagai fitur untuk membuat implementasi ERP Anda sukses. Booking konsultasi dengan kami hari ini untuk menemukan solusi ERP terbaik yang sesuai dengan kebutuhan bisnis Anda`
      }
    },

    project: {
      header: 'Projek',
      quote:
        'Kami mengajak anda untuk mengenal KeDA Tech lebih jauh, melalui proyek-proyek yang sudah kami kerjakan dan berapa lama waktu yang dibutuhkan untuk menyelesaikannya',
      see: 'Lihat Proyek',
      load: 'Muat lebih banyak',
      time: 'Waktu',
      months: 'Bulan',
      worker: 'Tim yang dibutuhkan',
      tech: 'Teknologi yang dipakai',
      timeline: 'Perencanaan',
      development: 'Minggu pengembangan',
      'task-completed': 'Tugas Terselesaikan',
      point1: ' Memahami masalah secara mendalam',
      point2: 'Pembuatan Ide',
      point3: 'Solusi',
      explore:
        'Cek beberapa proyek lain yang bikin geger dan bikin kita terinspirasi untuk berpikir ulang tentang hal-hal yang biasa',
      detail: {
        'item-wayfinder': {
          name: 'Wayfinder Indonesia',
          subtitle:
            'Transfomasikan pengalaman navigasi indoor Anda dengan integrasi mulus ke sistem manufaktur Anda untuk peningkatan efisiensi dan produktivitas',
          desc: 'Sistem ini memberikan kemudahan kepada tenaga kerja Anda untuk mengelola masuk dan keluar barang secara efisien',
          'desc-point1': `Bagaimana integrasi teknologi dalam sistem manufaktur dan gudang bisa mempermudah beban kerja para pekerjanya dan meningkatkan produktivitas mereka?`,
          'desc-point2': `Ide sistem ini muncul dengan tujuan untuk meningkatkan efisiensi para pekerja sistem gudang, khususnya mengenai tata letak barang dalam suatu ruangan.`,
          'sub-point3': `Hadirnya Wayfinder membawa revolusi dalam cara kerja gudang, menjadikannya sebagai standar efisiensi dan efektivitas`,
          'desc-point3': `Karena data yang dimasukkan terintegrasi dengan keadaan ruangan saat itu juga, hal ini menciptakan kombinasi yang dinamis dan menarik antara informasi dan sekitar.`,
          quote: `"Sistem ini muncul dengan tujuan untuk memperkenalkan revolusi efisiensi bagi pekerja gudang, khususnya mengenai tata letak barang dalam suatu ruangan. Bayangkan jika penempatan barang di gudang dapat dioptimalkan sampai potensi maksimal, sistem yang lebih teratur dan efisien dapat memberikan manfaat bagi semua pihak. Saatnya untuk memikirkan ulang cara kita mengatasi penyimpanan dan organisasi di tempat kerja."`,
          'quote-by': `Richard Moran, Koordinator Proyek IT`
        },
        'item-axa': {
          name: 'AXA SMART DRIVER',
          subtitle:
            'Nikmati pengalaman berkendara yang mulus dan bebas dari masalah dengan menggunakan teknologi Virtual Reality!',
          desc: 'Siapkan diri Anda untuk pengalaman mengemudi yang luar biasa bersama VR interaktif! Rasakan sensasi mengemudi di kota virtual dengan kenyamanan dan keamanan yang tak tertandingi.',
          'desc-point1': `AXA hadir kepada kita dengan tantangan untuk membuat berkendara lebih aman. Bersama dengan AXA, kami hadirkan pengalaman simulasi berkendara yang luar biasa di kota Anfield. Dengan menggunakan teknologi VR interaktif yang canggih, Anda dapat merasakan seolah-olah berkendara di dunia nyata dengan aman dan nyaman.`,
          'desc-point2': `Simulasi ini diciptakan dengan satu tujuan utama: memberikan pengalaman berkendara yang luar biasa bagi pengemudi. Dengan teknologi VR interaktif yang sangat canggih, kami akan membawa Anda pada perjalanan berkendara yang aman dan nyaman dengan kesan yang sangat realistis`,
          'sub-point3': '',
          'desc-point3': `AXA Smart Driver memberi pengemudi kesempatan untuk berkendara tanpa khawatir. Dengan dukungan teknologi canggihnya, ini membantu Anda menjaga keamanan dengan cara yang unik. Teknologi ini menyediakan peringatan batas aman berkendara sehingga Anda bisa tetap fokus di jalan dan menghemat waktu melalui perjalanan yang aman. Jadi, cobalah AXA Smart Driver dan rasakan sensasi berkendara tanpa cemas!`,
          quote: `"Kami sangat senang menggunakan teknologi yang ditawarkan oleh KeDa Tech untuk simulasi berkendara kami. Dengan tujuan utama memberikan pengalaman berkendara yang luar biasa bagi pengemudi kami, kami percaya bahwa teknologi VR interaktif yang canggih ini akan memungkinkan kami untuk menciptakan perjalanan berkendara yang aman dan nyaman dengan kesan yang sangat realistis.<br><br>KeDa Tech telah bekerja dengan professionalisme dan keahliannya dalam menciptakan simulasi ini. Kita semua sangat terkesan dengan hasil proyek mereka. Mereka membuat proyek ini aman, akurat dan dapat diandalkan, memastikan bahwa semua pengemudi mendapatkan pengalaman berkendara yang luar biasa.<br><br>Kami juga sangat berterima kasih atas dukungan KeDa Tech selama proses pembuatan simulasi ini. Mereka sangat mengedepankan kepuasan pelanggan dan memberikan jaminan layanan berkualitas tinggi, sehingga kami dapat menciptakan produk yang berkualitas tinggi, efisien, dan akurat.<br><br>Kami sangat menyarankan KeDa Tech untuk para pelanggan kami yang ingin menciptakan simulasi berbasis teknologi VR interaktif. Kami benar-benar percaya bahwa mereka akan selalu membantu pelanggan kami memenuhi tujuan mereka dengan memberikan solusi terbaik. Terima kasih KeDa Tech atas semua kerjasama yang anda berikan."`,
          'quote-by': ``
        },
        'item-load-container': {
          name: 'Load Container',
          subtitle: 'Software untuk meningkatian efisiensi kargo',
          desc: 'Kalahkan kompetisi dengan software canggih kami, dirancang untuk mengoptimalkan operasi cargo Anda, memberikan efisiensi, produktivitas, dan profitabilitas yang lebih tinggi.',
          'desc-point1': `Bagaimana memaksimalkan jumlah barang dalam satu kontainer kargo? (Tanpa software ini, penataan barang dalam satu kontainer dan kargo akan mengalami banyak percobaan. Sehingga memakan waktu lebih lama untuk menghitung dan memperkirakan jumlah kontainer dalam 1 kargo.)`,
          'desc-point2': `Tujuan dari Container Loader Program adalah untuk optimasi perhitungan barang dalam kontainer dan kontainer dalam kargo, juga menghitung kombinasi kontainer yang direkomendasikan untuk digunakan.`,
          'sub-point3': '',
          'desc-point3': `Software ini memberikan kemudahan bagi pengguna untuk mencapai pengisian kontainer secara optimal. Dengan berbagai fitur unik yang tersedia, pengguna dapat menentukan beberapa kombinasi kontainer terbaik untuk memuat cargo, serta dapat memilih tujuan lain melalui rekomendasi hasil hitungan software. Software ini menjanjikan solusi yang efisien dan handal untuk memuat cargo dan mengatur kontainer dengan tetap mempertimbangkan biaya dan keefisienan.`,
          quote: ``,
          'quote-by': ``
        },
        'item-sqm-chatbot': {
          name: 'SQM CHATBOT',
          subtitle:
            'Rasakan pengalaman berinteraksi dan bertukar informasi dengan kecepatan tinggi melalui Chatbot kami yang terhubung secara online!',
          desc: 'Chatbot ini memungkinkan Anda untuk menjawab pertanyaan dan meningkatkan interaksi dengan customer secara cepat dan instan. Dengan dukungan teknologi yang canggih, chatbot dapat mencakup berbagai jenis interaksi yang bervariasi dan real time. Chatbot ini akan memungkinkan Anda untuk menciptakan pengalaman pelayanan terbaik untuk customer Anda.',
          'desc-point1': `Bagaimana kita dapat terhubung dengan customer dari waktu ke waktu, tanpa memiliki data kontak yang berulang? `,
          'desc-point2': `Chatbot dapat memberikan solusi cepat untuk pelanggan, menjembatani kesenjangan sampai mereka bisa terhubung dengan agen manusia dan menyediakan dukungan tanpa batas waktu `,
          'sub-point3': '',
          'desc-point3': `SQM Property menghadirkan solusi tepat dan cepat bagi para customer melalui Chatbot SQM. Kapan saja, tanpa batas waktu, customer bisa menemukan jawaban atas pertanyaan mereka seputar SQM Property dengan mudah dan efisien`,
          quote: `"Sejak awal, kami yakin bahwa kerjasama dengan KeDa Tech adalah pilihan yang tepat untuk mewujudkan visi kami akan sebuah chatbot. Tim KeDa Tech memberikan solusi yang tidak hanya cepat dan akurat, namun juga mudah digunakan oleh pelanggan kami. Pelanggan kami kini dapat dengan gampang dan cepat menemukan jawaban atas pertanyaan mereka tentang SQM Property kapan saja, tanpa terbatas waktu.<br><br>Tim KeDa Tech sangat profesional dan responsif terhadap setiap pertanyaan atau kekhawatiran yang kami ajukan. Dedikasi dan perhatian terhadap detail dalam memproduksi produk berkualitas tinggi sangat jelas terlihat dalam setiap aspek proyek.<br><br>Kami sangat senang dengan hasil akhir dari proyek ini dan sangat menyarankan KeDa Tech sebagai mitra pengembangan sistem bagi siapa saja yang membutuhkan"`,
          'quote-by': ``
        },
        'item-verif-doctor': {
          name: 'Membuka Akses Layanan Kesehatan Ahli Dengan Jasa Raharja',
          subtitle:
            'Merevolusikan Kesehatan dengan Aplikasi Manajemen Dokter Konsultan Terdepan: Meningkatkan Efisiensi, Memperkuat Komunikasi, dan Menyederhanakan Alur Kerja untuk Hasil Pasien Optimal!',
          desc: `Setelah kecelakaan, hal terakhir yang harus dikhawatirkan korban atau keluarganya adalah melalui prosedur tagihan rumah sakit yang rumit dan membingungkan. Oleh karena itu, kami berkomitmen untuk menggunakan teknologi terkini untuk mempermudah proses bagi pasien yang dicover oleh Jasa Raharja. Solusi inovatif kami memungkinkan untuk menghitung dan memverifikasi biaya rumah sakit dengan mudah, memberikan ketenangan pikiran bagi mereka yang membutuhkan. Tak ada lagi jam-jam terbuang di telepon atau kefrustrasian akibat informasi yang hilang. Dengan pendekatan yang disederhanakan, kami membuat aksesibilitas kesehatan lebih mudah dan lebih stress-free bagi semua orang`,
          'desc-point1': `Jalan untuk pulih setelah kecelakaan bisa menjadi panjang dan stres, terutama ketika harus memverifikasi data untuk perlindungan PT Jasa Raharja. Proses verifikasi tradisional seringkali lambat dan merepotkan, menimbulkan kesulitan baik bagi konsultan dokter maupun korban kecelakaan. Namun, bagaimana jika ada cara untuk mempercepat langkah kritis ini dalam perjalanan penyembuhan? Dengan memanfaatkan teknologi terdepan, kami membuat mungkin untuk memverifikasi data cepat dan akurat bagi korban kecelakaan yang dilindungi oleh PT Jasa Raharja. Tak ada lagi menunggu, tak ada lagi kefrustrasian. Dengan pendekatan yang terintegrasi, kami membantu korban kecelakaan dan keluarga mereka mendapatkan dukungan yang mereka butuhkan, ketika mereka membutuhkannya. Bergabunglah dengan kami dalam mengubah cara kami menangani verifikasi data setelah kecelakaan.`,
          'desc-point2': `Selama ini, alur proses verifikasi data perusahaan asuransi PT Jasa Raharja dalam melayani korban kecelakaan yang tercover oleh asuransi belum cukup efisien. Untuk mengatasi masalah ini, tim kami mencari solusi untuk meningkatkan efisiensi alur proses tersebut. Setelah melakukan serangkaian pemeliharaan, kami memutuskan untuk membuat aplikasi untuk membantu meningkatkan efisiensi proses verifikasi data PT Jasa Raharja. Aplikasi ini diharapkan dapat mengurangi waktu dan biaya, serta membuat proses lebih mudah dan efisien, sehingga dokter konsultan dapat melayani korban kecelakaan dengan lebih cepat dan akurat.`,
          'sub-point3': '',
          'desc-point3': `Kami memperkenalkan Aplikasi Verifikasi Dokter Konsultan untuk membantu dalam proses verifikasi data tagihan rumah sakit untuk korban kecelakaan yang dicover Jasa Raharja. Aplikasi ini merupakan solusi yang membuat proses verifikasi menjadi lebih cepat dan mudah bagi kedua belah pihak, yaitu dokter konsultan dan korban kecelakaan.`,
          quote: `"KeDa Tech telah menjadi rekan yang luar biasa dalam pengembangan dan rilis solusi kami di Jasa Raharja. Mereka telah membantu kami mengembangkan proses yang disederhanakan untuk meningkatkan pengalaman kesehatan secara keseluruhan bagi pasien yang dilindungi oleh Jasa Raharja. Tim profesional mereka, dari manajer proyek hingga insinyur perangkat lunak, semuanya telah sangat profesional, bercommunicative dan responsif dalam membantu kami membuat produk inovatif yang memudahkan proses billing untuk pasien dan keluarganya setelah kecelakaan. Kami bangga menjadi bagian dari program yang lebih besar untuk membuat aksesibilitas kesehatan dan bebas stres untuk semua populasi. Kami yakin bahwa kerja sama ini dengan Keda Tech akan memberikan dampak positif yang berkelanjutan bagi pengalaman pasien di masa mendatang."`,
          'quote-by': ``
        },
        'item-health-clinic': {
          name: 'Sistem Manajemen Klinik Kesehatan',
          subtitle:
            'Menggunakan aplikasi untuk memudahkan pengaturan semua kegiatan di klinik, mulai dari menyimpan data pasien hingga melacak aktivitas staff serta melakukan pemantauan dan evaluasi secara terus menerus.',
          desc: `Selain manajemen klinik, aplikasi ini juga dapat diakses dan digunakan oleh dokter untuk memantau perkembangan pasien mereka. Hal ini memungkinkan dokter untuk selalu mengetahui perkembangan terbaru dan memberikan perawatan terbaik bagi pasien mereka, sehingga memastikan bahwa mereka berada di jalur yang tepat menuju pemulihan yang cepat`,
          'desc-point1': `Untuk menyelesaikan masalah ini, kami harus terlebih dahulu mengidentifikasi kebutuhan untuk mengelola keseluruhan klinik secara efisien. Ini meliputi melakukan survei awal, mengumpulkan data, membandingkannya dengan standar industri, dan mengevaluasi berbagai solusi yang tersedia. Setelah memahami masalah dan aplikasi yang tepat yang dapat membantu menyelesaikannya, kami dapat memberikan solusi yang terintegrasi secara real-time untuk memungkinkan pengelolaan pasiennya secara efektif dan efisien.`,
          'desc-point2': `Mulai dari melakukan analisa masalah yang dihadapi oleh manajemen klinik, merumuskan ide-ide tentang bagaimana cara menerapkan teknologi pertama untuk memastikan efektivitas solusi, serta menguji berbagai teknologi untuk melihat mana yang tepat untuk membantu manajemen klinik mencapai tujuan, tim kami berusaha dengan sungguh-sungguh untuk mengembangkan aplikasi yang bisa membantu manajemen klinik dalam mengatur dan mengolah data klinik dan pasien secara real time dan utuh. Setelah proses pengujian dan kualitas aplikasi selesai, kami cek kembali ulang apakah tujuan proyek telah tercapai dengan baik.`,
          'sub-point3': '',
          'desc-point3': `Kami menciptakan aplikasi ini sebagai solusi untuk mengembangkan efisiensi dan produktivitas bagi manajemen klinik dan dokter. Dengan aplikasi ini, mereka dapat mengelola data kesehatan pasien dan aktivitas klinik dengan lebih mudah dan cepat. Ini merupakan langkah penting dalam meningkatkan produktivitas klinik dan dokter dalam menangani berbagai masalah di bidang kesehatan.`,
          quote: `"Kami sangat berterima kasih atas kerja besar yang telah dilakukan oleh KeDa Tech dalam membuat aplikasi yang membantu kita menjalankan klinik kesehatan kita dengan lebih efisien dan produktif. Aplikasi ini memungkinkan kami untuk dengan mudah dan cepat mengelola data kesehatan pasien dan kegiatan klinik. Dengan aplikasi ini, manajer klinik dan dokter kami sekarang dapat memberikan perawatan yang lebih baik kepada para pasien dengan tingkat efisiensi yang lebih tinggi. Ini adalah langkah penting menuju peningkatan produktivitas klinik dan dokter kami dalam menangani berbagai masalah kesehatan. Kami ingin mengucapkan terima kasih kepada KeDa Tech atas nama semua staf kami yang telah memanfaatkan aplikasi Anda. Kami sangat puas dengan hasilnya sampai saat ini dan berharap dapat berkolaborasi lebih jauh dengan Anda di masa depan."`,
          'quote-by': ``
        },
        'item-price-fetcher': {
          name: 'PRICE FETCHER',
          subtitle:
            'Software untuk melacak dan membandingkan harga dari e-commerce yang berbeda, agar Anda bisa mendapatkan opsi terbaik!',
          desc: `Dengan menggunakan aplikasi kami, Anda dapat membandingkan harga produk yang Anda cari dan mendapatkan barang dengan harga terbaik.`,
          'desc-point1': `Mengakses aplikasi e-commerce satu persatu hanya untuk membandingkan harga dapat menjadi melelahkan dan tidak praktis. Bagaimana caranya untuk membandingkan harga antar e-commerce dengan sekali akses?`,
          'desc-point2': `Kami terinspirasi dengan kebingungan konsumen dalam membandingkan harga produk e-commerce. Tujuan kami bersama software ini adalah untuk memudahkan dan membantu proses memilih dan membandingkan harga produk antar e-commerce agar lebih praktis dan efisien.`,
          'sub-point3': '',
          'desc-point3': `Software ini menyediakan solusi untuk masalah akses data harga barang yang berbeda-beda di berbagai e-commerce. Dengan memanfaatkan teknologi ini, pengguna dapat dengan mudah memperoleh harga barang yang mereka cari tanpa harus mengakses aplikasi secara individual untuk setiap e-commerce.`,
          quote: ``,
          'quote-by': ``
        },
        'item-gemx': {
          name: 'G-EMx',
          subtitle: '',
          desc: `Aplikasi digital untuk mengelola kebutuhan bisnis kapan saja dan di mana saja.`,
          'desc-point1': `Bagi banyak pemilik bisnis, mengakses seluruh aktivitas bisnis mereka di satu tempat dapat menjadi tantangan yang nyata. Metode tradisional yang harus berkordinasi dengan berbagai departemen untuk mengakses data keuangan perusahaan dapat menjadi waktu yang menyita dan tidak efisien. Ini membuat pemilik bisnis merasa frustrasi dan tidak dapat membuat keputusan cepat berdasarkan informasi yang up-to-date.`,
          'desc-point2': `Dengan mengutamakan tingkat keamanan yang tinggi, kami membuat solusi G-EMx untuk memudahkan pemilik bisnis dalam mengakses data finansial usahanya kapan saja dan dimana saja.`,
          'sub-point3': '',
          'desc-point3': `Dengan solusi kami, G-EMx dapat memiliki kontrol dan pengawasan penuh atas seluruh kegiatan bisnis mereka, akses terhadap kebutuhan finansial yang disesuaikan, dan visibilitas real-time terhadap transaksi finansial, semua dengan mudah di genggaman mereka. Kami mengerti tantangan yang dihadapi pemilik bisnis dalam mengelola keuangan mereka, dan itulah mengapa kami mengembangkan solusi yang mengatasi tantangan tersebut dan memberikan kemudahan dan kenyamanan yang mereka butuhkan.`,
          quote: `"Kami sangat senang memiliki KeDa Tech sebagai mitra kami dalam menciptakan aplikasi digital untuk mengelola kebutuhan bisnis kami. Mereka benar-benar memahami kebutuhan bisnis modern dan telah menyediakan solusi yang melebihi ekspektasi kami. Dengan keahlian KeDa Tech dalam pengembangan perangkat lunak, kami telah mampu mempercepat operasi dan memiliki kendali dan pengawasan yang lengkap terhadap aktivitas bisnis kami kapan saja dan di mana saja. Aplikasi ini mudah digunakan, dapat disesuaikan, dan memberikan visibilitas real-time pada transaksi keuangan. Kami sangat merekomendasikan KeDa Tech untuk bisnis mana pun yang mencari solusi yang handal dan inovatif untuk mengelola operasi mereka. Mereka adalah ahli dalam bidangnya dan benar-benar menghasilkan hasil."`,
          'quote-by': `Asmadi Ahmad - CEO G-EMx Technologies`
        }
      }
    },

    consult: {
      form: {
        title: 'Konsultasi dengan kami sekarang',
        desc: 'Memasuki dunia bisnis digital dan teknologi pasti akan memunculkan banyak kebingungan. Mulailah dengan mengisi form yang tersedia di bawah ini, dan kami akan memberikan solusi dan jawaban dengan sepenuh hati, tanpa dipungut biaya apapun',
        input1: 'Nama',
        input2: 'Sektor usaha anda',
        input3: 'Nomor HP',
        input4: 'Email',
        input5: 'Pertanyaan dan kebutuhan Anda',
        office: {
          name: 'Kantor Jakarta',
          address:
            'Komplek Green Ville, Jalan Ratu Kemuning Blok BL No. 1, RT08/RW14, DKI Jakarta +(62-81) 180-878-87'
        },
        send: 'Kirim'
      },
      desc: {
        title: 'Apa yang bisa Anda dapatkan?',
        desc: `Beginilah cara prosesnya akan berjalan`
      },
      point1: {
        title: '1. Membahas masalah',
        desc: `Izinkan kami untuk memahami lebih jauh operasi bisnis Anda dengan melakukan diskusi yang bermakna, memperoleh pemahaman yang menyeluruh tentang kebutuhan unik dan set up teknologi anda saat ini. Bersama-sama, kami akan menemukan masalah inti yang ada dan menawarkan solusi yang sesuai untuk membantu keberhasilan bisnis Anda. Tenang, kami juga akan terus memonitor dan mengoptimalkan solusi kami untuk memastikan hasil yang luar biasa.`
      },
      point2: {
        title: '2. Pemilihan Solusi',
        desc: `Di KeDa Tech, kami memahami bahwa menyediakan solusi yang benar-benar memberikan perbedaan adalah kunci kesuksesan. Oleh karena itu, kami tidak hanya mengandalkan kemampuan teknis kami, namun juga melibatkan tim ahli kami untuk melakukan penelitian dan analisis yang mendalam tentang permintaan Anda. Kami ingin memastikan bahwa solusi yang kami tawarkan bukan hanya memenuhi, namun juga melebihi harapan Anda<br/><br/>Dengan memahami tantangan dan tujuan Anda, kami dapat membuat solusi yang dibuat khusus untuk Anda. Ini bukanlah pendekatan "one-size-fits-all"", namun solusi yang dirancang secara unik untuk memenuhi kebutuhan Anda dan membantu Anda mencapai tujuan Anda<br/><br/>Anda tidak perlu terbatasi oleh solusi umum yang tidak memberikan hasil yang diinginkan. Di KeDa Tech, kami percaya bahwa solusi yang dirancang khusus untuk Anda adalah cara terbaik untuk memastikan hasil yang luar biasa dan berdampak. Jadi, mengapa berpuas diri dengan solusi yang biasa-biasa saja, ketika Anda bisa memiliki solusi yang dibuat khusus untuk memenuhi kebutuhan Anda? Hubungi kami hari ini dan rasakan perbedaannya!`
      },
      point3: {
        title: '3. Langkah Berikutnya',
        desc: `Di KeDa Tech, kami memahami pentingnya membangun hubungan yang kuat dan abadi dengan klien kami. Oleh karena itu, bahkan setelah sebuah proyek selesai, komitmen kami untuk kualitas tidak berakhir di sana. Proses follow-up kami dirancang untuk memastikan kepuasan yang sempurna, mengatasi masalah, dan memberikan dukungan berkelanjutan untuk kesinambungan sistem Anda. Dengan check-in rutin, penyelesaian masalah yang cepat, dan tim dukungan yang dedikasi, Anda dapat yakin bahwa keberhasilan Anda adalah prioritas utama kami. Bergabunglah bersama ribuan klien yang puas dan biarkan kami terus mendorong keberhasilan Anda bahkan setelah proyek selesai.`
      },
      review: 'Apa Kata Mereka'
    },

    story: {
      header: {
        title: 'Tentang Kami',
        desc: 'Berawal dari mimpi menjadi software house berstandar internasional, KeDA Tech hadir di tahun 2018. Selama pengembangannya, KeDA Tech percaya bahwa transformasi digital akan membawa banyak perubahan dalam segala aspek kehidupan, terlebih dalam bidang bisnis perusahaan, komunitas sampai masyarakat. <br><br> Bergabunglah dalam revolusi transformasi digital dengan KeDA Tech! Dari mimpi menjadi software house yang terkenal secara internasional, kami hadir dan siap untuk membentuk masa depan. Kami percaya bahwa era digital akan membawa perubahan dan kemajuan besar dalam setiap aspek kehidupan, dari bisnis hingga komunitas dan masyarakat secara keseluruhan. Siapkan diri untuk revolusi digital bersama KeDA Tech.'
      },
      'our-journey': {
        title: 'Perjalanan Kami',
        now: 'Sekarang',
        item1:
          'Berawal dari satu tim yang berisi 2 programmer dan seorang designer, KeDA Tech berdiri dan mampu menyelesaikan project pertamanya yaitu, Container Loader Project.',
        item2:
          'Selang setahun KeDA Tech berdiri, kerja sama yang baru pun di mulai dengan AXA. Dalam project ini kami membuat VR Simulator, yang berhasil diselesaikan oleh 3 programmer dan seorang designer dalam waktu 2 minggu.',
        item3:
          'Bekerja sama dengan salah satu perusahaan properti terbesar di Jakarta, KeDA Tech menyelesaikan aplikasi chatbot untuk SQM Property.',
        item4:
          'Dengan berbagai project yang sudah kami lakukan dengan perusahaan pengembang lainnya seperti Shinta VR, Wallex, Luceria dan PT Citra Kreasi Makmur, Kami juga ingin menjadi bagian dari kemajuan bisnis digital Anda. Dengan lebih dari 10 programmer saat ini, KeDA Tech terus berkomitmen untuk memberikan pelayanan yang terbaik bagi Anda.'
      },
      'our-story': {
        title: 'Sejarah Kami',
        desc: `Setelah menyelesaikan program Master-nya di California-Amerika pada tahun 2017, Kenneth Nursalim kembali ke Indonesia. Tahun-tahun yang ia habiskan di Amerika membukanya mata akan kenyataan bahwa perkembangan sistem informasi dan teknologi di Indonesia masih tertinggal. <br><br>Terkendali untuk mengubah hal ini, Kenneth Nursalim mendirikan KeDA Tech pada tahun 2018 dengan visi menjadi software house yang memiliki standar internasional. Bergabunglah bersama kami dalam petualangan kami dalam membawa perubahan pada industri teknologi dan membawa Indonesia ke depan dunia digital.`
      },
      'to-team': {
        desc: 'Dengan tulus membantu bisnismu mencapai puncak keberhasilan!',
        'meet-team': 'Kenali Tim Kami'
      }
    },

    technology: {
      'short-desc': {
        title: 'Teknologi',
        desc: 'Untuk kemajuan teknologi Anda, kami menggunakan semua fitur di bawah ini.'
      },
      'unreal-engine': {
        title: 'UNREAL ENGINE',
        desc: 'Game Engine ini menghasilkan framerate yang sangat stabil, grafik yang memukau dengan bayangan yang menakjubkan, kedalaman bidang yang menyeluruh, dan mendukung teknologi DX 11 terbaru. Siapkan diri Anda untuk mengalami petualangan gaming yang luar biasa seperti yang belum dirasakan sebelumnya.',
        link: 'Selengkapnya tentang UNREAL ENGINE',
        'long-desc': `Aplikasi game engine ini dibuat oleh Epic Games dan meluncur pada tahun 1998. Dimulai dengan game tembak-menembak, sekarang Unreal Engine mampu mengubah objek-objek disekitar Anda menjadi visualisasi 3D real-time. <br><br>Peningkatan yang terus-menerus dari Unreal Engine membawa bentuk 3D semakin dekat dengan kenyataan dengan representasi yang lebih hidup. Dengan setiap iterasi, engine menjadi semakin memukau dan mengambil Anda ke dunia di luar imajinasi Anda.`,
        ask1: 'Bagaimana teknologi ini diterapkan untuk membuka jendela baru dalam kreativitas dan inovasi?',
        explanation1: `Unreal Engine membuka dunia kemungkinan tanpa batas untuk proyek Anda. Bukan hanya untuk game, Anda bisa menerapkan Unreal Engine pada film, arsitektur, otomotif dan transportasi, acara siaran, bahkan simulasi segala jenis. Lepaskan imajinasi Anda dan bawa kreasi Anda ke dunia nyata dengan Unreal Engine.`,
        ask2: 'Apa keuntungannya?',
        explanation2: `Dengan menerapkan Unreal Engine Anda bisa mendapatkan gambaran yang sempurna sesuai dengan aslinya. Anda bisa memperkirakan simulasi secara maksimal dengan Unreal Engine ini.`,
        project: {
          use: 'Proyek kami yang memakai UNREAL Engine',
          title: 'Aplikasi Mobile',
          name: 'AXA',
          desc: `Sebuah pengalaman VR interaktif yang membawa Anda melalui jalan-jalan sibuk kota Anfield dengan berkendara virtual yang menegangkan. Anda dapat memasuki dunia kehidupan kota dengan memanfaatkan kendali intuitif dan headset VR mutakhir, sehingga Anda merasa seperti berada di belakang kemudi dan menjelajahi kota secara real time. Bersiaplah untuk perjalanan yang menyentuh hati dan tak terlupakan melalui salah satu kota paling menyenangkan di dunia.`
        }
      },
      'my-sql': {
        title: 'My SQL',
        desc: 'MySQL memiliki tingkat fleksibilitas yang tinggi terhadap teknologi dan bersifat sebagai basis data cross-platform. Struktur tabel yang mudah digunakan memberikan performa yang luar biasa saat diterapkan.',
        link: 'Selengkapnya tentang My SQL',
        'long-desc': `Awalnya, MySQL adalah pengembangan lanjutan dari proyek UNIREG yang tidak sangat kompatibel dengan database dinamis yang digunakan pada situs web. Seiring perkembangannya, MySQL menjadi sebuah sistem manajemen database yang menggunakan perintah dasar SQL atau Structured Query Language yang cukup terkenal.`,
        ask1: 'Bagaimana teknologi ini diterapkan untuk membuka jendela baru dalam kreativitas dan inovasi?',
        explanation1: `MySQL termasuk dalam jenis Sistem Manajemen Basis Data Relasional, di mana istilah seperti baris, kolom, dan tabel digunakan dalam aplikasi basis data ini. <br><br>Sambutlah dunia manajemen basis data dengan MySQL, di mana istilah baris, kolom, dan tabel yang akrab dipakai hidup kembali dalam dunia basis data relasional.`,
        ask2: 'Apa keuntungannya?',
        explanation2: `Dengan menerapkan MySQL, Anda tidak membutuhkan RAM besar. Selain itu, MySQL juga mendukung integrasi dengan bahasa pemrograman lain, membuatnya solusi yang serbaguna dan efisien.`,
        project: {
          use: 'Proyek kami yang memakai My SQL',
          title: 'Pengembangan Website',
          name: 'WayFinder',
          desc: `Navigasi dalam ruangan yang terintegrasi dengan sistem manajemen gudang. Sehingga meningkatkan efisiensi para pekerjanya.`
        }
      },
      'django-python': {
        title: 'Django Phyton',
        desc: `Dibuat tahun 2003, Django sendiri baru dirilis versi pertamanya tahun 2008. Karena Django adalah suatu kerangka full-stack untuk membuat aplikasi web dengan bahasa pemrograman Python.`,
        link: 'Selengkapnya tentang Python',
        'long-desc': `Dibuat pada tahun 2003, Django baru dirilis versi pertamanya pada tahun 2008. Kerangka full-stack ini dibuat untuk membantu pengembang membuat aplikasi web menggunakan bahasa pemrograman Python. Lewatkan waktu ke tahun 2019, versi kedua Django akhirnya dirilis, menyediakan lebih banyak alat untuk membantu pengembang mewujudkan visi aplikasi web mereka.`,
        ask1: 'Bagaimana teknologi ini diterapkan untuk membuka jendela baru dalam kreativitas dan inovasi?',
        explanation1: `Django adalah kerangka kerja web Python tingkat tinggi yang memungkinkan pengembangan aplikasi dengan cepat dan desain pragmatis. Efisiensi dan pendekatannya yang teratur membuatnya menjadi pilihan yang luar biasa untuk pengembangan web.`,
        ask2: 'Apa keuntungannya?',
        explanation2: `Django adalah solusi sempurna untuk setiap ukuran proyek, mulai dari proyek kecil hingga besar. Ia memiliki beragam fitur yang luar biasa dibandingkan dengan framework lain, sehingga mampu menangani segala hal penting untuk membangun aplikasi dengan mudah.`,
        project: {
          use: 'Proyek kami yang memakai Django Phyton',
          title: 'Pengembangan Game',
          name: 'Container Loader',
          desc: `Sebuah software simulasi yang menghitung secara optimal konfigurasi barang yang disimpan dalam kargo atau kontainer. <br><br>Software simulasi inovatif yang menghitung penataan barang yang paling efisien dalam kargo atau kontainer untuk memaksimalkan ruang dan meminimalkan ruang tidak terpakai.`
        }
      },
      unity: {
        title: 'Unity',
        desc: `Dikembangkan oleh Unity Technologies, Unity pertama kali diluncurkan tahun 2005 dan mengklaim sebagai platform pionir dalam menciptakan dan mengoperasikan pengalaman 3D secara real-time. Bukan hanya terbatas pada 3D, Unity juga menawarkan proyek lintas platform 2D, VR, AR.`,
        link: 'Selengkapnya tentang Unity',
        'long-desc': `Dikembangkan oleh Unity Technologies, Unity pertama kali diluncurkan tahun 2005 dan mengklaim sebagai platform pionir dalam menciptakan dan mengoperasikan pengalaman 3D secara real-time. Bukan hanya terbatas pada 3D, Unity juga menawarkan proyek lintas platform 2D, VR, AR. Dengan teknologinya yang canggih, Unity telah merevolusikan dunia gaming dan konten interaktif, membuat lebih mudah daripada sebelumnya untuk membawa pengalaman yang memukau dan imersif hidup.`,
        ask1: 'Bagaimana teknologi ini diterapkan untuk membuka jendela baru dalam kreativitas dan inovasi?',
        explanation1: `Unity dapat diterapkan dalam pembuatan berbagai macam games, animasi, desain otomotif, arsitektur, dan masih banyak lagi, membuka kemungkinan tanpa batas bagi imajinasi untuk menjadi kenyataan.`,
        ask2: 'Apa keuntungannya?',
        explanation2: `Salah satu keuntungan dari Unity adalah sistemnya yang mudah digunakan sehingga mudah dipelajari kodenya, namun mampu menghasilkan game dengan beragam fitur. Hal ini mempermudah para developer untuk bekerja secara efisien dan mewujudkan visi kreatif mereka dengan mudah. Baik Anda seorang pemula atau developer game berpengalaman, Unity menyediakan alat dan sumber daya untuk mewujudkan ide-ide Anda. Dengan antarmuka intuitif dan fitur yang kuat, Unity adalah alat ultimate untuk membuat game yang memukau.`,
        project: {
          use: 'Proyek kami yang memakai Unity',
          title: '',
          name: '',
          desc: ``
        }
      },
      'keda-engine': {
        title: 'KeDa Engine',
        desc: `Temukan kekuatan KeDa Engine - program yang lahir dari tekad dan kebutuhan akan kecepatan. Dengan bahasa pemrograman asli C#, tim KeDa mengalami tantangan iterasi yang lambat.`,
        link: 'Selengkapnya tentang KeDa Engine',
        'long-desc': `Temukan kekuatan KeDa Engine - program yang lahir dari tekad dan kebutuhan akan kecepatan. Dengan bahasa pemrograman asli C#, tim KeDa mengalami tantangan iterasi yang lambat. Namun dengan tekad yang tak tergoyahkan dan hasrat untuk berinovasi, KeDa Engine kini memiliki bahasa yang lebih efisien dan kuat yaitu C++.`,
        ask1: 'Bagaimana teknologi ini diterapkan untuk membuka jendela baru dalam kreativitas dan inovasi?',
        explanation1: `KeDa Engine adalah teknologi khusus yang dikembangkan oleh KeDa pada tahun 2017, dirancang untuk mem revolutioner dunia pemrograman. Awalnya ditulis dalam bahasa C#, tim di KeDa menemukan tantangan dengan kecepatan iterasi yang lambat. Namun dengan tekad tak tergoyahkan dan hasrat untuk berinovasi, KeDa Engine sekarang telah mengadopsi bahasa yang lebih efisien dan kuat yaitu C++. Teknologi ini adalah bukti dari komitmen KeDa untuk memberikan solusi terbaik bagi pelanggan mereka dan merupakan contoh bagaimana tekad dan hasrat untuk berinovasi dapat mengarah pada pembuatan teknologi game-changing.`,
        ask2: 'Apa keuntungannya?',
        explanation2: `Keuntungan dari menggunakan KedaEngine adalah sistem yang sudah dikustomisasi, menggunakan C++ dan runtime yang sangat cepat dibandingkan dengan mesin lainnya.`,
        project: {
          use: 'Proyek kami yang memakai KeDa Engine',
          item1: {
            title: 'Pengembangan Website',
            name: 'Wayfinder',
            desc: `Navigasi dalam ruangan yang terintegrasi dengan sistem manajemen gudang. Sehingga meningkatkan efisiensi para pekerjanya.`
          },
          item2: {
            title: 'Pengembangan Website',
            name: 'PRICE FETCHER',
            desc: `Software untuk melacak dan membandingkan harga dari e-commerce yang berbeda, agar Anda bisa mendapatkan opsi terbaik!`
          },
          item3: {
            title: 'Pengembangan Website',
            name: 'Load Container',
            desc: `Sebuah software simulasi yang mampu menghitung secara optimal tentang konfigurasi barang yang masuk dalam kargo atau kontainer.`
          }
        }
      },
      back: 'Kembali ke Teknologi'
    }
  }
};
