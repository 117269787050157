<template>
  <section v-bind="$attrs" :style="styles" v-on="$listeners">
    <slot />
  </section>
</template>

<script>
import Measurable from 'vuetify/lib/mixins/measurable';

export default {
  name: 'BaseSection',

  mixins: [Measurable],

  props: {
    space: {
      type: [Number, String],
      default: 96
    },
    topInnerShadow: {
      type: Boolean,
      default: false
    },
    padlessTop: {
      type: Boolean,
      default: false
    },
    padlessBottom: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    styles() {
      const space = this.$vuetify.breakpoint.mdAndUp ? this.space : this.space / 2;
      let result = {};

      if (this.padlessTop) {
        result = {
          ...this.measurableStyles,
          padding: `0 0 ${space}px`
        };
      } else if (this.padlessBottom) {
        result = {
          ...this.measurableStyles,
          padding: `${space}px 0 0`
        };
      } else {
        result = {
          ...this.measurableStyles,
          padding: `${space}px 0`
        };
      }

      if (this.topInnerShadow) {
        result = {
          ...result,
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)'
        };
      }

      return result;
    }
  }
};
</script>
