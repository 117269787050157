<template>
  <base-section id="icons-project-header-top-left" space="0">
    <svg
      :width="`${g_bLowerBr ? 150 : 244}`"
      :height="`${g_bLowerBr ? 144 : 238}`"
      viewBox="0 0 244 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240.803 102.934C258.462 60.561 198.663 16.6561 166.557 0H-1V236.849C9.70204 240.847 30.9054 236.649 30.1028 187.88C29.0994 126.919 96.323 109.93 113.38 123.921C130.436 137.912 218.73 155.901 240.803 102.934Z"
        :fill="color"
        fill-opacity="0.13"
      />
    </svg>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'IconProjectHeaderTopLeft',

  mixins: [BaseBreakpoint],

  props: {
    color: String
  }
};
</script>
