<template>
  <base-section id="icons-project-header-bottom-right" space="0">
    <svg
      width="257"
      height="132"
      viewBox="0 0 257 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8201 62.8012C-10.1299 89.0062 1.98014 129.186 11.1501 146H257V45.1131C254.55 32.6661 238.16 6.33112 192.17 0.56612C134.69 -6.63988 129.79 56.9051 101.66 79.8341C73.5301 102.763 46.0101 30.0462 14.8201 62.8012Z"
        :fill="color"
        fill-opacity="0.13"
      />
    </svg>
  </base-section>
</template>

<script>
export default {
  name: 'IconProjectHeaderBottomRight',
  props: {
    color: String
  }
};
</script>
