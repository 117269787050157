<template>
  <base-section id="icons-subs-bottom-right" space="0">
    <svg
      v-if="g_bLowestBr"
      width="141"
      height="184"
      viewBox="0 0 141 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.87776 96.1812C-13.5222 159.381 24.5444 181.181 45.8778 184.181H268.878V42.1812C260.545 31.5145 233.278 16.5812 190.878 42.1812C137.878 74.1812 106.878 69.1812 108.878 20.1812C110.878 -28.8188 27.8778 17.1812 4.87776 96.1812Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="!g_bLowestBr"
      width="267"
      height="184"
      viewBox="0 0 267 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99886 96.1812C-13.4011 159.381 24.6655 181.181 45.9989 184.181H268.999V42.1812C260.666 31.5145 233.399 16.5812 190.999 42.1812C137.999 74.1812 106.999 69.1812 108.999 20.1812C110.999 -28.8188 27.9989 17.1812 4.99886 96.1812Z"
        :fill="color"
      />
    </svg>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'IconSubsBottomRight',

  mixins: [BaseBreakpoint],

  props: {
    color: String
  }
};
</script>
