<template>
  <base-section id="icons-project-header-middle" space="0">
    <svg
      width="224"
      height="299"
      viewBox="0 0 224 299"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.619 137.527C-10.381 91.1271 47.285 63.5271 77.619 55.5271C96.62 -37.4729 198.617 5.52709 215.617 46.5271C232.617 87.5271 224.617 290.527 162.617 297.527C100.617 304.527 103.617 239.527 124.617 189.527C145.617 139.527 101.617 145.527 86.617 173.527C71.617 201.527 16.619 195.527 1.619 137.527Z"
        :fill="color"
        fill-opacity="0.13"
      />
    </svg>
  </base-section>
</template>

<script>
export default {
  name: 'IconProjectHeaderMiddle',
  props: {
    color: String
  }
};
</script>
