// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue';
import { VCol, VRow } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import de from './i18n/de';
import en from './i18n/en';
import id from './i18n/id';
import ja from './i18n/ja';
import zhHans from './i18n/zhHans';

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow
  }
});

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary1: '#720000',
        primary2: '#420000',
        secondary: '#efefef',
        accent1: '#720000',
        accent2: '#8c0000',
        accent3: '#420000',
        black: '#262626',
        appbar: '#B0060C',
        'main-r-500': '#930416',
        'main-r-450': '#C90000',
        'main-r-400': '#E1052B',
        'main-r-300': '#F1546F',
        'main-r-200': '#FC8A9E',
        'main-r-100': '#FFBFCA',
        'main-bl-500': '#1F2837',
        'main-bl-400': '#2C4368',
        'main-bl-300': '#3464B5',
        'main-bl-200': '#4686F1',
        'main-bl-100': '#83B1FF',
        'main-bw-500': '#000000',
        'main-bw-400': '#333333',
        'main-bw-300': '#555555',
        'main-bw-200': '#8C8C8C',
        'main-bw-100': '#9E9E9E',
        'grey-300': '#C8C8C8',
        'grey-200': '#E0E0E0',
        'grey-100': '#F3F3F3',
        'divisi-light-purple': '#BDC6F4',
        'divisi-green': '#BCF1CC',
        'divisi-dark-yellow': '#CDCD73',
        'divisi-light-blue': '#B1EBE9',
        'divisi-dark-purple': '#AFA5D0'
      },
      dark: {}
    }
  },
  lang: {
    locales: { id, en, de, ja, zhHans },
    current: 'en'
  }
});
