<template>
  <v-textarea
    v-if="required"
    :rules="requiredRules"
    :label="label"
    :outlined="outlined"
    :dense="outlined"
    :solo="!outlined"
    single-line
    v-bind="{
      rows: 6,
      ...$attrs
    }"
    v-on="$listeners"
  />
  <v-textarea
    v-else
    :label="label"
    :outlined="outlined"
    :dense="outlined"
    :solo="!outlined"
    single-line
    v-bind="{
      rows: rows,
      ...$attrs
    }"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'BaseTextarea',

  props: {
    label: String,
    outlined: Boolean,
    name: String,
    required: Boolean,
    rows: {
      type: [Number, String],
      default: 6
    }
  },

  data: function () {
    return {
      requiredRules: [(v) => !!v || `${this.name || this.label} is required`]
    };
  }
};
</script>

<style lang="sass">
//
</style>
